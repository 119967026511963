import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';
import './common/amplify/configure';
import { queryClient } from './common/query-client';
import { AuthContextProvider } from './modules/auth';
import { theme } from './theme';

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
              <CssBaseline />
              <ReactQueryDevtools position="bottom-right" />
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </AuthContextProvider>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
