import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { JobStatus } from '../../../../common/types';

type Props = {
  status: JobStatus;
  size?: 'small' | 'medium';
  sx?: SxProps;
};

export const JobColorChip: React.FC<Props> = ({ status, size, sx }) => {
  const chipProps: ChipProps = {};

  const translations: { [key in JobStatus]: string } = {
    CLOSED: 'CLOSED',
    COMPLETED: 'COMPLETED',
    DRAFT: 'DRAFT',
    IN_PROGRESS: 'IN PROGRESS',
    NONE: 'NONE',
    POSTED: 'POSTED',
    READY_TO_BE_POSTED: 'READY TO BE POSTED',
  };

  if (status === JobStatus.Completed || status === JobStatus.Posted) {
    chipProps.color = 'primary';
  }

  if (status === JobStatus.InProgress) {
    chipProps.color = 'warning';
  }

  if (status === JobStatus.ReadyToBePosted) {
    chipProps.color = 'info';
  }

  if (status === JobStatus.Closed) {
    chipProps.color = 'error';
  }

  return (
    <Chip
      size={size || 'small'}
      label={translations[status]}
      sx={sx || { fontSize: '10px', fontWeight: 'bold' }}
      {...chipProps}
    />
  );
};
