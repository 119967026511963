import React from 'react';
import PreviewIcon from '@mui/icons-material/Visibility';
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { OffsetPagingParamsInputOfSortingParamsInput } from '../../../common/types';
import { handlePageChange, handleRowsPerPageChange } from '../../../common/utils/table.utils';
import { FormGroupSection } from '../../../components/form-group-section';
import { routes } from '../../../routes';
import { useFilters } from '../../filter-context';
import { JobColorChip } from '../../jobs/components/list/job-status-chip';
import { NoResults } from '../../shared/components/no-results';
import { useUserJobsQuery } from '../hooks/use-user-jobs.query';

export const UserJobs: React.FC = () => {
  const filters = useFilters<{ paging: OffsetPagingParamsInputOfSortingParamsInput }>({
    syncSearchParams: false,
    defaultValues: {
      paging: {
        limit: 10,
        offset: 0,
      },
    },
  });

  const { data } = useUserJobsQuery(filters.values);

  return (
    <FormGroupSection label="User jobs">
      <Stack direction="row">
        <Stack spacing="20px" flex={1}>
          {!!data?.user?.jobs?.results && (
            <TablePagination
              component="div"
              sx={{ borderBottom: 'none' }}
              count={data?.user?.jobs?.totalCount || 15}
              page={filters.values.paging.offset / filters.values.paging.limit}
              onPageChange={handlePageChange(filters)}
              onRowsPerPageChange={handleRowsPerPageChange(filters)}
              rowsPerPage={filters.values.paging.limit}
              rowsPerPageOptions={[5, 10, 15, 20]}
            />
          )}
          <NoResults condition={data?.user?.jobs?.results?.length === 0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.user?.jobs?.results?.map((x, index) => (
                  <TableRow key={x.id + index}>
                    <TableCell>{x.title}</TableCell>
                    <TableCell>{x.category?.name}</TableCell>
                    <TableCell>
                      <JobColorChip size="small" status={x.status} />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="details"
                        href={routes.jobs.details.link({ jobId: x.id })}
                      >
                        <PreviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </NoResults>
        </Stack>
      </Stack>
    </FormGroupSection>
  );
};
