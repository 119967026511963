import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/user-block.graphql');

export const useUserBlockMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, GraphqlErrorsType, { userId: string }>(
    ['user-block'],
    async (body) => {
      await graphqlRunner<void, { id: string }>(queryDocument, {
        id: body.userId,
      });
    },
    {
      onSuccess: () => {
        enqueueSnackbar(`Operation successful`, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error?.errors?.map((x) => x.message).join('\n, ') || 'Error', {
          variant: 'error',
        });
      },
    },
  );
};
