import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { addDays } from 'date-fns';
import { Alert, Box, Button, Card, Collapse, Stack, Typography } from '@mui/material';
import { JobStatus, LocationType, RateType, ToBeDoneType } from '../../../../common/types';
import { FormGroupSection } from '../../../../components/form-group-section';
import { DisplayForMinRole, Role, useAuth } from '../../../auth';
import { useJobMutation } from '../../hooks/use-job.mutation';
import { useJobQuery } from '../../hooks/use-job.query';
import { JobDetailsFormProps } from '../../job-details.page';
import { ApplicantsList } from './applicants/applicants-list';
import { DangerZone } from './danger-zone';
import { GeneralForm } from './general-form';
import { LocationForm } from './location-form';
import { ManagerDetails } from './manager-details';
import { PhotosForm } from './photos/photos-form';
import { PricingForm } from './pricing-form';

export const JobForm: React.FC = () => {
  const jobQuery = useJobQuery();
  const jobMutation = useJobMutation();
  const { userRole } = useAuth();

  const { handleSubmit, formState, control, reset } = useForm<JobDetailsFormProps>({
    defaultValues: {
      id: jobQuery.data?.job?.id || '',
      title: jobQuery.data?.job?.title || '',
      additionalConsiderations: jobQuery.data?.job?.additionalConsiderations || '',
      description: jobQuery.data?.job?.description || '',
      categoryId: jobQuery.data?.job?.category?.id || '',
      toBeDoneType: jobQuery.data?.job?.toBeDoneType || ToBeDoneType.None,
      toBeDoneDate: jobQuery.data?.job?.toBeDoneDate || '',
      locationType: jobQuery.data?.job?.locationType || LocationType.None,
      requiredTools: jobQuery.data?.job?.requiredTools || '',
      address: {
        apartmentNumber: jobQuery.data?.job?.address?.apartmentNumber || '',
        buildingNumber: jobQuery.data?.job?.address?.buildingNumber || '',
        city: jobQuery.data?.job?.address?.city || '',
        state: jobQuery.data?.job?.address?.state || '',
        street: jobQuery.data?.job?.address?.street || '',
      },
      location: {
        coordinates: {
          latitude: jobQuery.data?.job?.location?.coordinates?.latitude || 0,
          longitude: jobQuery.data?.job?.location?.coordinates?.longitude || 0,
        },
        zipCode: jobQuery.data?.job?.location?.zipCode || '',
      },
      maxRate: {
        type: jobQuery.data?.job?.maxRate.type || RateType.PerHour,
        wage: jobQuery.data?.job?.maxRate.wage || 0,
        currency: jobQuery.data?.job?.maxRate.currency || '',
      },
    },
    mode: 'onBlur',
  });

  const submit = useCallback(
    (body: JobDetailsFormProps) => {
      const toBeDoneDate: { [key in ToBeDoneType]: string } = {
        [ToBeDoneType.None]: new Date().toISOString(),
        [ToBeDoneType.Tomorrow]: addDays(new Date(), 1).toISOString(),
        [ToBeDoneType.WithinThreeDays]: addDays(new Date(), 3).toISOString(),
        [ToBeDoneType.CustomSchedule]: body.toBeDoneDate,
      };

      const data: JobDetailsFormProps = {
        ...body,
        toBeDoneDate: toBeDoneDate[body.toBeDoneType] || body.toBeDoneDate,
        maxRate: {
          ...body.maxRate,
          wage: Number(body.maxRate.wage),
        },
        location:
          body.locationType === LocationType.OnSite
            ? {
                zipCode: body.location?.zipCode || '',
                coordinates: {
                  latitude: Number(body.location?.coordinates?.latitude),
                  longitude: Number(body.location?.coordinates?.longitude),
                },
              }
            : null,
        address:
          body.locationType === LocationType.OnSite && body.address ? { ...body.address } : null,
      };

      jobMutation.mutate(data, {
        onSuccess: () => {
          jobQuery.refetch();
          reset(data);
        },
      });
    },
    [jobMutation.mutate, jobQuery.refetch, reset],
  );

  const disabled = useMemo(
    () =>
      jobQuery.data?.job?.status === JobStatus.Closed ||
      jobQuery.data?.job?.status === JobStatus.Completed ||
      userRole < Role.moderator ||
      jobMutation.isLoading,
    [jobQuery.data?.job?.status, jobMutation.isLoading],
  );

  return (
    <Box>
      <form onSubmit={handleSubmit(submit)}>
        <FormGroupSection defaultExpanded label="Job & Offer details">
          <Collapse in={formState.isDirty && formState.isDirty}>
            <Card sx={{ p: 2, mb: 1 }}>
              <Stack direction="row" spacing={2}>
                <Button type="submit" size="small" disabled={disabled}>
                  Save Changes
                </Button>
                <Button color="secondary" size="small" disabled={disabled} onClick={() => reset()}>
                  Reset
                </Button>
              </Stack>
            </Card>
          </Collapse>
          <Collapse in={!!jobMutation.error}>
            <Card sx={{ mt: 1, mb: 3 }}>
              <Alert severity="error">Error</Alert>
            </Card>
          </Collapse>
          <Collapse in={jobMutation.isSuccess}>
            <Card sx={{ mt: 1, mb: 3 }}>
              <Alert severity="success">Successfully updated</Alert>
            </Card>
          </Collapse>
          <Stack gap={2} justifyContent={'stretch'} width="100%">
            <GeneralForm control={control} disabled={disabled} />
            <PricingForm control={control} disabled={disabled} />
            <LocationForm control={control} disabled={disabled} />
          </Stack>
        </FormGroupSection>
        <FormGroupSection defaultExpanded label="Photos">
          <PhotosForm disabled={disabled} />
        </FormGroupSection>
        <FormGroupSection defaultExpanded label="Manager">
          <ManagerDetails />
        </FormGroupSection>
        <FormGroupSection defaultExpanded label="Applicants">
          <ApplicantsList />
        </FormGroupSection>

        {jobQuery.data?.job?.status !== JobStatus.Completed && (
          <DisplayForMinRole requiredMinRole={Role.moderator}>
            <FormGroupSection
              label={<Typography sx={{ color: 'error.main' }}>Danger Zone</Typography>}
            >
              <DangerZone />
            </FormGroupSection>
          </DisplayForMinRole>
        )}
      </form>
    </Box>
  );
};
