import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { AlertDialog } from '../../../../components/alert-dialog';
import { routes } from '../../../../routes';
import { useJobQuery } from '../../hooks/use-job.query';
import { useJobsDeleteMutation } from '../../hooks/use-jobs-delete.mutation';

export const DangerZone: React.FC = () => {
  const jobQuery = useJobQuery();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const jobsDeleteMutation = useJobsDeleteMutation();
  const navigate = useNavigate();

  const handleDeleteJobs = useCallback(() => {
    jobsDeleteMutation.mutate(
      { jobsId: [jobQuery.data?.job?.id || ''] },
      {
        onSuccess: () => {
          setOpenDeleteConfirmation(false);
          jobQuery.remove();
          navigate(routes.jobs.list.link());
        },
        onError: () => {
          setOpenDeleteConfirmation(false);
          navigate(routes.jobs.list.link());
        },
      },
    );
  }, [jobQuery.data?.job?.id, setOpenDeleteConfirmation]);

  return (
    <Box>
      <Button aria-label="delete" color="error" onClick={() => setOpenDeleteConfirmation(true)}>
        Delete
      </Button>
      <AlertDialog
        title="Deleting Job"
        description={`Are you sure you want to delete the job?`}
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onAccept={handleDeleteJobs}
      />
    </Box>
  );
};
