import React, { useCallback, useMemo } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import { JobStatus, PhotoValidationStatus } from '../../../../../../common/types';
import { useJobDeleteImageMutation } from '../../../../hooks/use-job-delete-image.mutation';
import { useJobQuery } from '../../../../hooks/use-job.query';
import { PhotoValidationStatusChip } from './photo-validation-status-chip';

export type PhotoItem = {
  id: string;
  url: string;
  thumbnailUrl?: string | null | undefined;
  validationStatus: PhotoValidationStatus;
  order: number;
};

export const SortablePhoto = SortableElement((props: { value: PhotoItem & { index: number } }) => {
  const jobQuery = useJobQuery();

  const jobDeleteImageMutation = useJobDeleteImageMutation();

  const disabled = useMemo(
    () =>
      jobQuery.data?.job?.status === JobStatus.Closed ||
      jobQuery.data?.job?.status === JobStatus.Completed,
    [jobQuery.data?.job?.status],
  );

  const handleDeleteButton = useCallback(() => {
    jobDeleteImageMutation.mutate(
      {
        jobId: jobQuery.data?.job?.id || '',
        jobPhotos: [props.value.id],
      },
      {
        onSuccess: () => {
          jobQuery.refetch();
        },
      },
    );
  }, [jobDeleteImageMutation.mutate, jobQuery.data?.job?.id, props.value.id]);

  return (
    <Box
      key={props.value.id}
      sx={{ p: 1, display: 'inline-block', opacity: jobDeleteImageMutation.isLoading ? 0.4 : 1 }}
    >
      <Box
        sx={{
          position: 'relative',
          maxHeight: '200px',
          '&:hover': { div: { opacity: 1 } },
          cursor: 'move',
          '& img': {
            height: '200px',
          },
          backgroundColor: '#d0d0d0',
          minWidth: '150px',
          height: '100%',
        }}
      >
        <img
          src={`${props.value.url}?w=164&h=164&fit=contain&auto=format`}
          srcSet={`${props.value.url}?w=164&h=164&fit=contain&auto=format&dpr=2 2x`}
          alt={props.value.id}
          loading="lazy"
        />
        {props.value.index === 0 && (
          <Chip
            size="small"
            sx={{
              backgroundColor: 'primary.main',
              position: 'absolute',
              top: '10px',
              left: 0,
              fontSize: '12px',
              opacity: 1,
              borderRadius: 0,
            }}
            label="Thumbnail"
          />
        )}
        {!disabled && (
          <Stack
            direction="row"
            gap={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#0008',
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              height: '50px',
              opacity: 0,
              transition: 'opacity .3s',
              cursor: 'default',
            }}
          >
            <IconButton
              aria-label="delete"
              sx={{ color: 'error.main' }}
              onClick={handleDeleteButton}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
        <PhotoValidationStatusChip status={props.value.validationStatus} />
      </Box>
    </Box>
  );
});
