import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';

type Props = {
  label: string | JSX.Element;
  defaultExpanded?: boolean;
  actions?: JSX.Element;
};

export const FormGroupSection: React.FC<Props> = (props) => {
  return (
    <Accordion defaultExpanded={props.defaultExpanded} sx={{ mb: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', pr: 3 }}
        >
          {typeof props.label == 'string' ? (
            <Typography>{props.label}</Typography>
          ) : (
            <Box>{props.label}</Box>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ pb: 3 }}>{props.children}</AccordionDetails>
    </Accordion>
  );
};
