import React, { useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { GetUsersQueryVariables } from '../../../common/types';
import { toDate } from '../../../common/utils/dates';
import {
  handlePageChange,
  handleRowsPerPageChange,
  tableHeaderSortableByKey,
} from '../../../common/utils/table.utils';
import { AlertDialog } from '../../../components/alert-dialog';
import { routes } from '../../../routes';
import { DisplayForMinRole, Role } from '../../auth';
import { useFiltersContext } from '../../filter-context';
import { useTableSelect } from '../../shared';
import { NoResults } from '../../shared/components/no-results';
import { useUsersDeleteMutation } from '../hooks/use-users-delete.mutation';
import { useUsersListQuery } from '../hooks/use-users-list.query';

export const UsersList: React.FC = () => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const filters = useFiltersContext<GetUsersQueryVariables>();

  const usersQuery = useUsersListQuery();
  const usersDeleteMutation = useUsersDeleteMutation();

  const tableSelect = useTableSelect<string>({
    items: usersQuery.data?.users.results?.map((x) => x.id) || [],
  });

  const handleDeleteUsers = useCallback(() => {
    setOpenDeleteConfirmation(false);

    usersDeleteMutation.mutate(
      { usersId: tableSelect.selected },
      {
        onSuccess: () => {
          usersQuery.refetch();
          tableSelect.clearAll();
        },
      },
    );
  }, [tableSelect.selected]);

  const loadingTableState = useMemo(
    () => (usersQuery.isFetching && !usersQuery.isFetched) || usersDeleteMutation.isLoading,
    [usersQuery.isFetching, usersQuery.isFetched, usersDeleteMutation.isLoading],
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Collapse in={tableSelect.count > 0}>
        <Card sx={{ my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ ml: 2, mr: 1 }}
          >
            <Typography>
              Selected <strong>{tableSelect.count}</strong> row(s)
            </Typography>
            <IconButton
              aria-label="delete"
              sx={{ color: 'error.main' }}
              onClick={() => setOpenDeleteConfirmation(true)}
            >
              <DeleteIcon />
            </IconButton>
            <AlertDialog
              title="Deleting User(s)"
              description={`Are you sure you want to delete ${tableSelect.count} user(s)?`}
              open={openDeleteConfirmation}
              onClose={() => setOpenDeleteConfirmation(false)}
              onAccept={handleDeleteUsers}
            />
          </Stack>
        </Card>
      </Collapse>

      {!!usersQuery.data && (
        <TablePagination
          component="div"
          sx={{ borderBottom: 'none' }}
          count={usersQuery.data?.users.totalCount || 0}
          page={filters.values.paging.offset / filters.values.paging.limit}
          onPageChange={handlePageChange(filters)}
          onRowsPerPageChange={handleRowsPerPageChange(filters)}
          rowsPerPage={filters.values.paging.limit}
          rowsPerPageOptions={[5, 10, 15, 20]}
        />
      )}
      <NoResults condition={!!usersQuery.data?.users && usersQuery.data?.users.totalCount === 0}>
        <Table sx={{ opacity: loadingTableState ? 0.5 : 1 }}>
          <TableHead>
            <TableRow>
              <DisplayForMinRole requiredMinRole={Role.moderator}>
                <TableCell padding={'checkbox'}>
                  <Checkbox
                    onClick={() => tableSelect.selectAll()}
                    checked={tableSelect.hasAllSelected}
                  />
                </TableCell>
              </DisplayForMinRole>
              <TableCell>Photo</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>ZIP Code</TableCell>
              <TableCell>
                <TableSortLabel
                  {...tableHeaderSortableByKey('createdAt', filters.values, filters.setValue)}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>Verified</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: usersQuery.isFetching && !usersQuery.isFetched ? 0.2 : 1,
              pointerEvents: usersQuery.isFetching && !usersQuery.isFetched ? 'none' : 'all',
            }}
          >
            {usersQuery?.data?.users?.results?.map((x) => (
              <TableRow key={x.id}>
                <DisplayForMinRole requiredMinRole={Role.moderator}>
                  <TableCell width={10} padding={'checkbox'}>
                    <Checkbox
                      checked={tableSelect.isSelected(x.id)}
                      onClick={() => tableSelect.setItem(x.id)}
                    />
                  </TableCell>
                </DisplayForMinRole>
                <TableCell width={50}>
                  {x.userProfilePhoto.thumbnailUrl && (
                    <Avatar src={x.userProfilePhoto.thumbnailUrl} variant={'square'} />
                  )}
                </TableCell>
                <TableCell>{x.givenName}</TableCell>
                <TableCell>{x.familyName}</TableCell>
                <TableCell>{x.email}</TableCell>
                <TableCell>{x.phoneNumber}</TableCell>
                <TableCell>{x.location.zipCode}</TableCell>
                <TableCell>{x.createdAt && toDate(x.createdAt || '')}</TableCell>

                <TableCell>{x.verification.isVerified ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Tooltip title={'Preview'} placement="top">
                    <IconButton
                      aria-label="preview"
                      href={routes.users.preview.link({ userId: x.id })}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </NoResults>
    </Box>
  );
};
