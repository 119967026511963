import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import {
  GetJobApplicantsQuery,
  GetJobApplicantsQueryVariables,
  GraphqlErrorsType,
} from '../../../common/types';

const queryDocument = loader('../queries/job-applicants.graphql');

export const useJobApplicantsQuery = (
  filters: Pick<GetJobApplicantsQueryVariables, 'paging'>,
): UseQueryResult<GetJobApplicantsQuery, GraphqlErrorsType> => {
  const { jobId: jobParamId } = useParams();

  return useQuery<GetJobApplicantsQuery, GraphqlErrorsType>(
    ['job-applicants', jobParamId, filters],
    async () => {
      return await graphqlRunner<GetJobApplicantsQuery, GetJobApplicantsQueryVariables>(
        queryDocument,
        {
          id: jobParamId || '',
          paging: filters?.paging,
        },
      );
    },
    {
      staleTime: 5000,
      retry: false,
      enabled: !!jobParamId,
    },
  );
};
