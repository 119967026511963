import React from 'react';
import { Chip } from '@mui/material';

export const EnabledDisabledChip: React.FC<{ enabled: boolean }> = (props) => {
  if (props.enabled) {
    return (
      <Chip
        sx={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: 700 }}
        label={'Enabled'}
        color={'primary'}
      />
    );
  }
  return (
    <Chip
      sx={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: 700 }}
      label={'Disabled'}
      color={'error'}
    />
  );
};
