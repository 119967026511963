import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { PhotoValidationStatus } from '../../../common/types';
import { PhotoValidationStatusChip } from '../../jobs/components/details/photos/photos-form/photo-validation-status-chip';
import { useUserQuery } from '../hooks/use-user.query';
import { useWorkerDeletePhotoMutation } from '../hooks/use-worker-delete-photo.mutation';

interface Props {
  id: string;
  url: string;
  thumbnailUrl?: string | null | undefined;
  validationStatus: PhotoValidationStatus;
  order: number;
}

export const WorkerPhoto: React.FC<Props> = (props) => {
  const { userId } = useParams();
  const userQuery = useUserQuery(userId as string);
  const workerDeletePhotoMutation = useWorkerDeletePhotoMutation();

  const handleDeleteButton = useCallback(() => {
    workerDeletePhotoMutation.mutate(
      {
        userId: userId as string,
        photosIds: [props.id],
      },
      {
        onSuccess: () => {
          userQuery.refetch();
        },
      },
    );
  }, [workerDeletePhotoMutation.mutate, userId, props.id]);

  return (
    <Box
      sx={{ p: 1, display: 'inline-block', opacity: workerDeletePhotoMutation.isLoading ? 0.4 : 1 }}
    >
      <Box
        sx={{
          position: 'relative',
          maxHeight: '200px',
          '&:hover': { div: { opacity: 1 } },
          '& img': {
            height: '200px',
          },
          backgroundColor: '#d0d0d0',
          minWidth: '150px',
          height: '100%',
        }}
      >
        <img
          src={`${props.url}?w=164&h=164&fit=contain&auto=format`}
          srcSet={`${props.url}?w=164&h=164&fit=contain&auto=format&dpr=2 2x`}
          alt={props.id}
          loading="lazy"
        />
        <Stack
          direction="row"
          gap={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: '#0008',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            height: '50px',
            opacity: 0,
            transition: 'opacity .3s',
            cursor: 'default',
          }}
        >
          <IconButton aria-label="delete" sx={{ color: 'error.main' }} onClick={handleDeleteButton}>
            <DeleteIcon />
          </IconButton>
        </Stack>
        <PhotoValidationStatusChip status={props.validationStatus} />
      </Box>
    </Box>
  );
};
