import React from 'react';
import { Control, Controller, FormState, UseFormReset } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { UpdateUserInput } from '../../../common/types';
import { FormGroupSection } from '../../../components/form-group-section';
import { useUserQuery } from '../hooks/use-user.query';
import { UserDetailsFormProps } from '../user.page';
import { UserPhoto } from './user-photo';
import { UserSubmit } from './user-submit';

type Props = {
  control: Control<UserDetailsFormProps>;
  disabled?: boolean;
  formState: FormState<UserDetailsFormProps>;
  reset: UseFormReset<UpdateUserInput>;
};

export const UserProfile: React.FC<Props> = (props) => {
  const { userId } = useParams();
  const { data, isLoading } = useUserQuery(userId as string);

  return (
    <FormGroupSection defaultExpanded label="User profile">
      <UserSubmit formState={props.formState} reset={props.reset} />
      <Grid item md={12}>
        <Typography variant={'h6'} sx={{ mb: 2 }}>
          Details
        </Typography>
      </Grid>
      <Stack direction="row">
        <Stack spacing="20px" flex={1}>
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item md={4}>
              <UserPhoto src={data?.user?.userProfilePhoto.url} />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="givenName"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  minLength: {
                    value: 3,
                    message: 'Field must be at least 3 characters long',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    disabled={isLoading || props.disabled}
                    label="First Name"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 4 }}
                  />
                )}
              />
              <Controller
                name="familyName"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  minLength: {
                    value: 3,
                    message: 'Field must be at least 3 characters long',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    disabled={isLoading || props.disabled}
                    label="Last Name"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 4 }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                disabled
                label="E-mail"
                value={data?.user?.email}
                sx={{ mb: 4 }}
                InputProps={{
                  startAdornment: data?.user?.emailVerified ? (
                    <CheckCircleOutlineIcon color={'primary'} sx={{ mr: 1 }} />
                  ) : (
                    <CancelOutlinedIcon color={'secondary'} sx={{ mr: 1 }} />
                  ),
                }}
              />
              <Controller
                name="phoneNumber"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
                    message: 'Invalid phone number format',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    disabled={isLoading || props.disabled}
                    label="Phone number"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 4 }}
                    InputProps={{
                      startAdornment: data?.user?.phoneNumberVerified ? (
                        <CheckCircleOutlineIcon color={'primary'} sx={{ mr: 1 }} />
                      ) : (
                        <CancelOutlinedIcon color={'secondary'} sx={{ mr: 1 }} />
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Grid item md={12}>
        <Typography variant={'h6'} sx={{ mb: 2 }}>
          Location
        </Typography>
      </Grid>
      <Stack direction="row">
        <Stack spacing="20px" flex={1}>
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item md={4}>
              <Controller
                name="location.zipCode"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    disabled={isLoading || props.disabled}
                    label="ZIP Code"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 4 }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="location.coordinates.latitude"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    disabled={isLoading || props.disabled}
                    label="Latitude"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 4 }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="location.coordinates.longitude"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    disabled={isLoading || props.disabled}
                    label="Longitude"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 4 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </FormGroupSection>
  );
};
