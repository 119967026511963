import React, { useMemo } from 'react';
import { RateType } from '../../../common/types';
import { SelectField, SelectFieldOption, SelectFieldProps } from '../../../components/select-field';

type Props = Omit<SelectFieldProps, 'options'>;

export const SelectRatePricingTypeField: React.FC<Props> = (props) => {
  const locationTypeOptions = useMemo<SelectFieldOption[]>(
    () => [
      { value: '', label: '', disabled: true },
      { value: RateType.PerHour.toString(), label: 'Per Hour' },
      { value: RateType.PerJob.toString(), label: 'Per Job' },
    ],
    [],
  );

  return (
    <SelectField
      label={props.label || 'Rate Type'}
      {...props}
      disabled={props.disabled}
      options={locationTypeOptions}
    />
  );
};
