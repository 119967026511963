import React from 'react';
import PreviewIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { OffsetPagingParamsInputOfSortingParamsInput } from '../../../common/types';
import { handlePageChange, handleRowsPerPageChange } from '../../../common/utils/table.utils';
import { FormGroupSection } from '../../../components/form-group-section';
import { routes } from '../../../routes';
import { useFilters } from '../../filter-context';
import { NoResults } from '../../shared/components/no-results';
import { useUserReportsQuery } from '../hooks/use-user-reports.query';

export const UserReports: React.FC = () => {
  const filters = useFilters<{ paging: OffsetPagingParamsInputOfSortingParamsInput }>({
    syncSearchParams: false,
    defaultValues: {
      paging: {
        limit: 10,
        offset: 0,
      },
    },
  });

  const { data } = useUserReportsQuery(filters.values);

  return (
    <FormGroupSection label="User reports">
      <Stack direction="row">
        <Stack spacing="20px" flex={1}>
          {!!data?.user?.sentReports?.results && (
            <TablePagination
              component="div"
              sx={{ borderBottom: 'none' }}
              count={data?.user?.sentReports?.totalCount || 0}
              page={filters.values.paging.offset / filters.values.paging.limit}
              onPageChange={handlePageChange(filters)}
              onRowsPerPageChange={handleRowsPerPageChange(filters)}
              rowsPerPage={filters.values.paging.limit}
              rowsPerPageOptions={[5, 10, 15, 20]}
            />
          )}
          <NoResults condition={data?.user?.sentReports?.results?.length === 0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Avatar</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.user?.sentReports?.results?.map((x) => (
                  <TableRow key={x.id}>
                    <TableCell>{x.user?.givenName}</TableCell>
                    <TableCell>{x.user?.familyName}</TableCell>
                    <TableCell>{x.user?.email}</TableCell>
                    <TableCell>
                      <Avatar src={x.user?.userProfilePhoto?.thumbnailUrl} variant={'square'} />
                    </TableCell>
                    <TableCell>{x.description}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="details"
                        href={routes.users.preview.link({ userId: x.reportedUserId })}
                      >
                        <PreviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </NoResults>
        </Stack>
      </Stack>
    </FormGroupSection>
  );
};
