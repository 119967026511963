import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { GetJobsQueryQueryVariables, LocationType } from '../../../../common/types';
import { DateRange } from '../../../../components/date-range';
import { useFiltersContext } from '../../../filter-context';
import {
  SelectCategoryField,
  SelectCityField,
  SelectLocationTypeField,
  SelectStateField,
  SelectStatusTypeField,
} from '../../../shared';

type JobsFiltersProps = GetJobsQueryQueryVariables;

export const JobsFilters = () => {
  const {
    setValue: setFilterContextValue,
    values,
    reset,
  } = useFiltersContext<GetJobsQueryQueryVariables>();

  const submit = useCallback(
    (body: JobsFiltersProps) => {
      setFilterContextValue({ filter: body.filter, paging: { ...values.paging, offset: 0 } });
    },
    [setFilterContextValue],
  );

  const defaultValues = useMemo<JobsFiltersProps>(
    () => ({
      filter: {
        categoryIds: null,
        state: null,
        city: null,
        createdAtFilter: null,
        createdBy: null,
        filter: null,
        locationType: null,
        rateFilter: null,
        status: null,
      },
      paging: {
        limit: 15,
        offset: 0,
        sorting: [],
      },
    }),
    [],
  );

  const {
    control,
    handleSubmit,
    setValue: setFormValue,
  } = useForm<JobsFiltersProps>({
    defaultValues,
  });

  useEffect(() => {
    setFormValue('filter.categoryIds', values.filter?.categoryIds ?? null);
    setFormValue('filter.city', values.filter?.city);
    setFormValue('filter.state', values.filter?.state);
    setFormValue('filter.createdAtFilter', values.filter?.createdAtFilter);
    setFormValue('filter.createdBy', values.filter?.createdBy);
    setFormValue('filter.filter', values.filter?.filter);
    setFormValue('filter.locationType', values.filter?.locationType);
    setFormValue('filter.rateFilter', values.filter?.rateFilter);
    setFormValue('filter.status', values.filter?.status);
  }, [values]);

  const handleReset = useCallback(() => {
    reset();
  }, [reset, setFilterContextValue]);

  const locationType = useWatch({
    name: 'filter.locationType',
    control: control,
  }) as LocationType;

  const state = useWatch({
    name: 'filter.state',
    control: control,
  }) as string;

  return (
    <Stack spacing={2}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item md={3}>
            <Controller
              name={'filter.filter'}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  label="Search"
                  size="small"
                  name={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Controller
              name={'filter.locationType'}
              control={control}
              render={({ field, fieldState }) => (
                <SelectLocationTypeField
                  fullWidth
                  label={'Location type'}
                  size="small"
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Controller
              name={'filter.state'}
              control={control}
              render={({ field, fieldState }) => (
                <SelectStateField
                  fullWidth
                  disabled={locationType === LocationType.CanBeDoneRemotely}
                  size="small"
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Controller
              name={'filter.city'}
              control={control}
              render={({ field, fieldState }) => (
                <SelectCityField
                  fullWidth
                  disabled={state === null || locationType === LocationType.CanBeDoneRemotely}
                  size="small"
                  state={state ?? ''}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Controller
              name={'filter.status'}
              control={control}
              render={({ field, fieldState }) => (
                <SelectStatusTypeField
                  fullWidth
                  label={'Status'}
                  size="small"
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Controller
              name={'filter.categoryIds'}
              control={control}
              render={({ field, fieldState }) => (
                <SelectCategoryField
                  fullWidth
                  label={'Categories'}
                  size="small"
                  value={(field.value || []) as string[]}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ maxWidth: '280px' }}
                  multiple
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Controller
              name={'filter.createdAtFilter'}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DateRange
                    fullWidth
                    size="small"
                    gap={2}
                    labelFrom={'Created from'}
                    labelTo={'Created to'}
                    value={
                      field.value
                        ? {
                            from: field.value.dateFrom ? new Date(field.value.dateFrom) : undefined,
                            to: field.value.dateTo ? new Date(field.value.dateTo) : undefined,
                          }
                        : undefined
                    }
                    onChange={({ fromDate, toDate }) => {
                      field.onChange({
                        dateFrom: fromDate.toISOString(),
                        dateTo: toDate.toISOString(),
                      });
                    }}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Stack direction="row" gap={2}>
              <Button type={'submit'} fullWidth size="small">
                Search
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                size="small"
                type="button"
                onClick={handleReset}
              >
                Clear
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};
