import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { GetJobsQueryQueryVariables, JobStatus } from '../../../../common/types';
import { toDate } from '../../../../common/utils/dates';
import {
  handlePageChange,
  handleRowsPerPageChange,
  tableHeaderSortableByKey,
} from '../../../../common/utils/table.utils';
import { AlertDialog } from '../../../../components/alert-dialog';
import { routes } from '../../../../routes';
import { DisplayForMinRole, Role } from '../../../auth';
import { useFiltersContext } from '../../../filter-context';
import { useTableSelect } from '../../../shared';
import { useJobsDeleteMutation } from '../../hooks/use-jobs-delete.mutation';
import { useJobsListQuery } from '../../hooks/use-jobs-list.query';
import { JobColorChip } from './job-status-chip';

export const JobsList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const filters = useFiltersContext<GetJobsQueryQueryVariables>();

  const jobsQuery = useJobsListQuery();
  const jobsDeleteMutation = useJobsDeleteMutation();

  const tableSelect = useTableSelect<string>({
    items:
      jobsQuery.data?.jobs.results
        ?.filter((x) => x.status !== JobStatus.Completed)
        .map((x) => x.id) || [],
  });

  const handleDeleteJobs = useCallback(() => {
    setOpenDeleteConfirmation(false);

    jobsDeleteMutation.mutate(
      { jobsId: tableSelect.selected },
      {
        onSuccess: () => {
          jobsQuery.refetch();
          enqueueSnackbar(
            <span>
              <strong>{tableSelect.selected.length}</strong> jobs has been deleted
            </span>,
            { variant: 'success' },
          );
          tableSelect.clearAll();
        },
      },
    );
  }, [tableSelect.selected]);

  const loadingTableState =
    (jobsQuery.isFetching && !jobsQuery.isFetched) || jobsDeleteMutation.isLoading;

  return (
    <Box sx={{ mt: 2 }}>
      <Collapse in={tableSelect.count > 0}>
        <Card sx={{ my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ ml: 2, mr: 1 }}
          >
            <Typography>
              Selected <strong>{tableSelect.count}</strong> row(s)
            </Typography>
            <IconButton
              aria-label="delete"
              sx={{ color: 'error.main' }}
              onClick={() => setOpenDeleteConfirmation(true)}
            >
              <DeleteIcon />
            </IconButton>
            <AlertDialog
              title="Deleting Job(s)"
              description={`Are you sure you want to delete ${tableSelect.count} job(s)?`}
              open={openDeleteConfirmation}
              onClose={() => setOpenDeleteConfirmation(false)}
              onAccept={handleDeleteJobs}
            />
          </Stack>
        </Card>
      </Collapse>

      {!!jobsQuery.data && (
        <TablePagination
          component="div"
          sx={{ borderBottom: 'none' }}
          count={jobsQuery.data?.jobs.totalCount || 0}
          page={filters.values.paging.offset / filters.values.paging.limit}
          onPageChange={handlePageChange(filters)}
          onRowsPerPageChange={handleRowsPerPageChange(filters)}
          rowsPerPage={filters.values.paging.limit}
          rowsPerPageOptions={[5, 10, 15, 20]}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <DisplayForMinRole requiredMinRole={Role.moderator}>
              <TableCell padding={'checkbox'}>
                <Checkbox
                  onClick={() => tableSelect.selectAll()}
                  checked={tableSelect.hasAllSelected}
                />
              </TableCell>
            </DisplayForMinRole>
            <TableCell>
              <TableSortLabel
                {...tableHeaderSortableByKey('title', filters.values, filters.setValue)}
              >
                Title
              </TableSortLabel>
            </TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Photo</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <TableSortLabel
                {...tableHeaderSortableByKey('toBeDoneDate', filters.values, filters.setValue)}
              >
                To Be Done Date
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                {...tableHeaderSortableByKey('createdAt', filters.values, filters.setValue)}
              >
                Created At
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                {...tableHeaderSortableByKey('updatedAt', filters.values, filters.setValue)}
              >
                Updated At
              </TableSortLabel>
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            opacity: loadingTableState ? 0.2 : 1,
            pointerEvents: loadingTableState ? 'none' : 'all',
          }}
        >
          {jobsQuery.data?.jobs.results?.map((x) => (
            <TableRow key={x.id}>
              <DisplayForMinRole requiredMinRole={Role.moderator}>
                <TableCell width={10} padding={'checkbox'}>
                  <Checkbox
                    disabled={x.status === JobStatus.Completed}
                    checked={tableSelect.isSelected(x.id)}
                    onClick={() => tableSelect.setItem(x.id)}
                  />
                </TableCell>
              </DisplayForMinRole>
              <Tooltip title={x.title} enterDelay={300} arrow placement="right">
                <TableCell>{x.title}</TableCell>
              </Tooltip>
              <Tooltip title={x.description} enterDelay={300} arrow placement="right">
                <TableCell>{x.description}</TableCell>
              </Tooltip>
              <TableCell width={40}>
                <Avatar alt={x.title} src={x.photos?.[0]?.thumbnailUrl || ''} variant={'square'}>
                  {x.title.slice(0, 1).toUpperCase()}
                </Avatar>
              </TableCell>
              <TableCell>{x.category?.name}</TableCell>
              <TableCell>
                <JobColorChip status={x.status} />
              </TableCell>
              <TableCell>{toDate(x.toBeDoneDate)}</TableCell>
              <TableCell>{toDate(x.createdAt)}</TableCell>
              <TableCell>{toDate(x.updatedAt)}</TableCell>
              <TableCell>
                <Stack direction="row" gap={1}>
                  <Tooltip title={'Details'} placement="top">
                    <IconButton
                      aria-label="details"
                      href={routes.jobs.details.link({ jobId: x.id })}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {jobsQuery.data?.jobs && jobsQuery.data?.jobs.totalCount === 0 && (
        <Card sx={{ mt: 3, backgroundColor: 'background.default' }}>
          <CardContent>
            <Typography textAlign="center">No results</Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};
