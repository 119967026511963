import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LogoIcon } from '../../components/icons';
import { routes } from '../../routes';
import { useAuth } from './hooks/use-auth.hook';

type FormProps = {
  login: string;
  password: string;
};

export const LoginPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();

  const { control, handleSubmit, formState } = useForm<FormProps>({
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const submit = useCallback(
    async (body: FormProps) => {
      setError(undefined);
      await auth.signIn(
        {
          username: body.login,
          password: body.password,
        },
        {
          onSuccess: () => {
            navigate(routes.users.list.link());
          },
          onError: () => {
            setError('Invalid username or password');
          },
        },
      );
    },
    [setError],
  );

  return (
    <Stack sx={{ height: '100vh' }} justifyContent="center" alignItems="center">
      <Card>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit(submit)}>
            <Stack sx={{ minWidth: '400px' }} gap="16px">
              <Stack alignItems="center" sx={{ mb: 3, mt: 2 }}>
                <Typography variant="h5" align="center" sx={{ mb: 1 }}>
                  Admin Panel
                </Typography>
                <LogoIcon sx={{ fontSize: '180px', height: 'unset' }} />
              </Stack>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Username is required',
                  },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Username should be an email',
                  },
                }}
                name="login"
                render={({ field, fieldState }) => (
                  <TextField
                    label="Username"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="username email"
                    disabled={formState.isSubmitting}
                  />
                )}
              />

              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Password is required',
                  },
                }}
                name="password"
                render={({ field, fieldState }) => (
                  <TextField
                    label="Password"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    type="password"
                    autoComplete="login current-password"
                    disabled={formState.isSubmitting}
                  />
                )}
              />

              {error && <Alert severity="error">{error}</Alert>}

              <Box sx={{ mb: 1, mt: 0, textAlign: 'right' }}>
                <Link href={routes.forgotPassword}>Forgot password</Link>
              </Box>

              <Button disabled={formState.isSubmitting} type="submit">
                Login
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};
