import React from 'react';
import { FormState, UseFormReset } from 'react-hook-form';
import { Button, Card, Collapse, Stack } from '@mui/material';
import { UpdateWorkerProfileInput } from '../../../common/types';
import { useWorkerMutation } from '../hooks/use-worker.mutation';
import { UserDetailsFormProps } from '../user.page';

type Props = {
  formState: FormState<UserDetailsFormProps>;
  reset: UseFormReset<UpdateWorkerProfileInput>;
};

export const WorkerSubmit: React.FC<Props> = ({ formState, reset }) => {
  const workerMutation = useWorkerMutation();

  return (
    <>
      <Collapse in={formState.isDirty && formState.isDirty}>
        <Card sx={{ p: 2, mb: 1 }}>
          <Stack direction="row" spacing={2}>
            <Button type="submit" size="small" disabled={workerMutation.isLoading}>
              Save Changes
            </Button>
            <Button
              color="secondary"
              size="small"
              disabled={workerMutation.isLoading}
              onClick={() => reset()}
            >
              Reset
            </Button>
          </Stack>
        </Card>
      </Collapse>
    </>
  );
};
