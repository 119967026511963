import React, { useCallback, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputBaseProps,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';

export type SelectFieldOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
  groupedOptions?: {
    value: string | number;
    label: string;
    disabled?: boolean;
  }[];
};

export type SelectFieldProps = {
  value?: string;
  onChange: (value?: string) => void;
  onBlur?: (value?: string) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  options: SelectFieldOption[];
  disabled?: boolean;
  sx?: SxProps<Theme>;
  size?: InputBaseProps['size'];
  fullWidth?: boolean;
};

export const SelectField: React.FC<SelectFieldProps> = (props) => {
  const [labelId] = useState(`label-id-${Math.random() * 9999}`);

  const handleOnChange = useCallback(
    (event: SelectChangeEvent) => {
      props.onChange(event.target?.value || '');
    },
    [props.onChange],
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!props.onBlur) return;
      props.onBlur(event.target?.value || '');
    },
    [props.onBlur],
  );

  return (
    <FormControl
      sx={{ minWidth: 210 }}
      fullWidth={props.fullWidth}
      size={props.size}
      disabled={props.disabled}
    >
      <InputLabel error={props.error} id="demo-simple-select-label">
        {props.label}
      </InputLabel>
      <MuiSelect
        size={props.size}
        labelId={labelId}
        label={props.label}
        disabled={props.disabled || !props.options.length}
        value={props.options.length && props.value ? props.value : ''}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        error={props.error}
      >
        {props.options.map((option) => {
          if (option.groupedOptions?.length) {
            return option.groupedOptions.map((o, index) => [
              index === 0 ? (
                <ListSubheader
                  sx={{ color: 'primary.main', textTransform: 'uppercase', fontWeight: '700' }}
                  key={option.value}
                >
                  {option.label}
                </ListSubheader>
              ) : null,
              <MenuItem
                key={o.value}
                value={o.value}
                disabled={o.disabled}
                sx={{ m: !o.label ? 0 : undefined, p: !o.label ? 0 : undefined }}
              >
                {o.label}
              </MenuItem>,
            ]);
          }
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              sx={{ m: !option.label ? 0 : undefined, p: !option.label ? 0 : undefined }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {props.error && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};
