import { useQuery, UseQueryResult } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import {
  GetJobsQueryQuery,
  GetJobsQueryQueryVariables,
  GraphqlErrorsType,
} from '../../../common/types';
import { useFiltersContext } from '../../filter-context';

const queryDocument = loader('../queries/jobs.graphql');

export const useJobsListQuery = (): UseQueryResult<GetJobsQueryQuery, GraphqlErrorsType> => {
  const { values } = useFiltersContext<GetJobsQueryQueryVariables>();

  return useQuery<GetJobsQueryQuery, GraphqlErrorsType>(
    ['jobs', values?.paging, values?.filter],
    async () => {
      return await graphqlRunner<GetJobsQueryQuery, GetJobsQueryQueryVariables>(
        queryDocument,
        values,
      );
    },
    {
      keepPreviousData: true,
      staleTime: 3000,
    },
  );
};
