import React from 'react';
import { Chip } from '@mui/material';
import { DisplayForUser } from './display-for-user';

export const CurrentAdministratorChip: React.FC<{ userId: string; size?: 'small' | 'medium' }> = ({
  userId,
  size,
}) => {
  return (
    <DisplayForUser userId={userId}>
      <Chip
        sx={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: 700 }}
        label={`It's you`}
        color={'info'}
        size={size || 'medium'}
      />
    </DisplayForUser>
  );
};
