import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useUserQuery } from '../hooks/use-user.query';
import { useWorkerAddPhotoMutation } from '../hooks/use-worker-add-photo.mutation';
import { WorkerPhoto } from './worker-photo';

type Props = {
  disabled?: boolean;
};

export const WorkerPhotos: React.FC<Props> = (props) => {
  const inputFiles = useRef<HTMLInputElement>(null);

  const { userId } = useParams();
  const userQuery = useUserQuery(userId as string);

  const workerAddPhotoMutation = useWorkerAddPhotoMutation();

  const handleBrowseFiles = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      workerAddPhotoMutation.mutate(
        {
          userId: userId as string,
          files: event.target.files as FileList,
        },
        {
          onSettled: () => {
            userQuery.refetch();
            if (inputFiles.current) {
              inputFiles.current.value = '';
            }
            setTimeout(() => {
              workerAddPhotoMutation.reset();
            }, 5000);
          },
        },
      );
    },
    [workerAddPhotoMutation.mutate, userQuery.refetch, userId],
  );

  const loading = false;

  return (
    <Box sx={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'all' }}>
      {!props.disabled && (
        <Box sx={{ mb: 1 }}>
          <input
            ref={inputFiles}
            type="file"
            onChange={handleBrowseFiles}
            multiple
            hidden
            accept="image/jpeg, image/png, image/jpg, image/jfif"
          />
          <Button onClick={() => inputFiles.current?.click && inputFiles.current?.click()}>
            Browse files
          </Button>
        </Box>
      )}

      {!userQuery.data?.user?.workerPublicProfile?.pastJobPhotos.length && (
        <Typography>No photos added</Typography>
      )}
      <Box sx={{ mt: 1, pointerEvents: props.disabled ? 'none' : 'all' }}>
        {userQuery.data?.user?.workerPublicProfile?.pastJobPhotos.map((x) => (
          <WorkerPhoto
            key={x.id}
            id={x.id}
            order={x.order}
            thumbnailUrl={x.thumbnailUrl}
            url={x.url}
            validationStatus={x.validationStatus}
          />
        ))}
      </Box>
    </Box>
  );
};
