import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/job-image-delete.graphql');

export const useJobDeleteImageMutation = () => {
  return useMutation<void, GraphqlErrorsType, { jobId: string; jobPhotos: string[] }>(
    ['job-image-delete'],
    async (body) => {
      await graphqlRunner<void, { id: string; jobPhotos: string[] }>(queryDocument, {
        id: body.jobId,
        jobPhotos: body.jobPhotos || [],
      });
    },
  );
};
