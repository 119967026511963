import { createTheme } from '@mui/material';
import { accordionStyles } from './components/accordion.styles';
import { buttonsStyles } from './components/buttons.styles';
import { collapseStyles } from './components/collapse.styles';
import { dialogStyles } from './components/dialog.styles';
import { iconButtonStyles } from './components/icon-button.styles';
import { inputBaseStyles } from './components/input-base.styles';
import { inputStyles } from './components/input.styles';
import { linkStyles } from './components/link.styles';
import { selectStyles } from './components/select.styles';
import { snackbarStyles } from './components/snackbar.styles';
import { tableStyles } from './components/table.styles';
import { textFieldStyles } from './components/text-field.styles';
import { tooltipStyles } from './components/tooltip.styles';
import { paletteOptions } from './pallete';
import { typographyOptions } from './typography';

export const theme = createTheme({
  typography: typographyOptions,
  palette: paletteOptions,
  components: {
    MuiButton: buttonsStyles,
    MuiLink: linkStyles,
    MuiTable: tableStyles,
    MuiTooltip: tooltipStyles,
    MuiIconButton: iconButtonStyles,
    MuiAccordion: accordionStyles,
    MuiSelect: selectStyles,
    MuiCollapse: collapseStyles,
    MuiInput: inputStyles,
    MuiInputBase: inputBaseStyles,
    MuiTextField: textFieldStyles,
    MuiOutlinedInput: inputStyles,
    MuiDialog: dialogStyles,
    MuiSnackbar: snackbarStyles,
  },
});
