import React from 'react';
import { Chip } from '@mui/material';
import { Role, useAuth } from '../../index';

export const ChipRole: React.FC = () => {
  const { userInfo } = useAuth();

  const rolesDictionary: {
    [key in Role]: {
      label: string;
      color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    };
  } = {
    1: {
      label: 'Viewer',
      color: 'secondary',
    },
    2: {
      label: 'Moderator',
      color: 'info',
    },
    4: {
      label: 'Administrator',
      color: 'warning',
    },
  };

  return (
    <Chip
      size="small"
      sx={{ px: 3, textTransform: 'uppercase', fontWeight: 700 }}
      label={rolesDictionary[userInfo?.role || 1]?.label}
      color={rolesDictionary[userInfo?.role || 1]?.color}
    />
  );
};
