import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetJobQueryQuery, GetJobQueryQueryVariables } from '../../../common/types';

const queryDocument = loader('../queries/job.graphql');

export const useJobQuery = (jobId?: string): UseQueryResult<GetJobQueryQuery> => {
  const { jobId: jobParamId } = useParams();

  return useQuery<GetJobQueryQuery, GetJobQueryQueryVariables>(
    ['job', jobId || jobParamId],
    async () => {
      return await graphqlRunner<GetJobQueryQuery, GetJobQueryQueryVariables>(queryDocument, {
        id: jobId || jobParamId || '',
      });
    },
    {
      keepPreviousData: true,
      staleTime: 20000,
      enabled: !!jobParamId,
    },
  );
};
