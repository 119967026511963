import React from 'react';
import { Control, Controller, FormState, UseFormReset, Validate } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { InputMaybe, UpdateWorkerProfileInput } from '../../../common/types';
import { FormGroupSection } from '../../../components/form-group-section';
import { NumberField } from '../../../components/number-field';
import { SelectCategoryField } from '../../shared';
import { SelectCurrency } from '../../shared/components/select-currency';
import { useUserQuery } from '../hooks/use-user.query';
import { WorkerPhotos } from './worker-photos';
import { WorkerSubmit } from './worker-submit';

type Props = {
  control: Control<UpdateWorkerProfileInput>;
  disabled?: boolean;
  formState: FormState<UpdateWorkerProfileInput>;
  reset: UseFormReset<UpdateWorkerProfileInput>;
};

const isValidHttpUrl: Validate<InputMaybe<string> | undefined> = (input) => {
  let url;

  const errorMessages = 'URL is invalid. Correct URL value should starts with http:// or https://';

  try {
    if (input) {
      url = new URL(input);
    }
  } catch (_) {
    return errorMessages;
  }
  if (!url) {
    return errorMessages;
  }

  return url?.protocol === 'http:' || url?.protocol === 'https:';
};

export const WorkerProfile: React.FC<Props> = (props) => {
  const { userId } = useParams();
  const { data } = useUserQuery(userId as string);

  return (
    <FormGroupSection label="Worker Profile">
      <WorkerSubmit formState={props.formState} reset={props.reset} />
      <Stack direction="row">
        <Stack spacing="20px" flex={1}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item md={12}>
              <Typography variant={'h6'} sx={{ mb: 2 }}>
                Details
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Controller
                name="biography"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  maxLength: {
                    value: 9000,
                    message: 'Max 9000 characters',
                  },
                  minLength: {
                    value: 50,
                    message: 'Min 50 characters',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    multiline
                    disabled={props.disabled}
                    label="Biography"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="website"
                control={props.control}
                rules={{
                  validate: {
                    value: isValidHttpUrl,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    disabled={props.disabled}
                    label="Website"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="categoriesIds"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <SelectCategoryField
                    fullWidth
                    multiple
                    disabled={props.disabled}
                    label="Categories"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    maxItems={10}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="willingToTravel"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                }}
                render={({ field }) => (
                  <FormGroup sx={{ width: 'auto', maxWidth: '200px' }}>
                    <FormControlLabel
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '1rem',
                          color: 'rgba(0, 0, 0,' + ' 0.6)',
                        },
                      }}
                      control={
                        <Switch
                          disabled={props.disabled}
                          onChange={field.onChange}
                          value={field.value}
                          checked={field.value}
                        />
                      }
                      label="Willing to travel"
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant={'h6'} sx={{ mb: 2 }}>
                Pricing
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Controller
                name="rate.currency"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <SelectCurrency
                    fullWidth
                    disabled={props.disabled}
                    label="Currency"
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="rate.wage"
                control={props.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  max: {
                    value: 999.99,
                    message: 'Max value is 999.99',
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <NumberField
                      disabled={props.disabled}
                      fullWidth
                      label="Wage"
                      precision={2}
                      name={field.name}
                      value={field.value || ''}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant={'h6'} sx={{ mb: 2 }}>
                Statistics
              </Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                disabled
                label="Average Rating"
                value={data?.user?.workerPublicProfile?.statistics.averageRating}
                sx={{ mb: 4 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                disabled
                label="Ratings Count"
                value={data?.user?.workerPublicProfile?.statistics.ratingsCount}
                sx={{ mb: 4 }}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant={'h6'} sx={{ mb: 2 }}>
                Certificates
              </Typography>
            </Grid>
            {data?.user?.workerPublicProfile?.certificates?.length === 0 && (
              <Grid item md={12} sx={{ pt: 0 }}>
                <Card sx={{ p: 2, mt: 0 }}>No certificates added</Card>
              </Grid>
            )}
            {data?.user?.workerPublicProfile?.certificates?.map((x, index) => (
              <>
                <Grid item md={6}>
                  <Controller
                    name={`certificates.${index}.name`}
                    control={props.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        disabled={props.disabled}
                        label="Name"
                        name={field.name}
                        value={field.value || x.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{ mb: 4 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name={`certificates.${index}.yearAwarded`}
                    control={props.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        disabled={props.disabled}
                        label="Year Awarded"
                        name={field.name}
                        value={field.value || x.yearAwarded}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{ mb: 4 }}
                      />
                    )}
                  />
                </Grid>
              </>
            ))}
            <Grid item md={12}>
              <Typography variant={'h6'} sx={{ mb: 2 }}>
                Past job photos
              </Typography>
            </Grid>
            <Grid item md={12}>
              <WorkerPhotos disabled={props.disabled} />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </FormGroupSection>
  );
};
