import React from 'react';
import PreviewIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { OffsetPagingParamsInputOfSortingParamsInput } from '../../../../../common/types';
import { handlePageChange, handleRowsPerPageChange } from '../../../../../common/utils/table.utils';
import { routes } from '../../../../../routes';
import { useFilters } from '../../../../filter-context';
import { useJobApplicantsQuery } from '../../../hooks/use-job-applicants.query';
import { ApplicantStatus } from './applicants-list/applicant-status';

export const ApplicantsList: React.FC = () => {
  const filters = useFilters<{ paging: OffsetPagingParamsInputOfSortingParamsInput }>({
    syncSearchParams: false,
    defaultValues: {
      paging: {
        limit: 10,
        offset: 0,
      },
    },
  });

  const jobApplicantsQuery = useJobApplicantsQuery(filters.values);

  if (jobApplicantsQuery.data && jobApplicantsQuery.data.job?.applicants.totalCount === 0) {
    return (
      <Box>
        <Card>
          <CardContent>
            <Typography align="center">No applicants</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      {!!jobApplicantsQuery.data && (
        <TablePagination
          component="div"
          sx={{ borderBottom: 'none' }}
          count={jobApplicantsQuery.data?.job?.applicants.totalCount || 15}
          page={filters.values.paging.offset / filters.values.paging.limit}
          onPageChange={handlePageChange(filters)}
          onRowsPerPageChange={handleRowsPerPageChange(filters)}
          rowsPerPage={filters.values.paging.limit}
          rowsPerPageOptions={[5, 10, 15, 20]}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Avatar</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Wage</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobApplicantsQuery.data?.job?.applicants.results?.map((applicant) => (
            <TableRow key={applicant.workerId}>
              <TableCell>
                <Avatar variant={'square'} src={applicant.profileThumbnailUrl}>
                  {applicant.givenName.slice(0, 1)}
                  {applicant.familyName.slice(0, 1)}
                </Avatar>
              </TableCell>
              <TableCell>{applicant.givenName}</TableCell>
              <TableCell>{applicant.familyName}</TableCell>
              <TableCell>
                <ApplicantStatus status={applicant.status} />
              </TableCell>
              <TableCell>{applicant.wage}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="details"
                  href={routes.users.preview.link({ userId: applicant.workerId })}
                >
                  <PreviewIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
