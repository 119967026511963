import React from 'react';
import { Chip } from '@mui/material';
import { Role } from '../../../common/types';

export const AdministratorRoleChip: React.FC<{
  role?: Role;
  size?: 'small' | 'medium';
}> = ({ role, size }) => {
  const rolesDictionary: {
    [key in Role]: {
      label: string;
      color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    };
  } = {
    NONE: {
      label: 'No role assigned',
      color: 'error',
    },
    VIEWER: {
      label: 'Viewer',
      color: 'secondary',
    },
    MODERATOR: {
      label: 'Moderator',
      color: 'info',
    },
    ADMINISTRATOR: {
      label: 'Administrator',
      color: 'warning',
    },
  };

  return (
    <Chip
      size={size || 'small'}
      sx={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: 700 }}
      label={rolesDictionary[role || Role.Viewer]?.label}
      color={rolesDictionary[role || Role.Viewer]?.color}
    />
  );
};
