import { PaletteOptions } from '@mui/material/styles/createPalette';

export const paletteOptions: PaletteOptions = {
  mode: 'light',
  contrastThreshold: 2,
  primary: {
    main: '#00D192',
  },
  secondary: {
    main: '#919497',
  },
  error: {
    main: '#FF5757',
  },
  text: {
    primary: '#232930',
  },
  background: {
    default: '#F0F2F1',
    paper: '#FEFEFE',
  },
  warning: {
    main: '#FFC83B',
  },
};
