import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { AlertDialog } from '../../../../components/alert-dialog';
import { FormGroupSection } from '../../../../components/form-group-section';
import { routes } from '../../../../routes';
import { useAuth } from '../../../auth';
import { useAdministratorQuery } from '../../hooks/use-administrator.query';
import { useBlockUnblockAdministratorMutation } from '../../hooks/use-block-unblock-administrator.mutation';
import { useDeleteAdministratorMutation } from '../../hooks/use-delete-administrator.mutation';

export const DangerZone: React.FC = () => {
  const { adminId } = useParams();
  const { userInfo } = useAuth();
  const administratorQuery = useAdministratorQuery(adminId || '');
  const blockUnblockAdministratorMutation = useBlockUnblockAdministratorMutation();
  const deleteAdministratorMutation = useDeleteAdministratorMutation();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const navigate = useNavigate();

  if (userInfo?.id === adminId) {
    return null;
  }

  const handleBlockUnblockUser = useCallback(() => {
    blockUnblockAdministratorMutation.mutate(
      {
        id: adminId || '',
        value: !!administratorQuery.data?.administrator?.enabled,
      },
      {
        onSettled: () => {
          administratorQuery.refetch();
        },
      },
    );
  }, [administratorQuery.data?.administrator?.enabled]);

  const handleDeleteUser = useCallback(() => {
    deleteAdministratorMutation.mutate(
      {
        ids: [adminId || ''],
      },
      {
        onSuccess: () => {
          setOpenDeleteConfirmation(false);
          administratorQuery.remove();
          navigate(routes.admins.list.link());
        },
        onError: () => {
          setOpenDeleteConfirmation(false);
          navigate(routes.admins.list.link());
        },
      },
    );
  }, []);

  return (
    <FormGroupSection label={<Typography sx={{ color: 'error.main' }}>Danger Zone</Typography>}>
      <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 3 }} columns={8}>
        <Grid item>
          <Button
            aria-label="disable"
            color={administratorQuery.data?.administrator?.enabled ? 'error' : 'success'}
            variant={'outlined'}
            onClick={handleBlockUnblockUser}
          >
            {administratorQuery.data?.administrator?.enabled ? 'Disable' : 'Enable'}
          </Button>
        </Grid>
        <Grid item>
          <Button aria-label="delete" color="error" onClick={() => setOpenDeleteConfirmation(true)}>
            Delete
          </Button>
          <AlertDialog
            title="Deleting Administrator"
            description={`Are you sure you want to delete the administrator?`}
            open={openDeleteConfirmation}
            onClose={() => setOpenDeleteConfirmation(false)}
            onAccept={handleDeleteUser}
          />
        </Grid>
      </Grid>
    </FormGroupSection>
  );
};
