import React, { useMemo, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import NavBar from './components/nav-bar';
import SideBar from './components/side-bar';

export const DashboardLayout: React.FC = () => {
  const drawerWidth = 240;
  const appBarRef = useRef<HTMLDivElement>(null);
  const appBarHeight = useMemo(() => appBarRef.current?.clientHeight || 64, [appBarRef.current]);

  return (
    <Stack direction="row">
      <NavBar drawerWidth={drawerWidth} appBarRef={appBarRef} />
      <SideBar drawerWidth={drawerWidth} appBarHeight={appBarHeight} />
      <Box sx={{ px: 4, pb: 4, pt: `calc(${appBarHeight}px + 30px)`, width: '100%' }}>
        <Outlet />
      </Box>
    </Stack>
  );
};
