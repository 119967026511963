import { ButtonClasses, ButtonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { LinkBehavior } from '../../components/link-behavior';

export const buttonsStyles: {
  defaultProps?: Partial<ButtonProps<'button'>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof ButtonClasses>> | undefined;
} = {
  defaultProps: {
    color: 'primary',
    variant: 'contained',
    LinkComponent: LinkBehavior,
  },
  styleOverrides: {
    root: {
      borderRadius: 0,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'none',
      minWidth: '155px',
      padding: '16px',
    },
    sizeLarge: {
      fontSize: '18px',
      lineHeight: '24px',
      textTransform: 'none',
      minWidth: '195px',
      padding: '16px',
    },
    sizeMedium: {
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'none',
      minWidth: '155px',
      padding: '16px',
    },
    sizeSmall: {
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'none',
      minWidth: '80px',
      padding: '8px',
    },
    containedPrimary: {
      color: 'white',
    },
  },
};
