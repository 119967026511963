import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import to from 'await-to-js';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { routes } from '../../../routes';

type CallbackFunctions = {
  onSuccess?: () => void;
  onError?: (err?: Error) => void;
};

export enum Role {
  administrator = 4,
  moderator = 2,
  viewer = 1,
}

type UserInfo = {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  role: Role;
};

type CognitoUserInfo = {
  username: string;
  attributes: {
    sub: string;
    email_verified: boolean;
    phone_number_verified: boolean;
    phone_number: string;
    email: string;
    family_name: string;
    given_name: string;
  };
  signInUserSession?: {
    accessToken?: {
      jwtToken: string;
      payload: {
        'cognito:groups': string[];
      };
    };
  };
  authenticationFlowType?: string;
  userDataKey?: string;
  challengeName?: string;
};

type SingInInput = {
  username: string;
  password: string;
};

type ConfirmInput = {
  password: string;
};

type ChangePasswordInput = {
  password: string;
  oldPassword: string;
};

type ForgotPasswordInput = {
  code: string;
  password: string;
  username: string;
};

type AuthContextType = {
  signIn: (body: SingInInput, callbacks?: CallbackFunctions) => void;
  signOut: (callbacks?: CallbackFunctions) => void;
  confirmAccount: (body: ConfirmInput, callbacks?: CallbackFunctions) => void;
  changePassword: (body: ChangePasswordInput, callbacks?: CallbackFunctions) => void;
  forgotPassword: (body: ForgotPasswordInput, callbacks?: CallbackFunctions) => void;
  userInfo: UserInfo | null;
  authenticated: boolean;
  initialized: boolean;
  token?: string;
  userRole: Role;
  cognitoUser?: CognitoUserInfo | null;
  userId: string | null;
};

export const AuthContext = React.createContext<AuthContextType>({
  signIn: () => null,
  signOut: () => null,
  confirmAccount: () => null,
  changePassword: () => null,
  forgotPassword: () => null,
  userInfo: null,
  authenticated: false,
  initialized: false,
  userRole: Role.viewer,
  cognitoUser: null,
  userId: null,
});

export const AuthContextProvider: React.FC = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [token, setToken] = useState<string | undefined>();
  const [cognitoUser, setCognitoUser] = useState<CognitoUserInfo | null>();

  const parseArrayRolesToRoleEnum = useCallback((roles: string[]): Role => {
    if (roles.some((x) => x === 'admins')) return Role.administrator;
    if (roles.some((x) => x === 'moderators')) return Role.moderator;
    return Role.viewer;
  }, []);

  const setUserData = useCallback(
    (user: CognitoUserInfo) => {
      setCognitoUser(user);
      setToken(user.signInUserSession?.accessToken?.jwtToken);
      setUserInfo({
        id: user.attributes.sub,
        emailAddress: user.attributes.email,
        phoneNumber: user.attributes.phone_number,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        role: parseArrayRolesToRoleEnum(
          user.signInUserSession?.accessToken?.payload['cognito:groups'] || [],
        ),
      });
    },
    [setUserInfo, setToken, setCognitoUser],
  );

  const fetchCurrentUserSession = useCallback(async (shouldRedirectOnError?: boolean) => {
    const [error, user] = await to<CognitoUserInfo>(Auth.currentAuthenticatedUser());
    if (user && !error) {
      setInitialized(true);
      setUserData(user);
      return;
    }
    setInitialized(true);
    if (shouldRedirectOnError) {
      navigate(routes.login);
      return;
    }
    throw new Error('No user is logged in');
  }, []);

  const signIn = useCallback(
    async (body: SingInInput, callbacks?: CallbackFunctions) => {
      const [error, user] = await to<CognitoUserInfo, { code: string }>(
        Auth.signIn({
          username: body.username,
          password: body.password,
        }),
      );

      setCognitoUser(user);

      if (user && !error) {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          navigate(routes.confirm);
          return;
        }
        setUserData(user);
        callbacks?.onSuccess && callbacks?.onSuccess();
        return;
      }

      if (error && error.code === 'PasswordResetRequiredException') {
        const searchParams = new URLSearchParams();
        searchParams.set('username', body.username);
        navigate(routes.forgotPassword + '?' + searchParams.toString());
        return;
      }

      callbacks?.onError && callbacks?.onError();
    },
    [setUserData, setCognitoUser],
  );

  const confirmAccount = useCallback(
    async (body: ConfirmInput, callbacks?: CallbackFunctions) => {
      if (!cognitoUser) {
        navigate(routes.login);
      }
      const [completePasswordError] = await to(
        Auth.completeNewPassword(cognitoUser, body.password),
      );
      if (completePasswordError) {
        callbacks?.onError && callbacks?.onError();
        return;
      }
      const [currentUserSessionError] = await to(fetchCurrentUserSession());
      if (currentUserSessionError) {
        callbacks?.onError && callbacks?.onError();
        return;
      }
      callbacks?.onSuccess && callbacks?.onSuccess();
    },
    [fetchCurrentUserSession, cognitoUser],
  );

  const changePassword = useCallback(
    async (body: ChangePasswordInput, callbacks?: CallbackFunctions) => {
      const [error] = await to(Auth.changePassword(cognitoUser, body.oldPassword, body.password));
      if (error) {
        callbacks?.onError && callbacks?.onError(error);
        return;
      }
      await fetchCurrentUserSession();
      callbacks?.onSuccess && callbacks?.onSuccess();
    },
    [fetchCurrentUserSession, cognitoUser],
  );

  const forgotPassword = useCallback(
    async (body: ForgotPasswordInput, callbacks?: CallbackFunctions) => {
      const [error] = await to(Auth.forgotPasswordSubmit(body.username, body.code, body.password));
      if (error) {
        callbacks?.onError && callbacks?.onError(error);
        return;
      }
      enqueueSnackbar('Your password has been successfully changed', { variant: 'success' });
      navigate(routes.login);
      callbacks?.onSuccess && callbacks?.onSuccess();
    },
    [fetchCurrentUserSession, navigate],
  );

  const signOut = useCallback(
    async (callbacks?: CallbackFunctions) => {
      const [error] = await to(Auth.signOut());
      if (error) {
        callbacks?.onError && callbacks?.onError();
        return;
      }
      setUserInfo(null);
      setToken(undefined);
      setCognitoUser(undefined);
      callbacks?.onSuccess && callbacks?.onSuccess();
    },
    [setUserInfo, setToken, setCognitoUser],
  );

  useEffect(() => {
    fetchCurrentUserSession(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        userInfo,
        confirmAccount,
        changePassword,
        forgotPassword,
        token,
        authenticated: !!userInfo,
        initialized,
        userRole: userInfo?.role || Role.viewer,
        cognitoUser: cognitoUser || null,
        userId: userInfo?.id || null,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
