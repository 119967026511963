import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/worker-photo-delete.graphql');

export const useWorkerDeletePhotoMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, GraphqlErrorsType, { userId: string; photosIds: string[] }>(
    ['worker-photo-delete'],
    async (body) => {
      await graphqlRunner<void, { id: string; photosIds: string[] }>(queryDocument, {
        id: body.userId,
        photosIds: body.photosIds || [],
      });
    },
    {
      onSuccess: () => {
        enqueueSnackbar(`Photo deleted successful`, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error?.errors?.map((x) => x.message).join('\n, ') || 'Error', {
          variant: 'error',
        });
      },
    },
  );
};
