import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Box } from '@mui/material';
import { PhotoItem, SortablePhoto } from './sortable-photo';

export const SortablePhotosContainer = SortableContainer((props: { items: PhotoItem[] }) => {
  return (
    <Box sx={{ m: -1 }}>
      {props.items
        .sort((item) => item.order)
        .map((item, index) => (
          <SortablePhoto key={`item-${item.id}`} index={index} value={{ ...item, index }} />
        ))}
    </Box>
  );
});
