import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType, UpdateJobInput } from '../../../common/types';

const queryDocument = loader('../queries/job-update.graphql');

export const useJobMutation = () => {
  return useMutation<void, GraphqlErrorsType, UpdateJobInput>(['job-update'], async (body) => {
    await graphqlRunner<void, { input: UpdateJobInput }>(queryDocument, {
      input: body,
    });
  });
};
