import React, { useMemo } from 'react';
import { ToBeDoneType } from '../../../common/types';
import { SelectField, SelectFieldOption, SelectFieldProps } from '../../../components/select-field';

type Props = Omit<SelectFieldProps, 'options'>;

export const SelectToBeDoneTypeField: React.FC<Props> = (props) => {
  const toBeDoneTypeOptions = useMemo<SelectFieldOption[]>(
    () => [
      { value: ToBeDoneType.None.toString(), label: '', disabled: true },
      { value: ToBeDoneType.Tomorrow.toString(), label: 'Tomorrow' },
      { value: ToBeDoneType.WithinThreeDays.toString(), label: 'Within Three Days' },
      { value: ToBeDoneType.CustomSchedule.toString(), label: 'Custom Schedule' },
    ],
    [],
  );

  return (
    <SelectField
      label={props.label || 'To Be Done Type'}
      disabled={props.disabled}
      options={toBeDoneTypeOptions}
      {...props}
    />
  );
};
