import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Chip, Stack, Typography } from '@mui/material';
import { useUserQuery } from '../hooks/use-user.query';

export const UserHeader: React.FC = () => {
  const { userId } = useParams();
  const { data } = useUserQuery(userId as string);

  return (
    <Stack
      sx={{
        p: 2,
        borderRadius: '4px',
        mb: 3,
      }}
      component={Card}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5" sx={{ maxWidth: '50%' }}>
        {`${data?.user?.givenName} ${data?.user?.familyName}`}
      </Typography>
      <Stack direction="row" spacing={3} alignItems="center">
        <Chip
          size={'small'}
          label={data?.user?.enabled ? 'active' : 'disabled'}
          sx={{ fontSize: '16px', px: 3, py: 2, textTransform: 'uppercase' }}
          color={data?.user?.enabled ? 'primary' : 'error'}
        />
        <Chip
          size={'small'}
          label={data?.user?.verification.isVerified ? 'verified' : 'not verified'}
          sx={{ fontSize: '16px', px: 3, py: 2, textTransform: 'uppercase' }}
          color={data?.user?.verification.isVerified ? 'primary' : 'error'}
        />
      </Stack>
    </Stack>
  );
};
