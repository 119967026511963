import { API } from 'aws-amplify';
import { DocumentNode } from 'graphql/language/ast';
import { GraphQLResult } from '@aws-amplify/api-graphql/src/types/index';
import { GraphqlErrorsType } from '../types';

export const graphqlRunner = async <TResult, TVariables extends Record<string, unknown>>(
  documentNode: string | DocumentNode,
  variables?: TVariables,
): Promise<TResult> => {
  const response = (await API.graphql({
    variables: variables as unknown as TVariables,
    query: documentNode,
  })) as GraphQLResult<Response>;

  if (response.errors) {
    throw response.errors as unknown as GraphqlErrorsType;
  }

  return response?.data as unknown as TResult;
};
