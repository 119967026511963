import { useQuery, UseQueryResult } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetCitiesQuery, GetCitiesQueryVariables, GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/cities.graphql');

export const useCitiesQuery = (
  state: string,
): UseQueryResult<GetCitiesQuery, GraphqlErrorsType> => {
  return useQuery<GetCitiesQuery, GraphqlErrorsType>(
    ['cities'],
    async () => {
      return await graphqlRunner<GetCitiesQuery, GetCitiesQueryVariables>(queryDocument, {
        state: state,
      });
    },
    {
      keepPreviousData: true,
      staleTime: 20000,
      enabled: !!state,
    },
  );
};
