import { useQuery, UseQueryResult } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetAdministratorsQuery, GetAdministratorsQueryVariables } from '../../../common/types';

const queryDocument = loader('../queries/administrators.graphql');

export const useAdministratorsQuery = (): UseQueryResult<GetAdministratorsQuery> => {
  return useQuery<GetAdministratorsQuery, GetAdministratorsQueryVariables>(
    ['administrators'],
    async () => {
      return await graphqlRunner<GetAdministratorsQuery, GetAdministratorsQueryVariables>(
        queryDocument,
      );
    },
    {
      staleTime: 2000,
    },
  );
};
