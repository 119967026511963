import { DialogClasses, DialogProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const dialogStyles: {
  defaultProps?: Partial<DialogProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof DialogClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    paper: {
      borderRadius: 0,
    },
    root: {
      '& .MuiDialogActions-root': {
        padding: '16px',
      },
    },
  },
};
