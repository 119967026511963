import { useMutation } from 'react-query';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { isValidImageFileSize, isValidImageFileType } from '../../../common/utils/image-type.utils';

export const useWorkerAddPhotoMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<number, Error, { userId: string; files: FileList }>(
    ['worker-add-photo'],
    async (body) => {
      const filesAsArray = Array.from(body.files);

      for (const file of filesAsArray) {
        if (!isValidImageFileType(file)) {
          throw new Error('Invalid file type');
        }

        if (!isValidImageFileSize(file)) {
          throw new Error('Invalid file size');
        }

        const form = new FormData();

        form.append('userId', body.userId);
        form.append('file', file);

        try {
          await API.post('API_URL', '/past-jobs/photos', {
            body: form,
          });
        } catch (err) {
          throw new Error('Limit of photos has been reached');
        }
      }

      return filesAsArray?.length || 0;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('New photos has ben added', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );
};
