import React from 'react';
import { UseFiltersType } from '../types/use-filters.type';

export const FiltersContext = React.createContext<UseFiltersType>({
  values: {},
  reset: () => null,
  setValue: () => null,
});

export const FiltersProvider: React.FC<UseFiltersType> = (props) => {
  const { children, ...filters } = props;

  return <FiltersContext.Provider value={filters}>{children}</FiltersContext.Provider>;
};
