import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { ToBeDoneType } from '../../../../common/types';
import { DateField } from '../../../../components/date-field';
import { SelectCategoryField, SelectToBeDoneTypeField } from '../../../shared';
import { useJobQuery } from '../../hooks/use-job.query';
import { JobDetailsFormProps } from '../../job-details.page';

type Props = {
  control: Control<JobDetailsFormProps>;
  disabled?: boolean;
};

export const GeneralForm: React.FC<Props> = (props) => {
  const jobQuery = useJobQuery();

  const toBeDoneTypeValue = useWatch({
    name: 'toBeDoneType',
    control: props.control,
  });

  return (
    <Box>
      <Typography variant={'h6'} sx={{ mb: 2 }}>
        General
      </Typography>
      <Grid container sx={{ width: '100%' }} rowSpacing={3} columnSpacing={2}>
        <Grid item md={4}>
          <Controller
            name="title"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'Title is required',
              },
              maxLength: {
                value: 22,
                message: 'Max 22 characters',
              },
              minLength: {
                value: 3,
                message: 'Min. 3 characters',
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                disabled={jobQuery.isLoading || props.disabled}
                label="Title"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            name="categoryId"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'Category is required',
              },
            }}
            render={({ field, fieldState }) => (
              <SelectCategoryField
                fullWidth
                disabled={jobQuery.isLoading || props.disabled}
                label="Category"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Controller
            name="description"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'Description is required',
              },
              maxLength: {
                value: 9000,
                message: 'Max description length is 2500 chars',
              },
              minLength: {
                value: 3,
                message: 'Min. 3 characters',
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                disabled={jobQuery.isLoading || props.disabled}
                label="Description"
                multiline
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={8}>
          <Controller
            name="requiredTools"
            control={props.control}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                disabled={jobQuery.isLoading || props.disabled}
                label="Required Tools"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} />

        <Grid item md={8}>
          <Controller
            name="additionalConsiderations"
            control={props.control}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                disabled={jobQuery.isLoading || props.disabled}
                label="Additional considerations"
                multiline
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} />

        <Grid item md={4}>
          <Controller
            name="toBeDoneType"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'To Be Done Type is required',
              },
            }}
            render={({ field, fieldState }) => (
              <SelectToBeDoneTypeField
                fullWidth
                disabled={jobQuery.isLoading || props.disabled}
                label="To be done type"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        {(toBeDoneTypeValue as ToBeDoneType) === ToBeDoneType.CustomSchedule && (
          <Grid item md={4}>
            <Controller
              name="toBeDoneDate"
              control={props.control}
              rules={{
                required: {
                  value: true,
                  message: 'To Be Done Date is required',
                },
              }}
              render={({ field, fieldState }) => (
                <DateField
                  fullWidth
                  disabled={jobQuery.isLoading || props.disabled}
                  label="To Be Done Date"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  minDate={new Date()}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
