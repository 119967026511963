import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { routes } from '../../routes';
import { useAuth } from './hooks/use-auth.hook';

type FormProps = {
  password: string;
  repeatedPassword: string;
};

export const ConfirmationPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState<string | undefined>();

  const {
    control,
    handleSubmit,
    formState,
    setError: setFormError,
  } = useForm<FormProps>({
    defaultValues: {
      password: '',
      repeatedPassword: '',
    },
  });

  const submit = useCallback(
    async (body: FormProps) => {
      if (body.password !== body.repeatedPassword) {
        setFormError('repeatedPassword', {
          type: 'VALIDATION_ERROR',
          message: 'Repeated password must be the same as password',
        });

        return;
      }

      setError(undefined);
      await auth.confirmAccount(
        {
          password: body.password,
        },
        {
          onSuccess: () => {
            navigate(routes.users.root);
          },
          onError: () => {
            setError(
              'Password Requirements: Min. 8 characters, at least 1 uppercase, 1 digit and 1 special character (do not use < or >)',
            );
          },
        },
      );
    },
    [setError, setFormError],
  );

  return (
    <Stack sx={{ height: '100vh' }} justifyContent="center" alignItems="center">
      <Card>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit(submit)}>
            <Stack sx={{ minWidth: '400px' }} gap="16px">
              <Stack alignItems="center" sx={{ mb: 3, mt: 2 }}>
                <Typography variant="h5" align="center" sx={{ mb: 1 }}>
                  Activate New Account
                </Typography>
                {/*<LogoIcon sx={{ fontSize: '180px', height: 'unset' }} />*/}
              </Stack>

              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Password is required',
                  },
                }}
                name="password"
                render={({ field, fieldState }) => (
                  <TextField
                    type="password"
                    label="New password"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="confirm new-password"
                    disabled={formState.isSubmitting}
                  />
                )}
              />

              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Repeated Password is required',
                  },
                }}
                name="repeatedPassword"
                render={({ field, fieldState }) => (
                  <TextField
                    label="Repeat new password"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    type="password"
                    autoComplete="confirm repeated new-password"
                    disabled={formState.isSubmitting}
                  />
                )}
              />

              {error && <Alert severity="error">{error}</Alert>}

              <Button disabled={formState.isSubmitting} type="submit">
                Confirm
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};
