import React from 'react';
import { DatePicker } from '@mui/lab';
import { TextField, TextFieldProps } from '@mui/material';

type Props = Omit<TextFieldProps, 'onChange' | 'onBlur'> & {
  onChange?: (value?: Date) => void;
  onBlur?: () => void;
  minDate?: Date;
};

export const DateField: React.FC<Props> = (props) => {
  return (
    <DatePicker
      label={props.label}
      value={props.value}
      disabled={props.disabled}
      onChange={(newValue) => {
        props.onChange && props.onChange(newValue as Date);
      }}
      onClose={() => props.onBlur && props.onBlur()}
      renderInput={(params) => <TextField fullWidth={props.fullWidth} {...params} />}
      minDate={props.minDate}
    />
  );
};
