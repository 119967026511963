import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, IconButton, Stack } from '@mui/material';
import { useUserPhotoMutation } from '../hooks/use-user-photo.mutation';
import { useUserQuery } from '../hooks/use-user.query';

type Props = {
  src: string | undefined;
};

export const UserPhoto: React.FC<Props> = ({ src }) => {
  const inputFiles = useRef<HTMLInputElement>(null);
  const userQuery = useUserQuery();
  const userPhotoMutation = useUserPhotoMutation();

  const { userId } = useParams();

  const handleBrowseFiles = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      userPhotoMutation.mutate(
        {
          userId: userId as string,
          files: event.target.files as FileList,
        },
        {
          onSettled: () => {
            userQuery.refetch();
            if (inputFiles.current) {
              inputFiles.current.value = '';
            }
            setTimeout(() => {
              userPhotoMutation.reset();
            }, 5000);
          },
        },
      );
    },
    [userPhotoMutation.mutate, userQuery.refetch, userQuery.data?.user?.id],
  );

  const disabled = false;

  const jobDeleteImageMutation = {
    isLoading: false,
  };

  return (
    <>
      <input
        ref={inputFiles}
        type="file"
        onChange={handleBrowseFiles}
        hidden
        accept="image/jpeg, image/png, image/jpg, image/jfif"
      />

      <Box
        sx={{
          display: 'inline-block',
          opacity: jobDeleteImageMutation.isLoading ? 0.4 : 1,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '280px',
            width: '280px',
            '&:hover': { div: { opacity: 1 } },
            '& img': {
              objectFit: 'cover',
            },
            backgroundColor: '#d0d0d0',
          }}
        >
          <img alt="avatar" src={src} loading="lazy" width={280} height={280} />
          {!disabled && (
            <Stack
              direction="row"
              gap={2}
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: '#0008',
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                height: '50px',
                opacity: 0,
                transition: 'opacity .3s',
                cursor: 'default',
              }}
            >
              <IconButton
                aria-label="replace"
                sx={{ color: 'error.main' }}
                onClick={() => inputFiles.current?.click && inputFiles.current?.click()}
              >
                <CachedIcon />
              </IconButton>
            </Stack>
          )}
        </Box>
      </Box>
    </>
  );
};
