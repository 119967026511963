import React from 'react';
import { UseFiltersType } from '../../modules/filter-context/types/use-filters.type';
import { OffsetPagingParamsInputOfSortingParamsInput, SortingDirection } from '../types';

export const tableHeaderSortableByKey = (
  key: string,
  variables: { paging: OffsetPagingParamsInputOfSortingParamsInput },
  setFilter: (value: { paging: OffsetPagingParamsInputOfSortingParamsInput }) => void,
) => {
  const keyDirection = variables.paging.sorting?.find((x) => x.key === key);

  return {
    active: !!keyDirection,
    direction:
      keyDirection?.direction === SortingDirection.Asc
        ? 'asc'
        : ('desc' as 'desc' | 'asc' | undefined),
    onClick: () => {
      if (keyDirection) {
        setFilter({
          paging: {
            ...variables.paging,
            sorting: [
              {
                key: keyDirection.key,
                direction:
                  keyDirection?.direction === SortingDirection.Asc
                    ? SortingDirection.Desc
                    : SortingDirection.Asc,
              },
            ],
          },
        });
      } else {
        setFilter({
          paging: {
            ...variables.paging,
            sorting: [
              {
                key: key,
                direction: SortingDirection.Desc,
              },
            ],
          },
        });
      }
    },
  };
};

export const handlePageChange =
  (filters: UseFiltersType<{ paging: OffsetPagingParamsInputOfSortingParamsInput }>) =>
  (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    filters.setValue({
      paging: {
        ...filters.values.paging,
        limit: filters.values.paging?.limit || 0,
        offset: page * (filters.values.paging?.limit || 0),
      },
    });
  };

export const handleRowsPerPageChange =
  (filters: UseFiltersType<{ paging: OffsetPagingParamsInputOfSortingParamsInput }>) =>
  (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    filters.setValue({
      paging: { ...filters.values.paging, limit: Number(event.target.value) || 10, offset: 0 },
    });
  };
