import { TooltipClasses, TooltipProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const tooltipStyles: {
  defaultProps?: Partial<TooltipProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TooltipClasses>> | undefined;
} = {
  defaultProps: {
    placement: 'top-end',
    enterDelay: 300,
  },
  styleOverrides: {
    tooltip: {
      fontSize: '13px',
      rippleBackgroundColor: '#FFF',
      padding: '0.5rem',
      opacity: 1,
      whiteSpace: 'pre-wrap',
    },
  },
};
