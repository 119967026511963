import React from 'react';
import { Role, useAuth } from '../../index';

type Props = {
  requiredMinRole: Role;
};

export const DisplayForMinRole: React.FC<Props> = (props) => {
  const { userRole } = useAuth();

  return userRole >= props.requiredMinRole ? <>{props.children}</> : null;
};
