import React, { useMemo } from 'react';
import { Chip } from '@mui/material';
import { PhotoValidationStatus } from '../../../../../../common/types';

export const PhotoValidationStatusChip: React.FC<{ status: PhotoValidationStatus }> = (props) => {
  const validationLabel = useMemo(() => {
    if (props.status === PhotoValidationStatus.Rejected) return 'Rejected';
    if (props.status === PhotoValidationStatus.AwaitingValidation) return 'Validation in progress';
    if (props.status === PhotoValidationStatus.Valid) return 'Valid';
    return '';
  }, []);

  const validationColor = useMemo(() => {
    if (props.status === PhotoValidationStatus.Rejected) return 'error';
    if (props.status === PhotoValidationStatus.AwaitingValidation) return 'secondary';
    return 'primary';
  }, []);

  if (!validationLabel) return null;

  return (
    <Chip
      size="small"
      sx={{ position: 'absolute', right: '0px', top: '10px', opacity: 1, borderRadius: 0 }}
      color={validationColor}
      label={validationLabel}
    />
  );
};
