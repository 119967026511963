import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { cloneDeep } from 'lodash';
import merge from 'lodash.merge';
import { readURLSearchQuery, updateURLSearchQuery } from '../../../common/utils';
import { FilterRecords, UseFiltersType } from '../types/use-filters.type';

type UseFiltersConfigType<TData extends FilterRecords = FilterRecords> = {
  defaultValues: TData;
  syncSearchParams?: boolean;
  key?: string;
};

export const useFilters = <TData extends FilterRecords = FilterRecords>(
  config: UseFiltersConfigType<TData>,
): UseFiltersType<TData> => {
  const queryClient = useQueryClient();

  const { data: persistedSearchParams } = useQuery<TData>(['persistedSearchQuery', config.key], {
    staleTime: 360000,
  });

  const initialState = config.syncSearchParams
    ? Object.assign(cloneDeep(config.defaultValues), readURLSearchQuery())
    : config.defaultValues;

  const [initialData] = useState<TData>(cloneDeep(config.defaultValues));

  const [state, setSate] = useState<TData>(
    cloneDeep(config.key ? Object.assign(initialState, persistedSearchParams) : initialState),
  );

  const setValue = useCallback(
    (values: Partial<TData>) => {
      setSate(cloneDeep(merge(state, values)));
    },
    [state, setSate],
  );

  useEffect(() => {
    if (config.key) {
      queryClient.setQueryData<TData>(['persistedSearchQuery', config.key], state);
    }
    if (config.syncSearchParams) {
      updateURLSearchQuery(state);
    }
  }, [state, config.syncSearchParams]);

  const reset = useCallback(() => {
    setSate(cloneDeep(initialData));
    updateURLSearchQuery(cloneDeep(initialData));
  }, [setSate, initialData]);

  return {
    values: state,
    setValue,
    reset,
  };
};
