import { useQuery, UseQueryResult } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetCategoriesQuery, GetCategoriesQueryVariables } from '../../../common/types';

const queryDocument = loader('../queries/categories.graphql');

export const useCategoriesQuery = (): UseQueryResult<GetCategoriesQuery> => {
  return useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    ['categories'],
    async () => {
      return await graphqlRunner<GetCategoriesQuery, GetCategoriesQueryVariables>(
        queryDocument,
        {},
      );
    },
    {
      keepPreviousData: true,
      staleTime: 50000,
    },
  );
};
