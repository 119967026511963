import { AccordionClasses, AccordionProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const accordionStyles: {
  defaultProps?: Partial<AccordionProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof AccordionClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      '&:before': {
        content: 'none',
      },
      '&.Mui-expanded': {
        // margin: 0,
        '&:last-of-type': {
          marginBottom: '16px',
        },
      },
      '& .MuiAccordionSummary-content': {
        p: {
          color: 'secondary.main',
          fontWeight: 500,
          textTransform: 'uppercase',
        },
      },
      '& .MuiCollapse-root': {
        '& .MuiCollapse-wrapper': {
          padding: '16px 0',
        },
      },
      '& .MuiAccordionSummary-root': {
        borderBottom: '1px solid #F0F2F1',
      },
    },
  },
};
