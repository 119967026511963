import React, { useEffect, useMemo, useState } from 'react';
import { SelectField, SelectFieldOption, SelectFieldProps } from '../../../components/select-field';
import { useCitiesQuery } from '../hooks/use-cites.query';

type Props = Omit<SelectFieldProps, 'options'> & {
  state: string;
};

export const SelectCityField: React.FC<Props> = (props) => {
  const { state, ...restProps } = props;
  const [value, setValue] = useState<string | null | undefined>();

  const { data, isLoading, refetch } = useCitiesQuery(state);

  useEffect(() => {
    setValue('');
    refetch()
      .then(() => {
        setValue(null);
      })
      .catch(() => {
        setValue(null);
      });
  }, [state]);

  const citiesSelectItems = useMemo<SelectFieldOption[]>(
    () =>
      data?.cities.map((city) => ({
        value: city,
        label: city,
      })) || [],
    [data?.cities],
  );

  return (
    <SelectField
      label={'City'}
      value={value ?? ''}
      {...restProps}
      disabled={restProps.disabled || isLoading}
      options={citiesSelectItems}
    />
  );
};
