import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetUserQuery, GetUserQueryVariables, GraphqlErrorsType } from '../../../common/types';
import { useAuth } from '../../auth';

const queryDocument = loader('../queries/user.graphql');

export const useUserQuery = (userId?: string): UseQueryResult<GetUserQuery, GraphqlErrorsType> => {
  const { authenticated } = useAuth();
  const { userId: paramUserId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<GetUserQuery, GraphqlErrorsType>(
    ['user', paramUserId || userId],
    async () => {
      return await graphqlRunner<GetUserQuery, GetUserQueryVariables>(queryDocument, {
        id: paramUserId || userId || '',
      });
    },
    {
      staleTime: 5000,
      enabled: authenticated && !!(userId || paramUserId),
      onError: (err) => {
        enqueueSnackbar(err.errors.map((x) => x.message).join('\n, ') || 'Problem with user', {
          variant: 'error',
        });
      },
    },
  );
};
