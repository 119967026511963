import React from 'react';
import { Card, CardContent } from '@mui/material';
import { GetUsersQueryVariables, SortingDirection } from '../../common/types';
import { FiltersProvider, useFilters } from '../filter-context';
import { UsersFilters } from './components/users-filters';
import { UsersList } from './components/users-list';

export const UsersPage: React.FC = () => {
  const filters = useFilters<GetUsersQueryVariables>({
    key: 'users',
    syncSearchParams: true,
    defaultValues: {
      paging: {
        offset: 0,
        limit: 20,
        sorting: [{ key: 'createdAt', direction: SortingDirection.Desc }],
      },
      filter: {
        createdAt: undefined,
      },
    },
  });

  return (
    <FiltersProvider {...filters}>
      <Card>
        <CardContent>
          <UsersFilters />
          <UsersList />
        </CardContent>
      </Card>
    </FiltersProvider>
  );
};
