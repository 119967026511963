import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType, UpdateWorkerProfileInput } from '../../../common/types';

const queryDocument = loader('../queries/worker-update.graphql');

export const useWorkerMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, GraphqlErrorsType, UpdateWorkerProfileInput>(
    ['user-update'],
    async (body) => {
      await graphqlRunner<void, { workerInput: UpdateWorkerProfileInput }>(queryDocument, {
        workerInput: body,
      });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('User has been updated', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error?.errors?.map((x) => x.message).join('\n, ') || 'Error', {
          variant: 'error',
        });
      },
    },
  );
};
