import React, { useCallback, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { Alert, Box, Button, Collapse, Typography } from '@mui/material';
import { GetJobQueryQuery } from '../../../../../common/types';
import { useJobAddImageMutation } from '../../../hooks/use-job-add-image.mutation';
import { useJobPhotosOrderMutation } from '../../../hooks/use-job-photos-order.mutation';
import { useJobQuery } from '../../../hooks/use-job.query';
import { SortablePhotosContainer } from './photos-form/sortable-photos-container';

type Props = {
  disabled?: boolean;
};

export const PhotosForm: React.FC<Props> = (props) => {
  const { jobId: jobParamId } = useParams();
  const inputFiles = useRef<HTMLInputElement>(null);
  const jobQuery = useJobQuery();
  const queryClient = useQueryClient();
  const jobAddImageMutation = useJobAddImageMutation();
  const jobPhotosOrderMutation = useJobPhotosOrderMutation();

  const handleSortableEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (jobParamId && jobQuery.data?.job?.photos) {
        const keys = ['job', jobParamId];
        const reorderedData = arrayMoveImmutable(jobQuery.data?.job?.photos, oldIndex, newIndex);

        if (JSON.stringify(jobQuery.data?.job?.photos) === JSON.stringify(reorderedData)) return;

        jobPhotosOrderMutation.mutate(
          {
            photoOrders: reorderedData.map((x, index) => ({ order: index, id: x.id })),
          },
          {
            onSettled: () => {
              jobQuery.refetch();
              setTimeout(() => {
                jobPhotosOrderMutation.reset();
              }, 3000);
            },
          },
        );

        queryClient.setQueryData<GetJobQueryQuery>(keys, (oldData) => {
          if (!oldData) return {};
          return {
            ...oldData,
            job: {
              ...(oldData?.job || {}),
              photos: reorderedData,
            },
          };
        });
      }
    },
    [jobQuery.data?.job?.id, jobQuery.data?.job?.photos, queryClient, jobParamId],
  );

  const handleBrowseFiles = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      jobAddImageMutation.mutate(
        {
          jobId: jobQuery.data?.job?.id || '',
          files: event.target.files as FileList,
        },
        {
          onSettled: () => {
            jobQuery.refetch();
            if (inputFiles.current) {
              inputFiles.current.value = '';
            }
            setTimeout(() => {
              jobAddImageMutation.reset();
            }, 5000);
          },
        },
      );
    },
    [jobAddImageMutation.mutate, jobQuery.refetch, jobQuery.data?.job?.id],
  );

  const loading = useMemo(
    () => jobPhotosOrderMutation.isLoading || jobAddImageMutation.isLoading || jobQuery.isLoading,
    [jobAddImageMutation.isLoading, jobQuery.isLoading, jobPhotosOrderMutation.isLoading],
  );

  return (
    <Box sx={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'all' }}>
      {!props.disabled && (
        <Box sx={{ mb: 1 }}>
          <input
            ref={inputFiles}
            type="file"
            onChange={handleBrowseFiles}
            multiple
            hidden
            accept="image/jpeg, image/png, image/jpg, image/jfif"
          />
          <Button onClick={() => inputFiles.current?.click && inputFiles.current?.click()}>
            Browse files
          </Button>
        </Box>
      )}

      <Collapse in={jobPhotosOrderMutation.isSuccess}>
        <Alert severity="success" sx={{ mt: 2, mb: 1 }}>
          Successfully reordered
        </Alert>
      </Collapse>

      <Collapse in={jobPhotosOrderMutation.isError}>
        <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
          {jobPhotosOrderMutation.error?.errors.map((x) => x.message).join('\n,')}
        </Alert>
      </Collapse>

      <Collapse in={jobAddImageMutation.isSuccess}>
        <Alert severity="success" sx={{ mt: 2, mb: 1 }}>
          Successfully <strong>{jobAddImageMutation.data} photo(s) </strong> added
        </Alert>
      </Collapse>

      <Collapse in={!!jobAddImageMutation.error}>
        <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
          {jobAddImageMutation.error?.message || ''}
        </Alert>
      </Collapse>

      {!jobQuery.data?.job?.photos.length && <Typography>No photos added</Typography>}
      <Box sx={{ mt: 1, pointerEvents: props.disabled ? 'none' : 'all' }}>
        <SortablePhotosContainer
          onSortEnd={handleSortableEnd}
          items={jobQuery.data?.job?.photos || []}
          axis="xy"
        />
      </Box>
    </Box>
  );
};
