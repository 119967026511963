import React from 'react';
import { Card, CardContent } from '@mui/material';
import { GetJobsQueryQueryVariables, SortingDirection } from '../../common/types';
import { FiltersProvider, useFilters } from '../filter-context';
import { JobsFilters } from './components/list/jobs-filters';
import { JobsList } from './components/list/jobs-list';

export const JobsListPage: React.FC = () => {
  const filters = useFilters<GetJobsQueryQueryVariables>({
    key: 'jobs',
    syncSearchParams: true,
    defaultValues: {
      filter: {
        createdAtFilter: undefined,
      },
      paging: {
        limit: 15,
        offset: 0,
        sorting: [{ key: 'createdAt', direction: SortingDirection.Desc }],
      },
    },
  });

  return (
    <FiltersProvider {...filters}>
      <Card>
        <CardContent>
          <JobsFilters />
          <JobsList />
        </CardContent>
      </Card>
    </FiltersProvider>
  );
};
