import React from 'react';
import { Card, CardContent, Link, Stack, Typography } from '@mui/material';
import { routes } from '../../routes';
import { LogoIcon } from '../icons';

type Props = {
  title?: string;
  href?: string;
};

export const NotFound: React.FC<Props> = (props) => {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Card sx={{ minWidth: '250px', p: 3 }}>
        <CardContent>
          <Stack gap="20px" alignItems="center">
            <Typography variant="h3" fontWeight={700}>
              404
            </Typography>
            <Typography variant="h5">{props.title}</Typography>
            <LogoIcon sx={{ fontSize: '70px', height: 'unset' }} />
            <Link sx={{ mt: 4 }} href={props.href || routes.users.list.link()}>
              Go back
            </Link>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
