import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import { useAuth } from '../../auth';

export const RedirectConditional: React.FC = () => {
  const { authenticated, initialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialized) return;

    if (authenticated) {
      navigate(routes.users.list.link());
      return;
    }
    navigate(routes.login);
  }, [authenticated, initialized]);

  return null;
};
