import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { GetUsersQueryVariables } from '../../../common/types';
import { DateRange } from '../../../components/date-range';
import { useFiltersContext } from '../../filter-context';

type UsersFiltersProps = GetUsersQueryVariables;

export const UsersFilters = () => {
  const {
    setValue: setFilterContextValue,
    values,
    reset,
  } = useFiltersContext<GetUsersQueryVariables>();

  const submit = useCallback(
    (body: UsersFiltersProps) => {
      setFilterContextValue({
        filter: body.filter,
        paging: { ...body.paging, offset: 0 },
      });
    },
    [setFilterContextValue],
  );

  const {
    control,
    handleSubmit,
    setValue: setFormValue,
  } = useForm<UsersFiltersProps>({
    defaultValues: {
      filter: {
        name: null,
        emailAddress: null,
        createdAt: {
          dateFrom: null,
          dateTo: null,
        },
      },
    },
  });

  useEffect(() => {
    setFormValue('filter.name', values.filter?.name);
    setFormValue('filter.emailAddress', values.filter?.emailAddress);
    setFormValue('filter.createdAt', values.filter?.createdAt);
  }, [values]);

  return (
    <Stack spacing={2}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item md={3}>
            <Controller
              name={'filter.name'}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  label="Name"
                  size="small"
                  name={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={3}>
            <Controller
              name={'filter.emailAddress'}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  label="Email adress"
                  size="small"
                  name={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name={'filter.createdAt'}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DateRange
                    fullWidth
                    size="small"
                    labelFrom={'Created from'}
                    labelTo={'Created to'}
                    value={
                      field.value
                        ? {
                            from: field.value.dateFrom ? new Date(field.value.dateFrom) : undefined,
                            to: field.value.dateTo ? new Date(field.value.dateTo) : undefined,
                          }
                        : undefined
                    }
                    onChange={({ fromDate, toDate }) => {
                      field.onChange({
                        dateFrom: fromDate.toISOString(),
                        dateTo: toDate.toISOString(),
                      });
                    }}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={9}></Grid>
          <Grid item md={3}>
            <Stack direction="row" spacing={2}>
              <Button type={'submit'} fullWidth size="small">
                Search
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                size="small"
                type="button"
                onClick={() => reset()}
              >
                Clear
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};
