import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { LocationType } from '../../../../common/types';
import { SelectLocationTypeField } from '../../../shared';
import { useJobQuery } from '../../hooks/use-job.query';
import { JobDetailsFormProps } from '../../job-details.page';

type Props = {
  control: Control<JobDetailsFormProps>;
  disabled?: boolean;
};

export const LocationForm: React.FC<Props> = (props) => {
  const jobQuery = useJobQuery();

  const locationType = useWatch({
    name: 'locationType',
    control: props.control,
  }) as LocationType;

  return (
    <Box>
      <Typography variant={'h6'} sx={{ mb: 2 }}>
        Location
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 3 }}>
        <Grid item md={4}>
          <Controller
            name="locationType"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'Location Type is required',
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <SelectLocationTypeField
                  fullWidth
                  disabled={jobQuery.isLoading || props.disabled}
                  label={'Location type'}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {locationType !== LocationType.CanBeDoneRemotely && (
        <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 3 }}>
          <Grid item md={4}>
            <Controller
              name="address.street"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'Street'}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="address.city"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'City'}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="location.zipCode"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'ZIP code'}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="address.state"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'State'}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="address.apartmentNumber"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'Apartment Number'}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="address.buildingNumber"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'Building Number'}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      )}

      {locationType !== LocationType.CanBeDoneRemotely && (
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item md={4}>
            <Controller
              name="location.coordinates.latitude"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'Latitude'}
                    value={field.value || 0}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="location.coordinates.longitude"
              control={props.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    disabled={jobQuery.isLoading || props.disabled}
                    label={'Longitude'}
                    value={field.value || 0}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
