import { useMutation } from 'react-query';
import { API } from 'aws-amplify';
import { isValidImageFileSize, isValidImageFileType } from '../../../common/utils/image-type.utils';

export const useJobAddImageMutation = () => {
  return useMutation<number, Error, { jobId: string; files: FileList }>(
    ['job-add-image'],
    async (body) => {
      const filesAsArray = Array.from(body.files);

      for (const file of filesAsArray) {
        if (!isValidImageFileType(file)) {
          throw new Error('Invalid file type');
        }

        if (!isValidImageFileSize(file)) {
          throw new Error('Invalid file size');
        }

        const form = new FormData();

        form.append('jobId', body.jobId);
        form.append('file', file);

        try {
          await API.post('API_URL', '/jobs/photos', {
            body: form,
          });
        } catch (err) {
          throw new Error('Limit of photos has been reached');
        }
      }

      return filesAsArray?.length || 0;
    },
  );
};
