import React from 'react';
import { SvgIcon } from '@mui/material';
import { SxProps } from '@mui/system';

export const LogoIcon: React.FC<{ sx?: SxProps }> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width={341}
      height={95}
      viewBox="0 0 341 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M116.626 34.1678C116.626 20.9027 124.076 12.8632 137.778 12.8632C151.348 12.8632 159.197 20.7687 159.197 33.8999C159.33 47.433 151.348 55.3385 137.778 55.3385C124.076 55.3385 116.626 47.433 116.626 34.1678ZM100.662 34.5698C100.662 56.5444 114.099 69.5416 136.714 69.5416C149.219 69.5416 153.21 65.5219 157.334 61.1001H159.33V68.2017H173.963V1.20592H159.33V7.23554H157.334C153.609 4.15374 148.288 0 135.517 0C114.099 0 100.662 12.0592 100.662 34.5698Z"
        fill="#232930"
      />
      <path
        d="M220.754 52.6587C207.052 52.6587 199.602 44.7532 199.602 32.8279C199.602 20.9027 207.052 12.8632 220.754 12.8632C234.323 12.8632 242.039 20.7687 242.172 32.5599C242.305 44.7532 234.323 52.6587 220.754 52.6587ZM184.702 69.5416C187.363 84.9506 198.404 95 219.557 95C243.503 95 256.939 82.9408 256.939 58.6883V55.0705V1.20592H242.305V7.23554H240.31C236.319 3.88575 231.53 0 218.758 0C197.074 0 183.638 12.0592 183.638 32.8279C183.638 53.8646 197.074 66.8618 219.158 66.8618C232.195 66.8618 236.053 62.3061 240.31 58.4203H242.305C242.305 72.2214 235.654 82.0028 220.887 82.0028C216.497 82.0028 202.395 81.8688 199.336 69.5416H184.702Z"
        fill="#232930"
      />
      <path
        d="M265.222 34.8378C265.222 56.9464 279.989 69.5416 304.999 69.5416C324.289 69.5416 337.06 61.1001 339.321 46.897H322.027C320.032 52.6587 313.646 55.3385 303.935 55.3385C289.434 55.3385 281.053 49.4429 281.053 34.4358C281.053 19.8307 289.168 12.8632 302.737 12.8632C316.174 12.8632 323.756 19.5628 323.756 28.6742L275.732 28.2722V40.3315H339.72C342.381 13.1312 325.885 0 302.604 0C279.057 0 265.222 13.1312 265.222 34.8378Z"
        fill="#232930"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12933 28.9844L14.6954 68.0236H38.7455L47.9646 25.9593L57.451 68.0236H82.8372L97.5344 1.04224H81.5011L71.0794 49.9386H69.6097L57.451 1.04224H38.7455L26.5868 49.9386H25.1171L20.651 28.9844H6.12933Z"
        fill="#232930"
      />
      <g filter="url(#filter0_bi_160_4916)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8291 20.4282H4.25373L0 1.04224H14.6972L18.8291 20.4282Z"
          fill="#00D192"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_160_4916"
          x="-139.778"
          y="-138.735"
          width="298.384"
          height="298.941"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="69.8888" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_160_4916" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_160_4916"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.17676" />
          <feGaussianBlur stdDeviation="1.58838" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_160_4916" />
        </filter>
      </defs>
    </SvgIcon>
  );
};
