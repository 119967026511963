import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Card, Link } from '@mui/material';
import { routes } from '../../../../routes';
import { useJobQuery } from '../../hooks/use-job.query';

export const BreadcrumbsDetails: React.FC = () => {
  const { jobId } = useParams();
  const jobQuery = useJobQuery(jobId);

  return (
    <Card sx={{ mb: 3, p: 2 }}>
      <Breadcrumbs aria-label="breadcrumb" color="secondary">
        <Link underline="hover" color="inherit" href={routes.jobs.list.link(undefined)}>
          Jobs & Offers
        </Link>
        <Link
          underline="none"
          color="inherit"
          href={routes.jobs.details.link({ jobId: jobId || '' })}
        >
          {jobQuery.data?.job?.title || 'Job'}
        </Link>
      </Breadcrumbs>
    </Card>
  );
};
