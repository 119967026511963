import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { Role } from '../../common/types';
import { FormGroupSection } from '../../components/form-group-section';
import { routes } from '../../routes';
import { AdminBredcrumbs } from './components/details/admin-bredcrumbs';
import { RoleSelectField } from './components/details/role-select-field';
import { useCreateAdministratorMutation } from './hooks/use-create-administrator.mutation';

type FormProps = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  role: Role;
};

export const AdministratorCreatePage = () => {
  const { handleSubmit, control } = useForm<FormProps>();
  const mutation = useCreateAdministratorMutation();
  const navigate = useNavigate();

  const submit = async (body: FormProps) => {
    await mutation.mutate(
      {
        input: {
          emailAddress: body.emailAddress,
          role: body.role,
          givenName: body.firstName,
          familyName: body.lastName,
          phoneNumber: body.phoneNumber,
        },
      },
      {
        onSuccess: () => {
          navigate(routes.admins.list.link());
        },
      },
    );
  };

  return (
    <Box>
      <AdminBredcrumbs defaultName="Create" />

      <FormGroupSection defaultExpanded label="General data">
        <form onSubmit={handleSubmit(submit)}>
          <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 3 }}>
            <Grid item md={4}>
              <Controller
                name={'emailAddress'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Email should be an email',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={mutation.isLoading}
                    label="Email address"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name={'phoneNumber'}
                control={control}
                rules={{
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
                    message: 'Invalid phone number format',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={mutation.isLoading}
                    label="Phone number"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={4}>
              <Controller
                name={'firstName'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'First Name is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={mutation.isLoading}
                    label="First name"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name={'lastName'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Last Name is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={mutation.isLoading}
                    label="Last name"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} />

            <Grid item md={4}>
              <Controller
                name={'role'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Role is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <RoleSelectField
                    disabled={mutation.isLoading}
                    label="Role"
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Stack direction="row" gap={2}>
              <Button
                disabled={mutation.isLoading}
                variant="outlined"
                color="secondary"
                fullWidth
                href={routes.admins.list.link()}
              >
                Cancel
              </Button>
              <Button type={'submit'} fullWidth disabled={mutation.isLoading}>
                Save changes
              </Button>
            </Stack>
          </Grid>
        </form>
      </FormGroupSection>
    </Box>
  );
};
