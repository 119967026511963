import React from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Avatar, Box, Button, Chip, Grid, Stack, TextField } from '@mui/material';
import { routes } from '../../../../routes';
import { useJobManagerQuery } from '../../hooks/use-job-manager.query';
import { useJobQuery } from '../../hooks/use-job.query';

export const ManagerDetails: React.FC = () => {
  const jobQuery = useJobQuery();
  const userQuery = useJobManagerQuery(jobQuery.data?.job?.manager.id || '');

  return (
    <Box>
      <Grid container columnSpacing={4}>
        <Grid item sx={{ p: 1 }}>
          <Stack spacing={2}>
            <Avatar
              variant={'square'}
              src={userQuery.data?.user?.userProfilePhoto.url}
              sx={{ width: 200, height: 200 }}
            >
              {userQuery.data?.user?.givenName.slice(0, 1)}
              {userQuery.data?.user?.familyName.slice(0, 1)}
            </Avatar>

            <Box sx={{ width: 200, textAlign: 'center' }}>
              {userQuery.data?.user?.verification.isVerified ? (
                <Chip
                  color={'primary'}
                  icon={<CheckCircleOutlineIcon color={'primary'} />}
                  label="User is verified"
                  variant="outlined"
                  sx={{ mt: 1 }}
                />
              ) : (
                <Chip
                  color={'secondary'}
                  icon={<CancelOutlinedIcon color={'secondary'} />}
                  label="User is not verified"
                  variant="outlined"
                  sx={{ mt: 1 }}
                />
              )}
            </Box>

            <Button
              size="small"
              href={routes.users.preview.link({ userId: jobQuery.data?.job?.manager.id || '' })}
            >
              Details
            </Button>
          </Stack>
        </Grid>
        <Grid item md={4} sx={{ pt: 1 }}>
          <Stack spacing={2}>
            <TextField
              disabled
              variant="filled"
              label="Given name"
              value={userQuery.data?.user?.givenName || ''}
            />
            <TextField
              disabled
              variant="filled"
              label="Family name"
              value={userQuery.data?.user?.familyName || ''}
            />
            <TextField
              variant="filled"
              label="Email"
              InputProps={{
                endAdornment: userQuery.data?.user?.emailVerified ? (
                  <CheckCircleOutlineIcon color={'primary'} sx={{ mr: 1 }} />
                ) : (
                  <CancelOutlinedIcon color={'secondary'} sx={{ mr: 1 }} />
                ),
              }}
              disabled
              value={userQuery.data?.user?.email || ''}
            />
            <TextField
              variant="filled"
              label="Phone Number"
              disabled
              value={userQuery.data?.user?.phoneNumber || ''}
              InputProps={{
                endAdornment: userQuery.data?.user?.phoneNumberVerified ? (
                  <CheckCircleOutlineIcon color={'primary'} sx={{ mr: 1 }} />
                ) : (
                  <CancelOutlinedIcon color={'secondary'} sx={{ mr: 1 }} />
                ),
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
