export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  Uuid: string;
};

export type AddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']>;
  buildingNumber: Scalars['String'];
  city: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
};

export type AddressResponse = {
  __typename?: 'AddressResponse';
  apartmentNumber?: Maybe<Scalars['String']>;
  buildingNumber: Scalars['String'];
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
};

export type AdministratorResponse = {
  __typename?: 'AdministratorResponse';
  emailAddress?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type CategoryPhotoResponse = {
  __typename?: 'CategoryPhotoResponse';
  fileId: Scalars['Uuid'];
  photoUrl?: Maybe<Scalars['String']>;
  relativePhotoUrl: Scalars['String'];
};

export type CategoryResponse = {
  __typename?: 'CategoryResponse';
  defaultPhotoThumbnailUrl?: Maybe<Scalars['String']>;
  defaultPhotoUrl?: Maybe<Scalars['String']>;
  hasSubcategories: Scalars['Boolean'];
  id: Scalars['Uuid'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  parentCategory?: Maybe<CategoryResponse>;
  parentCategoryId?: Maybe<Scalars['Uuid']>;
  photos?: Maybe<Array<Maybe<CategoryPhotoResponse>>>;
  subcategories?: Maybe<Array<Maybe<CategoryResponse>>>;
};

export type CertificateInput = {
  name: Scalars['String'];
  yearAwarded: Scalars['Int'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesResponse = {
  __typename?: 'CoordinatesResponse';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateAdministratorInput = {
  emailAddress: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  phoneNumber: Scalars['String'];
  role: Role;
};

export type DateTimeOffsetRangeFilterInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};

export type IdentityVerificationResponse = {
  __typename?: 'IdentityVerificationResponse';
  beingVerifiedAt?: Maybe<Scalars['DateTime']>;
  failureDetailedReason?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  isVerified: Scalars['Boolean'];
  scanReferenceId?: Maybe<Scalars['String']>;
};

export type JobApplicantResponse = {
  __typename?: 'JobApplicantResponse';
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  jobApplicationId: Scalars['Uuid'];
  profilePhotoValidationStatus: PhotoValidationStatus;
  profileThumbnailUrl: Scalars['String'];
  statistics: StatisticsResponse;
  status: JobApplicationStatus;
  wage: Scalars['Decimal'];
  workerId: Scalars['Uuid'];
};

export enum JobApplicationStatus {
  AssignedToJob = 'ASSIGNED_TO_JOB',
  Awaiting = 'AWAITING',
  CancelledByWorker = 'CANCELLED_BY_WORKER',
  ClosedByManager = 'CLOSED_BY_MANAGER',
  None = 'NONE',
  RejectedByManager = 'REJECTED_BY_MANAGER'
}

export type JobListFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars['Uuid']>>;
  city?: InputMaybe<Scalars['String']>;
  createdAtFilter?: InputMaybe<DateTimeOffsetRangeFilterInput>;
  createdBy?: InputMaybe<Scalars['Uuid']>;
  filter?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationType>;
  rateFilter?: InputMaybe<RateFilterInput>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobStatus>;
};

export type JobResponse = {
  __typename?: 'JobResponse';
  additionalConsiderations?: Maybe<Scalars['String']>;
  address?: Maybe<AddressResponse>;
  applicants: PagedResultsOfJobApplicantResponse;
  category?: Maybe<CategoryResponse>;
  categoryId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Uuid'];
  location?: Maybe<LocationResponse>;
  locationType: LocationType;
  manager: ManagerResponse;
  managerId: Scalars['Uuid'];
  maxRate: RateResponse;
  photos: Array<PhotoResponse>;
  requiredTools?: Maybe<Scalars['String']>;
  status: JobStatus;
  title: Scalars['String'];
  toBeDoneDate: Scalars['DateTime'];
  toBeDoneType: ToBeDoneType;
  updatedAt: Scalars['DateTime'];
};


export type JobResponseApplicantsArgs = {
  pagingParamsInput: OffsetPagingParamsInputOfSortingParamsInput;
};

export enum JobStatus {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  None = 'NONE',
  Posted = 'POSTED',
  ReadyToBePosted = 'READY_TO_BE_POSTED'
}

export type LocationInput = {
  coordinates?: InputMaybe<CoordinatesInput>;
  zipCode: Scalars['String'];
};

export type LocationResponse = {
  __typename?: 'LocationResponse';
  coordinates?: Maybe<CoordinatesResponse>;
  zipCode: Scalars['String'];
};

export enum LocationType {
  CanBeDoneRemotely = 'CAN_BE_DONE_REMOTELY',
  None = 'NONE',
  OnSite = 'ON_SITE'
}

export type ManagerResponse = {
  __typename?: 'ManagerResponse';
  averageRating: Scalars['Decimal'];
  id: Scalars['Uuid'];
  ratingsCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  blockAdministrator: Scalars['Boolean'];
  blockUser: Scalars['Boolean'];
  changeJobPhotosOrder: Scalars['Boolean'];
  createAdministrator: Scalars['Boolean'];
  deleteAdministrator: Scalars['Boolean'];
  deleteJob: Scalars['Boolean'];
  deleteJobPhotos: Scalars['Boolean'];
  deletePastJobPhotos: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  resetAdministratorPassword: Scalars['Boolean'];
  updateAdministrator: Scalars['Boolean'];
  updateJob: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  updateWorkerProfile: Scalars['Boolean'];
};


export type MutationBlockAdministratorArgs = {
  id: Scalars['Uuid'];
  value: Scalars['Boolean'];
};


export type MutationBlockUserArgs = {
  userId: Scalars['Uuid'];
};


export type MutationChangeJobPhotosOrderArgs = {
  input: Array<PhotoOrderInput>;
  jobId: Scalars['Uuid'];
};


export type MutationCreateAdministratorArgs = {
  input: CreateAdministratorInput;
};


export type MutationDeleteAdministratorArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteJobPhotosArgs = {
  jobId: Scalars['Uuid'];
  photosIds: Array<Scalars['Uuid']>;
};


export type MutationDeletePastJobPhotosArgs = {
  photosIds: Array<Scalars['Uuid']>;
  userId: Scalars['Uuid'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['Uuid'];
};


export type MutationResetAdministratorPasswordArgs = {
  id: Scalars['Uuid'];
};


export type MutationUpdateAdministratorArgs = {
  input: UpdateAdministratorInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWorkerProfileArgs = {
  input: UpdateWorkerProfileInput;
};

export type OffsetPagingParamsInputOfSortingParamsInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sorting?: InputMaybe<Array<SortingParamsInput>>;
};

export type PagedResultsOfJobApplicantResponse = {
  __typename?: 'PagedResultsOfJobApplicantResponse';
  paging: PagingParams;
  results?: Maybe<Array<JobApplicantResponse>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export type PagedResultsOfJobResponse = {
  __typename?: 'PagedResultsOfJobResponse';
  paging: PagingParams;
  results?: Maybe<Array<JobResponse>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export type PagedResultsOfUserReportResponse = {
  __typename?: 'PagedResultsOfUserReportResponse';
  paging: PagingParams;
  results?: Maybe<Array<UserReportResponse>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export type PagedResultsOfUserResponse = {
  __typename?: 'PagedResultsOfUserResponse';
  paging: PagingParams;
  results?: Maybe<Array<UserResponse>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export type PagingParams = {
  __typename?: 'PagingParams';
  sorting?: Maybe<Array<SortingParams>>;
};

export type PhotoOrderInput = {
  id: Scalars['Uuid'];
  order: Scalars['Int'];
};

export type PhotoResponse = {
  __typename?: 'PhotoResponse';
  id: Scalars['Uuid'];
  order: Scalars['Int'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  validationStatus: PhotoValidationStatus;
};

export enum PhotoValidationStatus {
  AwaitingValidation = 'AWAITING_VALIDATION',
  Rejected = 'REJECTED',
  Valid = 'VALID'
}

export type ProfilePhotoResponse = {
  __typename?: 'ProfilePhotoResponse';
  id: Scalars['Uuid'];
  thumbnailUrl: Scalars['String'];
  url: Scalars['String'];
  validationStatus: PhotoValidationStatus;
};

export type Query = {
  __typename?: 'Query';
  administrator?: Maybe<AdministratorResponse>;
  administrators: Array<AdministratorResponse>;
  categories: Array<CategoryResponse>;
  category?: Maybe<CategoryResponse>;
  cities: Array<Scalars['String']>;
  job?: Maybe<JobResponse>;
  jobs: PagedResultsOfJobResponse;
  states: Array<Scalars['String']>;
  user?: Maybe<UserResponse>;
  users: PagedResultsOfUserResponse;
};


export type QueryAdministratorArgs = {
  id: Scalars['Uuid'];
};


export type QueryCategoryArgs = {
  id: Scalars['Uuid'];
};


export type QueryCitiesArgs = {
  state: Scalars['String'];
};


export type QueryJobArgs = {
  id: Scalars['Uuid'];
};


export type QueryJobsArgs = {
  offsetPagingParams: OffsetPagingParamsInputOfSortingParamsInput;
  searchInput?: InputMaybe<JobListFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['Uuid'];
};


export type QueryUsersArgs = {
  offsetPagingParams: OffsetPagingParamsInputOfSortingParamsInput;
  searchInput?: InputMaybe<UserListFilterInput>;
};

export type RateFilterInput = {
  maxRate?: InputMaybe<Scalars['Decimal']>;
  minRate?: InputMaybe<Scalars['Decimal']>;
  rateType: RateType;
};

export type RateInput = {
  currency: Scalars['String'];
  type: RateType;
  wage: Scalars['Decimal'];
};

export type RateResponse = {
  __typename?: 'RateResponse';
  currency: Scalars['String'];
  type: RateType;
  wage: Scalars['Decimal'];
};

export enum RateType {
  PerHour = 'PER_HOUR',
  PerJob = 'PER_JOB'
}

export enum Role {
  Administrator = 'ADMINISTRATOR',
  Moderator = 'MODERATOR',
  None = 'NONE',
  Viewer = 'VIEWER'
}

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortingParams = {
  __typename?: 'SortingParams';
  direction: SortingDirection;
  key: Scalars['String'];
};

export type SortingParamsInput = {
  direction: SortingDirection;
  key: Scalars['String'];
};

export type StatisticsResponse = {
  __typename?: 'StatisticsResponse';
  averageRating: Scalars['Decimal'];
  ratingsCount: Scalars['Int'];
};

export enum TargetDeviceType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export enum ToBeDoneType {
  CustomSchedule = 'CUSTOM_SCHEDULE',
  None = 'NONE',
  Tomorrow = 'TOMORROW',
  WithinThreeDays = 'WITHIN_THREE_DAYS'
}

export type UpdateAdministratorInput = {
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Uuid'];
  phoneNumber: Scalars['String'];
  role: Role;
};

export type UpdateJobInput = {
  additionalConsiderations?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressInput>;
  categoryId: Scalars['Uuid'];
  description: Scalars['String'];
  id: Scalars['Uuid'];
  location?: InputMaybe<LocationInput>;
  locationType: LocationType;
  maxRate: RateInput;
  photoIdFromDefaultPhotosGallery?: InputMaybe<Scalars['Uuid']>;
  requiredTools?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  toBeDoneDate: Scalars['DateTime'];
  toBeDoneType: ToBeDoneType;
};

export type UpdateUserInput = {
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Uuid'];
  location: LocationInput;
  phoneNumber: Scalars['String'];
  preferredLanguageName: Scalars['String'];
  timeZone: Scalars['String'];
};

export type UpdateWorkerProfileInput = {
  biography?: InputMaybe<Scalars['String']>;
  categoriesIds: Array<Scalars['Uuid']>;
  certificates: Array<CertificateInput>;
  id: Scalars['Uuid'];
  rate: RateInput;
  website?: InputMaybe<Scalars['String']>;
  willingToTravel: Scalars['Boolean'];
};

export type UserDeviceResolver = {
  __typename?: 'UserDeviceResolver';
  userDevice?: Maybe<TargetDeviceType>;
};

export type UserListFilterInput = {
  createdAt?: InputMaybe<DateTimeOffsetRangeFilterInput>;
  emailAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum UserReportCause {
  InappropriateContent = 'INAPPROPRIATE_CONTENT',
  NotARealPerson = 'NOT_A_REAL_PERSON',
  SuspicionOfFraud = 'SUSPICION_OF_FRAUD'
}

export type UserReportResponse = {
  __typename?: 'UserReportResponse';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  reportedUserId: Scalars['Uuid'];
  user: UserResponse;
  userReportCause: UserReportCause;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  createdAt: Scalars['DateTime'];
  device?: Maybe<TargetDeviceType>;
  email: Scalars['String'];
  emailCanBeModified: Scalars['Boolean'];
  emailVerificationRequired: Scalars['Boolean'];
  emailVerified: Scalars['Boolean'];
  enabled?: Maybe<Scalars['Boolean']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Uuid'];
  isActive: Scalars['Boolean'];
  jobs: PagedResultsOfJobResponse;
  location: LocationResponse;
  phoneNumber: Scalars['String'];
  phoneNumberVerificationRequired: Scalars['Boolean'];
  phoneNumberVerified: Scalars['Boolean'];
  preferredLanguageName: Scalars['String'];
  receivedReports: PagedResultsOfUserReportResponse;
  sentReports: PagedResultsOfUserReportResponse;
  timeZone: Scalars['String'];
  userProfilePhoto: ProfilePhotoResponse;
  verification: IdentityVerificationResponse;
  workerPublicProfile?: Maybe<WorkerPublicProfileResponse>;
};


export type UserResponseJobsArgs = {
  filter?: InputMaybe<JobListFilterInput>;
  input: OffsetPagingParamsInputOfSortingParamsInput;
};


export type UserResponseReceivedReportsArgs = {
  pagingParams: OffsetPagingParamsInputOfSortingParamsInput;
};


export type UserResponseSentReportsArgs = {
  pagingParams: OffsetPagingParamsInputOfSortingParamsInput;
};

export type WorkerCertificateResponse = {
  __typename?: 'WorkerCertificateResponse';
  name: Scalars['String'];
  yearAwarded: Scalars['Int'];
};

export type WorkerPublicProfileResponse = {
  __typename?: 'WorkerPublicProfileResponse';
  biography: Scalars['String'];
  categoriesIds: Array<Scalars['Uuid']>;
  certificates?: Maybe<Array<WorkerCertificateResponse>>;
  id: Scalars['Uuid'];
  isVerified: Scalars['Boolean'];
  pastJobPhotos: Array<PhotoResponse>;
  rate: RateResponse;
  statistics: StatisticsResponse;
  website?: Maybe<Scalars['String']>;
  willingToTravel: Scalars['Boolean'];
};

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'CategoryResponse', id: string, name: string, hasSubcategories: boolean, order?: number | null | undefined, parentCategoryId?: string | null | undefined, subcategories?: Array<{ __typename?: 'CategoryResponse', id: string, name: string, hasSubcategories: boolean, order?: number | null | undefined } | null | undefined> | null | undefined }> };

export type CreateAdministratorMutationVariables = Exact<{
  input: CreateAdministratorInput;
}>;


export type CreateAdministratorMutation = { __typename?: 'Mutation', createAdministrator: boolean };

export type GetAdministratorQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetAdministratorQuery = { __typename?: 'Query', administrator?: { __typename?: 'AdministratorResponse', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, emailAddress?: string | null | undefined, phoneNumber?: string | null | undefined, role?: Role | null | undefined, enabled?: boolean | null | undefined } | null | undefined };

export type GetAdministratorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdministratorsQuery = { __typename?: 'Query', administrators: Array<{ __typename?: 'AdministratorResponse', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, emailAddress?: string | null | undefined, phoneNumber?: string | null | undefined, role?: Role | null | undefined, enabled?: boolean | null | undefined }> };

export type BlockUnblockAdministratorMutationVariables = Exact<{
  id: Scalars['Uuid'];
  blocked: Scalars['Boolean'];
}>;


export type BlockUnblockAdministratorMutation = { __typename?: 'Mutation', blockAdministrator: boolean };

export type DeleteAdministratorMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type DeleteAdministratorMutation = { __typename?: 'Mutation', deleteAdministrator: boolean };

export type ResetAdministratorPasswordMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type ResetAdministratorPasswordMutation = { __typename?: 'Mutation', resetAdministratorPassword: boolean };

export type UpdateAdministratorMutationVariables = Exact<{
  input: UpdateAdministratorInput;
}>;


export type UpdateAdministratorMutation = { __typename?: 'Mutation', updateAdministrator: boolean };

export type GetJobApplicantsQueryVariables = Exact<{
  id: Scalars['Uuid'];
  paging: OffsetPagingParamsInputOfSortingParamsInput;
}>;


export type GetJobApplicantsQuery = { __typename?: 'Query', job?: { __typename?: 'JobResponse', id: string, applicants: { __typename?: 'PagedResultsOfJobApplicantResponse', totalCount?: number | null | undefined, results?: Array<{ __typename?: 'JobApplicantResponse', workerId: string, jobApplicationId: string, familyName: string, givenName: string, profileThumbnailUrl: string, wage: number, status: JobApplicationStatus, statistics: { __typename?: 'StatisticsResponse', averageRating: number, ratingsCount: number } }> | null | undefined } } | null | undefined };

export type DeleteJobMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type DeleteJobMutation = { __typename?: 'Mutation', deleteJob: boolean };

export type DeleteJobPhotosMutationVariables = Exact<{
  id: Scalars['Uuid'];
  jobPhotos: Array<Scalars['Uuid']> | Scalars['Uuid'];
}>;


export type DeleteJobPhotosMutation = { __typename?: 'Mutation', deleteJobPhotos: boolean };

export type GetJobManagerQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetJobManagerQuery = { __typename?: 'Query', user?: { __typename?: 'UserResponse', id: string, email: string, emailVerified: boolean, emailVerificationRequired: boolean, phoneNumber: string, phoneNumberVerified: boolean, phoneNumberVerificationRequired: boolean, givenName: string, familyName: string, timeZone: string, isActive: boolean, userProfilePhoto: { __typename?: 'ProfilePhotoResponse', id: string, url: string, thumbnailUrl: string, validationStatus: PhotoValidationStatus }, location: { __typename?: 'LocationResponse', zipCode: string, coordinates?: { __typename?: 'CoordinatesResponse', latitude: number, longitude: number } | null | undefined }, verification: { __typename?: 'IdentityVerificationResponse', scanReferenceId?: string | null | undefined, isVerified: boolean, beingVerifiedAt?: string | null | undefined, failureReason?: string | null | undefined, failureDetailedReason?: string | null | undefined } } | null | undefined };

export type ChangeJobPhotosOrderMutationVariables = Exact<{
  jobId: Scalars['Uuid'];
  photoOrders: Array<PhotoOrderInput> | PhotoOrderInput;
}>;


export type ChangeJobPhotosOrderMutation = { __typename?: 'Mutation', changeJobPhotosOrder: boolean };

export type UpdateJobMutationVariables = Exact<{
  input: UpdateJobInput;
}>;


export type UpdateJobMutation = { __typename?: 'Mutation', updateJob: boolean };

export type GetJobQueryQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetJobQueryQuery = { __typename?: 'Query', job?: { __typename?: 'JobResponse', id: string, title: string, status: JobStatus, managerId: string, description: string, createdAt: string, updatedAt: string, toBeDoneType: ToBeDoneType, toBeDoneDate: string, locationType: LocationType, requiredTools?: string | null | undefined, additionalConsiderations?: string | null | undefined, address?: { __typename?: 'AddressResponse', apartmentNumber?: string | null | undefined, buildingNumber: string, city: string, state?: string | null | undefined, street: string } | null | undefined, location?: { __typename?: 'LocationResponse', zipCode: string, coordinates?: { __typename?: 'CoordinatesResponse', latitude: number, longitude: number } | null | undefined } | null | undefined, maxRate: { __typename?: 'RateResponse', currency: string, wage: number, type: RateType }, photos: Array<{ __typename?: 'PhotoResponse', id: string, url: string, thumbnailUrl?: string | null | undefined, validationStatus: PhotoValidationStatus, order: number }>, category?: { __typename?: 'CategoryResponse', id: string, name: string } | null | undefined, manager: { __typename?: 'ManagerResponse', id: string, averageRating: number, ratingsCount: number } } | null | undefined };

export type GetJobsQueryQueryVariables = Exact<{
  paging: OffsetPagingParamsInputOfSortingParamsInput;
  filter?: InputMaybe<JobListFilterInput>;
}>;


export type GetJobsQueryQuery = { __typename?: 'Query', jobs: { __typename?: 'PagedResultsOfJobResponse', totalCount?: number | null | undefined, results?: Array<{ __typename?: 'JobResponse', id: string, status: JobStatus, title: string, description: string, createdAt: string, toBeDoneDate: string, toBeDoneType: ToBeDoneType, updatedAt: string, photos: Array<{ __typename?: 'PhotoResponse', id: string, url: string, thumbnailUrl?: string | null | undefined, validationStatus: PhotoValidationStatus }>, category?: { __typename?: 'CategoryResponse', id: string, name: string } | null | undefined, manager: { __typename?: 'ManagerResponse', id: string, averageRating: number, ratingsCount: number } }> | null | undefined, paging: { __typename?: 'PagingParams', sorting?: Array<{ __typename?: 'SortingParams', key: string, direction: SortingDirection }> | null | undefined } } };

export type GetCitiesQueryVariables = Exact<{
  state: Scalars['String'];
}>;


export type GetCitiesQuery = { __typename?: 'Query', cities: Array<string> };

export type GetStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatesQuery = { __typename?: 'Query', states: Array<string> };

export type BlockUserMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type BlockUserMutation = { __typename?: 'Mutation', blockUser: boolean };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type GetUserJobsQueryVariables = Exact<{
  id: Scalars['Uuid'];
  jobsPaging: OffsetPagingParamsInputOfSortingParamsInput;
}>;


export type GetUserJobsQuery = { __typename?: 'Query', user?: { __typename?: 'UserResponse', jobs: { __typename?: 'PagedResultsOfJobResponse', totalCount?: number | null | undefined, results?: Array<{ __typename?: 'JobResponse', id: string, status: JobStatus, title: string, category?: { __typename?: 'CategoryResponse', name: string } | null | undefined }> | null | undefined } } | null | undefined };

export type GetUserReportsQueryVariables = Exact<{
  id: Scalars['Uuid'];
  reportsPaging: OffsetPagingParamsInputOfSortingParamsInput;
}>;


export type GetUserReportsQuery = { __typename?: 'Query', user?: { __typename?: 'UserResponse', sentReports: { __typename?: 'PagedResultsOfUserReportResponse', totalCount?: number | null | undefined, results?: Array<{ __typename?: 'UserReportResponse', id: string, reportedUserId: string, userReportCause: UserReportCause, description?: string | null | undefined, user: { __typename?: 'UserResponse', id: string, email: string, givenName: string, familyName: string, userProfilePhoto: { __typename?: 'ProfilePhotoResponse', thumbnailUrl: string } } }> | null | undefined } } | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: boolean };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'UserResponse', id: string, email: string, emailVerified: boolean, emailVerificationRequired: boolean, phoneNumber: string, phoneNumberVerified: boolean, phoneNumberVerificationRequired: boolean, givenName: string, familyName: string, timeZone: string, isActive: boolean, enabled?: boolean | null | undefined, userProfilePhoto: { __typename?: 'ProfilePhotoResponse', id: string, url: string, thumbnailUrl: string, validationStatus: PhotoValidationStatus }, location: { __typename?: 'LocationResponse', zipCode: string, coordinates?: { __typename?: 'CoordinatesResponse', latitude: number, longitude: number } | null | undefined }, verification: { __typename?: 'IdentityVerificationResponse', scanReferenceId?: string | null | undefined, isVerified: boolean, beingVerifiedAt?: string | null | undefined, failureReason?: string | null | undefined, failureDetailedReason?: string | null | undefined }, workerPublicProfile?: { __typename?: 'WorkerPublicProfileResponse', id: string, categoriesIds: Array<string>, biography: string, website?: string | null | undefined, isVerified: boolean, willingToTravel: boolean, certificates?: Array<{ __typename?: 'WorkerCertificateResponse', name: string, yearAwarded: number }> | null | undefined, rate: { __typename?: 'RateResponse', currency: string, wage: number, type: RateType }, statistics: { __typename?: 'StatisticsResponse', averageRating: number, ratingsCount: number }, pastJobPhotos: Array<{ __typename?: 'PhotoResponse', id: string, url: string, thumbnailUrl?: string | null | undefined, validationStatus: PhotoValidationStatus, order: number }> } | null | undefined } | null | undefined };

export type GetUsersQueryVariables = Exact<{
  paging: OffsetPagingParamsInputOfSortingParamsInput;
  filter?: InputMaybe<UserListFilterInput>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: { __typename?: 'PagedResultsOfUserResponse', totalCount?: number | null | undefined, results?: Array<{ __typename?: 'UserResponse', id: string, email: string, emailVerified: boolean, emailVerificationRequired: boolean, phoneNumber: string, phoneNumberVerified: boolean, phoneNumberVerificationRequired: boolean, givenName: string, familyName: string, timeZone: string, isActive: boolean, createdAt: string, userProfilePhoto: { __typename?: 'ProfilePhotoResponse', id: string, url: string, thumbnailUrl: string, validationStatus: PhotoValidationStatus }, location: { __typename?: 'LocationResponse', zipCode: string, coordinates?: { __typename?: 'CoordinatesResponse', latitude: number, longitude: number } | null | undefined }, verification: { __typename?: 'IdentityVerificationResponse', scanReferenceId?: string | null | undefined, isVerified: boolean, beingVerifiedAt?: string | null | undefined, failureReason?: string | null | undefined, failureDetailedReason?: string | null | undefined } }> | null | undefined } };

export type DeletePastJobPhotosMutationVariables = Exact<{
  id: Scalars['Uuid'];
  photosIds: Array<Scalars['Uuid']> | Scalars['Uuid'];
}>;


export type DeletePastJobPhotosMutation = { __typename?: 'Mutation', deletePastJobPhotos: boolean };

export type UpdateWorkerMutationVariables = Exact<{
  workerInput: UpdateWorkerProfileInput;
}>;


export type UpdateWorkerMutation = { __typename?: 'Mutation', updateWorkerProfile: boolean };
