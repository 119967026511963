import { useQuery } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetUsersQuery, GetUsersQueryVariables, GraphqlErrorsType } from '../../../common/types';
import { useFiltersContext } from '../../filter-context';

const queryDocument = loader('../queries/users.graphql');

export const useUsersListQuery = () => {
  const { values } = useFiltersContext<GetUsersQueryVariables>();

  return useQuery<GetUsersQuery, GraphqlErrorsType>(
    ['user', values.paging, values.filter],
    async () => {
      return await graphqlRunner<GetUsersQuery, GetUsersQueryVariables>(queryDocument, values);
    },
    {
      keepPreviousData: true,
      staleTime: 20000,
    },
  );
};
