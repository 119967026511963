import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DisplayForMinRole, Role, useAuth } from '../../auth';
import { useUserMutation } from '../hooks/use-user.mutation';
import { useUserQuery } from '../hooks/use-user.query';
import { UserDetailsFormProps } from '../user.page';
import { DangerZone } from './user-danger-zone';
import { UserHeader } from './user-header';
import { UserJobs } from './user-jobs';
import { UserProfile } from './user-profile';
import { UserReports } from './user-reports';
import { UserVerification } from './user-verification';
import { WorkerForm } from './worker-form';

export const UserForm: React.FC = () => {
  const { userId } = useParams();
  const { userRole } = useAuth();

  const userMutation = useUserMutation();
  const userQuery = useUserQuery(userId as string);

  const { handleSubmit, formState, control, reset } = useForm<UserDetailsFormProps>({
    defaultValues: {
      id: userQuery.data?.user?.id || '',
      givenName: userQuery.data?.user?.givenName || '',
      familyName: userQuery.data?.user?.familyName || '',
      email: userQuery.data?.user?.email || '',
      phoneNumber: userQuery.data?.user?.phoneNumber || '',
      location: {
        zipCode: userQuery.data?.user?.location?.zipCode || '',
        coordinates: {
          latitude: userQuery.data?.user?.location?.coordinates?.latitude || 0,
          longitude: userQuery.data?.user?.location?.coordinates?.longitude || 0,
        },
      },
      preferredLanguageName: 'English',
      timeZone: userQuery.data?.user?.timeZone || '',
    },
  });

  const submit = useCallback(
    (body: UserDetailsFormProps) => {
      const data = {
        ...body,
        location: {
          zipCode: body.location?.zipCode || '',
          coordinates: {
            latitude: Number(body.location?.coordinates?.latitude),
            longitude: Number(body.location?.coordinates?.longitude),
          },
        },
      };

      userMutation.mutate(data, {
        onSuccess: () => {
          userQuery.refetch();
          reset(data);
        },
      });
    },
    [userMutation.mutate, userQuery.refetch, reset],
  );

  return (
    <Box>
      <form onSubmit={handleSubmit(submit)}>
        <UserHeader />
        <UserProfile
          formState={formState}
          reset={reset}
          control={control}
          disabled={userMutation.isLoading || userRole < Role.moderator}
        />
        <UserVerification />
        <UserJobs />
        <UserReports />
      </form>
      {userQuery.data?.user?.workerPublicProfile && <WorkerForm />}
      <DisplayForMinRole requiredMinRole={Role.moderator}>
        <DangerZone />
      </DisplayForMinRole>
    </Box>
  );
};
