import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { Role } from '../../../../common/types';
import { FormGroupSection } from '../../../../components/form-group-section';
import { Role as AuthRole, useAuth } from '../../../auth';
import { useAdministratorQuery } from '../../hooks/use-administrator.query';
import { useUpdateAdministratorMutation } from '../../hooks/use-update-administrator.mutation';
import { RoleSelectField } from './role-select-field';

type UserFormProps = {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  role: Role;
};

export const GeneralForm: React.FC = () => {
  const { adminId } = useParams();
  const { data, refetch } = useAdministratorQuery(adminId || '');
  const mutation = useUpdateAdministratorMutation();
  const { userId, userRole } = useAuth();

  const { control, handleSubmit, reset } = useForm<UserFormProps>({
    defaultValues: {
      id: data?.administrator?.id || '',
      role: data?.administrator?.role || Role.Viewer,
      emailAddress: data?.administrator?.emailAddress || '',
      firstName: data?.administrator?.firstName || '',
      lastName: data?.administrator?.lastName || '',
      phoneNumber: data?.administrator?.phoneNumber || '',
    },
  });

  const submit = async (body: UserFormProps) => {
    await mutation.mutate(
      {
        input: {
          id: body.id,
          role: body.role,
          givenName: body.firstName,
          familyName: body.lastName,
          phoneNumber: body.phoneNumber,
        },
      },
      {
        onSettled: () => {
          refetch();
        },
      },
    );
    reset(body);
  };

  const blockEdit = data?.administrator?.id !== userId && userRole !== AuthRole.administrator;

  return (
    <FormGroupSection defaultExpanded label={'General data'}>
      <Stack maxWidth={700} direction={'row'} flexWrap={'wrap'}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 3 }} columns={8}>
            <Grid item md={4}>
              <Controller
                name={'emailAddress'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Email should be an email',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled
                    label="Email address"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name={'phoneNumber'}
                control={control}
                rules={{
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
                    message: 'Invalid phone number format',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={blockEdit || mutation.isLoading}
                    label="Phone number"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name={'firstName'}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={blockEdit || mutation.isLoading}
                    label="First name"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name={'lastName'}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={blockEdit || mutation.isLoading}
                    label="Last name"
                    fullWidth
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name={'role'}
                control={control}
                render={({ field, fieldState }) => (
                  <RoleSelectField
                    disabled={blockEdit || data?.administrator?.id === userId || mutation.isLoading}
                    label="Role"
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Stack direction="row" gap={2}>
              <Button
                disabled={blockEdit || mutation.isLoading}
                variant="outlined"
                color="secondary"
                fullWidth
                type="button"
                onClick={() => reset()}
              >
                Reset
              </Button>
              <Button type={'submit'} fullWidth disabled={blockEdit || mutation.isLoading}>
                Save changes
              </Button>
            </Stack>
          </Grid>
        </form>
      </Stack>
    </FormGroupSection>
  );
};
