import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import {
  GetUserReportsQuery,
  GetUserReportsQueryVariables,
  GraphqlErrorsType,
  OffsetPagingParamsInputOfSortingParamsInput,
} from '../../../common/types';
import { useAuth } from '../../auth';

const queryDocument = loader('../queries/user-reports.graphql');

export const useUserReportsQuery = (filters: {
  paging: OffsetPagingParamsInputOfSortingParamsInput;
}): UseQueryResult<GetUserReportsQuery, GraphqlErrorsType> => {
  const { authenticated } = useAuth();
  const { userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<GetUserReportsQuery, GraphqlErrorsType>(
    ['user-reports', userId, filters],
    async () => {
      return await graphqlRunner<GetUserReportsQuery, GetUserReportsQueryVariables>(queryDocument, {
        id: userId || '',
        reportsPaging: filters.paging,
      });
    },
    {
      staleTime: 5000,
      keepPreviousData: true,
      enabled: authenticated,
      onError: (err) => {
        enqueueSnackbar(err.errors.map((x) => x.message).join('\n, ') || 'Problem with user', {
          variant: 'error',
        });
      },
    },
  );
};
