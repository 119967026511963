import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { ChangeJobPhotosOrderMutationVariables, GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/job-photos-reorder.graphql');

export const useJobPhotosOrderMutation = (jobId?: string) => {
  const { jobId: jobParamId } = useParams();

  return useMutation<void, GraphqlErrorsType, Omit<ChangeJobPhotosOrderMutationVariables, 'jobId'>>(
    ['job-update'],
    async (body) => {
      await graphqlRunner<void, ChangeJobPhotosOrderMutationVariables>(queryDocument, {
        jobId: jobId || jobParamId || '',
        photoOrders: body.photoOrders,
      });
    },
  );
};
