import { SnackbarClasses, SnackbarProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const snackbarStyles: {
  defaultProps?: Partial<SnackbarProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof SnackbarClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    root: {},
  },
};
