import React from 'react';
import { Stack } from '@mui/material';
import { LogoIcon } from '../../../components/icons';

export const SplashScreen: React.FC = () => {
  return (
    <Stack sx={{ height: '100vh' }} justifyContent="center" alignItems="center">
      <LogoIcon sx={{ fontSize: '140px' }} />
    </Stack>
  );
};
