import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { CreateAdministratorInput, GraphqlErrorsType } from '../../../common/types';
import { routes } from '../../../routes';

const queryDocument = loader('../queries/administrator-create.mutation.graphql');

export const useCreateAdministratorMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useMutation<void, GraphqlErrorsType, { input: CreateAdministratorInput }>(
    ['create-administrator'],
    async (body) => {
      await graphqlRunner<void, { input: CreateAdministratorInput }>(queryDocument, body);
    },
    {
      onSuccess: () => {
        enqueueSnackbar(`Administrator successfully created`, { variant: 'success' });
        navigate(routes.admins.list.link());
      },
      onError: (error) => {
        enqueueSnackbar(error?.errors?.map((x) => x.message).join('\n, ') || 'Error', {
          variant: 'error',
        });
      },
    },
  );
};
