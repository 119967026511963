import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RateType, UpdateWorkerProfileInput } from '../../../common/types';
import { Role, useAuth } from '../../auth';
import { useUserQuery } from '../hooks/use-user.query';
import { useWorkerMutation } from '../hooks/use-worker.mutation';
import { WorkerProfile } from './worker-profile';

export const WorkerForm: React.FC = () => {
  const { userId } = useParams();
  const { userRole } = useAuth();
  const userQuery = useUserQuery(userId as string);
  const workerMutation = useWorkerMutation();

  const { handleSubmit, formState, control, reset } = useForm<UpdateWorkerProfileInput>({
    defaultValues: {
      id: userQuery.data?.user?.workerPublicProfile?.id || '',
      biography: userQuery.data?.user?.workerPublicProfile?.biography || '',
      website: userQuery.data?.user?.workerPublicProfile?.website || '',
      rate: {
        currency: userQuery.data?.user?.workerPublicProfile?.rate?.currency || '',
        type: RateType.PerHour,
        wage: userQuery.data?.user?.workerPublicProfile?.rate?.wage || 0,
      },
      willingToTravel: userQuery.data?.user?.workerPublicProfile?.willingToTravel || false,
      categoriesIds: userQuery.data?.user?.workerPublicProfile?.categoriesIds || [],
      certificates: userQuery.data?.user?.workerPublicProfile?.certificates || [],
    },
  });

  const submit = useCallback(
    (body: UpdateWorkerProfileInput) => {
      const data = {
        ...body,
        rate: {
          ...body.rate,
          wage: parseFloat(body.rate.wage.toString()),
        },
      };

      workerMutation.mutate(data, {
        onSuccess: () => {
          userQuery.refetch();
          reset({ ...body });
        },
      });
    },
    [workerMutation.mutate, userQuery.refetch, reset],
  );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <WorkerProfile
        formState={formState}
        reset={reset}
        control={control}
        disabled={workerMutation.isLoading || userRole < Role.moderator}
      />
    </form>
  );
};
