import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { AlertDialog } from '../../../components/alert-dialog';
import { FormGroupSection } from '../../../components/form-group-section';
import { routes } from '../../../routes';
import { useUserBlockMutation } from '../hooks/use-user-block';
import { useUserQuery } from '../hooks/use-user.query';
import { useUsersDeleteMutation } from '../hooks/use-users-delete.mutation';

export const DangerZone: React.FC = () => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const { userId } = useParams();
  const userQuery = useUserQuery(userId);
  const userDeleteMutation = useUsersDeleteMutation();
  const userBlockMutation = useUserBlockMutation();
  const navigate = useNavigate();

  const handleBlockUnblockUser = useCallback(() => {
    userBlockMutation.mutate(
      { userId: userId as string },
      {
        onSuccess: () => {
          userQuery.refetch();
        },
      },
    );
  }, [userId, setOpenDeleteConfirmation]);

  const handleDeleteUser = useCallback(() => {
    userDeleteMutation.mutate(
      { usersId: [userId as string] },
      {
        onSuccess: () => {
          setOpenDeleteConfirmation(false);
          userQuery.remove();
          navigate(routes.users.list.link());
        },
        onError: () => {
          setOpenDeleteConfirmation(false);
          navigate(routes.users.list.link());
        },
      },
    );
  }, [userId, setOpenDeleteConfirmation]);

  return (
    <>
      <FormGroupSection label={<Typography sx={{ color: 'error.main' }}>Danger Zone</Typography>}>
        <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 3 }} columns={8}>
          <Grid item>
            <Button
              aria-label="disable"
              color={userQuery.data?.user?.enabled ? 'error' : 'success'}
              variant={'outlined'}
              onClick={handleBlockUnblockUser}
            >
              {userQuery.data?.user?.enabled ? 'Disable' : 'Enable'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              aria-label="delete"
              color="error"
              onClick={() => setOpenDeleteConfirmation(true)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </FormGroupSection>
      <AlertDialog
        title="Deleting User"
        description={`Are you sure you want to delete the user?`}
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onAccept={handleDeleteUser}
      />
    </>
  );
};
