import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/user-delete.graphql');

export const useUsersDeleteMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, GraphqlErrorsType, { usersId: string[] }>(
    ['user-delete'],
    async (body) => {
      const promisses: Promise<void>[] = [];

      body.usersId.forEach((userId) => {
        promisses.push(
          graphqlRunner<void, { id: string }>(queryDocument, {
            id: userId,
          }),
        );
      });

      await Promise.all(promisses);
    },
    {
      onSuccess: () => {
        enqueueSnackbar('User has been deleted', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Problem with deleting user', { variant: 'error' });
      },
    },
  );
};
