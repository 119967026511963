import React, { useMemo } from 'react';
import {
  SelectField,
  SelectFieldOption,
  SelectFieldProps,
  SelectMultipleField,
  SelectMultipleFieldProps,
} from '../../../components/select-field';
import { useCategoriesQuery } from '../hooks/use-categories.query';

type Props = (Omit<SelectMultipleFieldProps, 'options'> | Omit<SelectFieldProps, 'options'>) & {
  multiple?: boolean;
};

export const SelectCategoryField: React.FC<Props> = (props) => {
  const categoriesQuery = useCategoriesQuery();

  const categoriesSelectItems = useMemo<SelectFieldOption[]>(
    () =>
      categoriesQuery.data?.categories
        .filter((x) => x.subcategories && x.subcategories?.length > 0)
        .map((category) => ({
          value: category.id,
          label: category.name,
          groupedOptions: category.subcategories?.map((subcategory) => ({
            value: subcategory?.id || '',
            label: subcategory?.name || '',
          })),
        })) || [],
    [categoriesQuery.data?.categories],
  );

  if (!props.multiple) {
    const selectFieldProps = props as Omit<SelectFieldProps, 'options'>;
    return (
      <SelectField
        {...selectFieldProps}
        disabled={props.disabled || categoriesQuery.isLoading}
        options={categoriesSelectItems}
      />
    );
  }

  const selectMultipleFieldProps = props as Omit<SelectMultipleFieldProps, 'options'>;
  return (
    <SelectMultipleField
      {...selectMultipleFieldProps}
      disabled={props.disabled || categoriesQuery.isLoading}
      options={categoriesSelectItems}
      sx={props.sx}
    />
  );
};
