import React, { useMemo } from 'react';
import { Role } from '../../../../common/types';
import { SelectField, SelectFieldOption } from '../../../../components/select-field';

type Props = {
  value?: string;
  onChange: (value?: string) => void;
  onBlur?: (value?: string) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const RoleSelectField: React.FC<Props> = (props) => {
  const options = useMemo<SelectFieldOption[]>(() => {
    return [
      { label: 'No role assigned', value: Role.None, disabled: true },
      { label: 'Viewer', value: Role.Viewer },
      { label: 'Moderator', value: Role.Moderator },
      { label: 'Administrator', value: Role.Administrator },
    ];
  }, []);
  return <SelectField fullWidth {...props} options={options} />;
};
