import { useMutation } from 'react-query';
import to from 'await-to-js';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { isValidImageFileSize, isValidImageFileType } from '../../../common/utils/image-type.utils';

export const useUserPhotoMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, Error, { userId: string; files: FileList }>(
    ['user-photo'],
    async (body) => {
      if (!isValidImageFileType(body.files[0])) {
        throw new Error('Invalid file type');
      }
      if (!isValidImageFileSize(body.files[0])) {
        throw new Error('Invalid file size');
      }

      const form = new FormData();
      form.append('userId', body.userId);
      form.append('file', body.files[0]);

      const [err] = await to(
        API.post('API_URL', '/profile-photo', {
          body: form,
        }),
      );
      if (err) {
        throw new Error('Error');
      }
      return;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('User photo has been updated', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Problem with updating new user photo', { variant: 'error' });
      },
    },
  );
};
