import React, { useCallback, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type Props = Omit<TextFieldProps, 'onBlur' | 'onChange' | 'value'> & {
  onChange: (value: string) => void;
  onBlur: () => void;
  value: number | string;
  precision?: number;
};

const parseFloatValue = (value?: string | number, precision?: number) => {
  const newValue = parseFloat(String(value || '').replaceAll(',', '.'));
  if (isNaN(newValue)) {
    return '';
  }

  return precision ? newValue.toFixed(precision) : newValue.toString();
};

export const NumberField: React.FC<Props> = (props) => {
  const { onChange, onBlur, value, precision, ...rest } = props;
  const [localValue, setLocalValue] = useState<string>(parseFloatValue(value || ''));
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized || typeof value !== 'number') return;
    setLocalValue(parseFloatValue(value, precision));
    setInitialized(true);
  }, [value, initialized, setInitialized, precision]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalValue(event.target.value || '');
    },
    [setLocalValue, onChange],
  );

  const handleBlue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsedValue = parseFloatValue(event.target.value || '', precision);
      setLocalValue(parsedValue);
      onChange && onChange(parsedValue);
      onBlur && onBlur();
    },
    [localValue, setLocalValue, precision],
  );

  return <TextField {...rest} value={localValue} onChange={handleChange} onBlur={handleBlue} />;
};
