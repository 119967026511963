import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetAdministratorQuery, GetAdministratorQueryVariables } from '../../../common/types';

const queryDocument = loader('../queries/administrator.graphql');

export const useAdministratorQuery = (id?: string): UseQueryResult<GetAdministratorQuery> => {
  const { adminId } = useParams();
  return useQuery<GetAdministratorQuery, GetAdministratorQueryVariables>(
    ['administrator', id || adminId],
    async () => {
      return await graphqlRunner<GetAdministratorQuery, GetAdministratorQueryVariables>(
        queryDocument,
        { id: id || adminId || '' },
      );
    },
    {
      keepPreviousData: true,
      staleTime: 20000,
    },
  );
};
