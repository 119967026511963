import React, { useCallback, useEffect, useRef, useState } from 'react';
import { endOfDay, format, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { PickersDay, PickersDayProps } from '@mui/lab';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {
  Box,
  FormHelperText,
  Popover,
  PopoverProps,
  Stack,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  value?: {
    from?: Date;
    to?: Date;
  };
  labelFrom?: string;
  labelTo?: string;
  error?: boolean;
  helperText?: string;
  onChange?: ({ fromDate, toDate }: { fromDate: Date; toDate: Date }) => void;
  onBlur?: () => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  gap?: string | number;
  TextFiledFrom?: TextFieldProps;
  TextFiledTo?: TextFieldProps;
  Popover?: Omit<PopoverProps, 'open'>;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  formatDate?: string;
};

export const DateRange: React.FC<Props> = (props) => {
  const anchorTarget = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [currentSelect, setCurrentSelect] = useState<'from' | 'to' | undefined>(undefined);
  const [fromDate, setFromDate] = useState<Date | undefined>(props.value?.from);
  const [toDate, setToDate] = useState<Date | undefined>(props.value?.to);

  useEffect(() => {
    if (initialized || !props.value?.from || !props.value?.to) return;
    setFromDate(props.value?.from);
    setToDate(props.value?.to);
    setInitialized(true);
  }, [props.value?.from, props.value?.to, setInitialized]);

  useEffect(() => {
    if (!fromDate || !toDate || !props.onChange) return;
    props.onChange({
      fromDate: fromDate,
      toDate: toDate,
    });
  }, [fromDate, toDate]);

  useEffect(() => {
    if (!!fromDate && !!toDate && !props.value?.from && !props.value?.to && !anchorEl) {
      setFromDate(undefined);
      setToDate(undefined);
    }
  }, [props.value?.from, props.value?.to, fromDate, toDate, setFromDate, setToDate]);

  const handleChange = (date: Date | null) => {
    if (currentSelect === 'from' && date) {
      setFromDate(startOfDay(date));
      if (date && toDate && isAfter(date, toDate)) {
        setToDate(endOfDay(date));
      }
      if (date && !toDate) {
        setToDate(endOfDay(date));
      }
      setCurrentSelect('to');
    }

    if (currentSelect === 'to' && date) {
      if (!fromDate) {
        setFromDate(startOfDay(date));
      }
      setToDate(endOfDay(date));
      setCurrentSelect('from');
    }
  };

  const renderDay = useCallback(
    (day: Date, selectedDates: (Date | null)[], pickersDayProps: PickersDayProps<Date>) => {
      const date = new Date(day);

      const isInRange = fromDate && toDate && isAfter(date, fromDate) && isBefore(date, toDate);
      const isFirstDay = fromDate && isSameDay(date, fromDate);
      const isLastDay = toDate && isSameDay(date, toDate);

      const isInRageWithFromToDates = isInRange || isFirstDay || isLastDay;

      return (
        <PickersDay
          {...pickersDayProps}
          sx={{
            backgroundColor: isInRageWithFromToDates
              ? isFirstDay || isLastDay
                ? 'primary.dark'
                : 'primary.light'
              : 'unset',
            color: isInRageWithFromToDates ? 'white' : 'unset',
            '&.Mui-selected': {
              color: 'common.white',
            },
            '&.MuiPickersDay-today': {
              border: 'none',
              fontWeight: 'bold',
            },
          }}
        />
      );
    },
    [fromDate, toDate],
  );

  return (
    <Box sx={props.sx} ref={anchorTarget}>
      <Stack direction="row" gap={props.gap || '10px'}>
        <TextField
          size={props.size}
          fullWidth={props.fullWidth}
          onClick={() => {
            if (props.disabled) return;
            setCurrentSelect('from');
            if (anchorTarget.current) {
              setAnchorEl(anchorTarget.current);
            }
          }}
          onChange={() => null}
          focused={currentSelect === 'from'}
          disabled={props.disabled}
          label={props.labelFrom || 'From'}
          value={fromDate ? format(fromDate, props.formatDate || 'yyyy-MM-dd') : ''}
          InputProps={{
            sx: { cursor: 'pointer', borderRadius: 0 },
            endAdornment: <DateRangeIcon />,
          }}
          {...(props.TextFiledFrom || {})}
        />
        <TextField
          size={props.size}
          fullWidth={props.fullWidth}
          onClick={() => {
            if (props.disabled) return;
            setCurrentSelect('to');
            if (anchorTarget.current) {
              setAnchorEl(anchorTarget.current);
            }
          }}
          onChange={() => null}
          focused={currentSelect === 'to'}
          disabled={props.disabled}
          label={props.labelTo || 'To'}
          value={toDate ? format(toDate, props.formatDate || 'yyyy-MM-dd') : ''}
          InputProps={{
            sx: { cursor: 'pointer', borderRadius: 0 },
            endAdornment: <DateRangeIcon />,
          }}
          {...(props.TextFiledTo || {})}
        />
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setCurrentSelect(undefined);
          }}
          {...(props.Popover || {})}
          open={!!anchorEl}
        >
          <StaticDatePicker
            allowSameDateSelection
            displayStaticWrapperAs={'desktop'}
            minDate={currentSelect === 'to' ? fromDate : undefined}
            value={fromDate}
            renderInput={(params: TextFieldProps) => <TextField {...params} />}
            onChange={handleChange}
            renderDay={renderDay}
          />
        </Popover>
      </Stack>
      {props.helperText && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
    </Box>
  );
};
