import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';
import { Box, Drawer, Stack } from '@mui/material';
import { ExploreIcon, LogoIcon } from '../../../components/icons';
import { ListLinkItem } from '../../../components/list';
import { routes } from '../../../routes';

type Props = {
  drawerWidth: number;
  appBarHeight: number;
};

const SideBar: React.FC<Props> = (props) => {
  const location = useLocation();

  const isActive = useCallback(
    (rootRoute: string): boolean => {
      return location.pathname?.includes(rootRoute);
    },
    [location.pathname],
  );

  return (
    <Drawer
      sx={{
        width: props.drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: props.drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box height={props.appBarHeight}>
        <Stack sx={{ height: '100%', px: 2 }} justifyContent="center" alignItems="center">
          <LogoIcon sx={{ fontSize: '100px', height: 'unset' }} />
        </Stack>
      </Box>
      <Stack
        spacing={0}
        sx={{
          pt: 0,
          '& .Mui-selected': {
            position: 'relative',
            '&:after': {
              content: '""',
              position: 'absolute',
              height: '100%',
              width: '6px',
              left: 0,
              top: 0,
              backgroundColor: 'primary.main',
            },
          },
        }}
      >
        <ListLinkItem
          href={routes.users.list.link()}
          isSelected={isActive(routes.users.list.path)}
          icon={<PeopleIcon sx={{ color: 'black' }} />}
          text={'Users'}
        />
        <ListLinkItem
          href={routes.admins.list.link()}
          isSelected={isActive(routes.admins.list.path)}
          icon={<AdminPanelSettingsIcon sx={{ color: 'black' }} />}
          text={'Administrators'}
        />

        <ListLinkItem
          href={routes.jobs.list.link()}
          isSelected={isActive(routes.jobs.list.path)}
          text={'Jobs'}
          icon={<ExploreIcon sx={{ fontSize: '1.5rem' }} />}
        />
      </Stack>
    </Drawer>
  );
};

export default SideBar;
