import { RoutePathLink } from './utils';

export const routes = {
  root: '/',
  login: '/login',
  confirm: '/confirm',
  forgotPassword: '/forgot-password',
  users: {
    root: '/users',
    list: new RoutePathLink('/users'),
    preview: new RoutePathLink<{ userId: string }>('/users/:userId'),
  },
  admins: {
    root: '/administrators',
    list: new RoutePathLink('/administrators'),
    create: new RoutePathLink('/administrators/create'),
    preview: new RoutePathLink<{ adminId: string }>('/administrators/:adminId'),
  },
  jobs: {
    root: '/jobs',
    list: new RoutePathLink('/jobs'),
    details: new RoutePathLink<{ jobId: string }>('/jobs/:jobId'),
  },
};
