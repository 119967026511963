import React, { RefObject, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDropDown, ExitToApp, Person } from '@mui/icons-material';
import {
  AppBar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { routes } from '../../../routes';
import { ChipRole, useAuth } from '../../auth';

type Props = {
  drawerWidth: number;
  appBarRef: RefObject<HTMLDivElement>;
};

const NavBar: React.FC<Props> = (props) => {
  const { userInfo, signOut } = useAuth();
  const navigate = useNavigate();

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorMenu), [anchorMenu]);

  const handleMenuClose = useCallback(() => {
    setAnchorMenu(null);
  }, [setAnchorMenu]);

  const handleMenuOpen = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      setAnchorMenu(evt.currentTarget);
    },
    [setAnchorMenu],
  );

  const handleSignOut = useCallback(() => {
    handleMenuClose();
    signOut({
      onSuccess: () => {
        window.location.pathname = '/';
      },
    });
  }, [handleMenuClose, signOut, navigate]);

  const handleProfileClick = useCallback(() => {
    handleMenuClose();
    navigate(routes.admins.preview.link({ adminId: userInfo?.id ?? '' }));
  }, [handleMenuClose, navigate, userInfo?.id]);

  return (
    <>
      <AppBar
        ref={props.appBarRef}
        sx={{ width: `calc(100% - ${props.drawerWidth}px)`, ml: `${props.drawerWidth}px` }}
      >
        <Toolbar style={{ justifyContent: 'end' }}>
          <Stack direction="row" spacing={5} alignItems="center">
            <ChipRole />
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              onClick={handleMenuOpen}
              sx={{ cursor: 'pointer' }}
            >
              <Typography>Hi {`${userInfo?.firstName || userInfo?.emailAddress}`}</Typography>
              <IconButton size={'small'} sx={{ minWidth: 'fit-content' }}>
                <ArrowDropDown />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Menu
        open={open}
        onClose={handleMenuClose}
        anchorEl={anchorMenu}
        sx={{ mt: 5 }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <Person sx={{ color: 'black' }} />
          </ListItemIcon>
          <Typography>My profile</Typography>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToApp sx={{ color: 'black' }} />
          </ListItemIcon>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default NavBar;
