import { flatten, unflatten } from 'flat';

export const updateURLSearchQuery = <TData>(state: TData): void => {
  const parsedState = getClearedUndefinedValues<TData, string>(state);

  if (!state) return;
  const searchQueryParams = new URLSearchParams(parsedState);
  history.replaceState(null, '', `${window.location.pathname}?${searchQueryParams.toString()}`);
};

export const readURLSearchQuery = <TData>(): TData => {
  const parsedEntries = getClearedUndefinedValues();

  const flattenObject = JSON.parse(
    JSON.stringify(Object.fromEntries(parsedEntries)),
    (key, value) => {
      if (typeof value === 'object') return value;
      if (value === '') return '';
      if (value === 'true') return true;
      if (value === 'false') return false;
      if (value === 'null') return null;
      if (!isNaN(value)) return parseInt(value, 10);
      return value;
    },
  );
  return unflatten<unknown, TData>(flattenObject);
};

const getClearedUndefinedValues = <TData, TResultValue = unknown>(
  state?: TData,
): [string, TResultValue][] => {
  const searchQueryParams = state
    ? Object.entries(flatten(state))
    : new URLSearchParams(window.location.search.slice(1)).entries();

  return [...Array.from(searchQueryParams)]
    .filter(([, value]) => {
      return value !== 'undefined';
    })
    .filter(([, value]) => {
      return value !== 'null';
    })
    .filter(([, value]) => {
      return value !== null;
    })
    .filter(([, value]) => {
      return typeof value !== 'undefined';
    }) as [string, TResultValue][];
};
