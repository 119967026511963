import React, { useCallback, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Role } from '../../common/types';
import { AlertDialog } from '../../components/alert-dialog';
import { routes } from '../../routes';
import { DisplayForMinRole, useAuth } from '../auth';
import { Role as AuthRole } from '../auth/context';
import { useTableSelect } from '../shared';
import { AdministratorRoleChip } from './components/administrator-role-chip';
import { CurrentAdministratorChip } from './components/current-administrator-chip';
import { useAdministratorsQuery } from './hooks/use-administrators.query';
import { useBlockUnblockAdministratorMutation } from './hooks/use-block-unblock-administrator.mutation';
import { useDeleteAdministratorMutation } from './hooks/use-delete-administrator.mutation';

export const AdministratorsPage = () => {
  const administratorsQuery = useAdministratorsQuery();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const { userInfo } = useAuth();
  const deleteAdministratorMutation = useDeleteAdministratorMutation();

  const blockUnblockAdministratorMutation = useBlockUnblockAdministratorMutation();

  const tableSelect = useTableSelect<string>({
    items:
      administratorsQuery?.data?.administrators
        ?.map((x) => x.id)
        .filter((x) => x !== userInfo?.id) || [],
  });

  const handleDeleteAdministrators = useCallback(async () => {
    setOpenDeleteConfirmation(false);
    await deleteAdministratorMutation.mutate(
      {
        ids: tableSelect.selected,
      },
      {
        onSettled: () => {
          tableSelect.clearAll();
          administratorsQuery.refetch();
        },
      },
    );
  }, [tableSelect.selected]);

  const handleChange = useCallback(async (id: string, evt: React.ChangeEvent<HTMLInputElement>) => {
    await blockUnblockAdministratorMutation.mutate(
      { id: id, value: evt.target.checked },
      {
        onSettled: () => {
          administratorsQuery.refetch();
        },
      },
    );
  }, []);

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <DisplayForMinRole requiredMinRole={AuthRole.administrator}>
            <Box justifyContent={'flex-end'} display={'flex'}>
              <Button size={'small'} sx={{ minWidth: '180px' }} href={routes.admins.create.path}>
                Add new user
              </Button>
            </Box>
          </DisplayForMinRole>
          <Collapse in={tableSelect.count > 0}>
            <Card sx={{ my: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ ml: 2, mr: 1 }}
              >
                <Typography>
                  Selected <strong>{tableSelect.count}</strong> row(s)
                </Typography>
                <IconButton
                  aria-label="delete"
                  sx={{ color: 'error.main' }}
                  onClick={() => setOpenDeleteConfirmation(true)}
                >
                  <DeleteIcon />
                </IconButton>
                <AlertDialog
                  title="Deleting Administrator(s)"
                  description={`Are you sure you want to delete ${tableSelect.count} administrator(s)?`}
                  open={openDeleteConfirmation}
                  onClose={() => setOpenDeleteConfirmation(false)}
                  onAccept={handleDeleteAdministrators}
                />
              </Stack>
            </Card>
          </Collapse>
          <Table>
            <TableHead>
              <TableRow>
                <DisplayForMinRole requiredMinRole={AuthRole.administrator}>
                  <TableCell padding={'checkbox'}>
                    <Checkbox
                      onClick={() => tableSelect.selectAll()}
                      checked={tableSelect.hasAllSelected}
                    />
                  </TableCell>
                </DisplayForMinRole>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Email address</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Disabled</TableCell>
                <DisplayForMinRole requiredMinRole={AuthRole.administrator}>
                  <TableCell>Actions</TableCell>
                </DisplayForMinRole>
              </TableRow>
            </TableHead>
            <TableBody>
              {administratorsQuery.data?.administrators &&
                administratorsQuery.data.administrators.map((x) => (
                  <TableRow key={x.id}>
                    <DisplayForMinRole requiredMinRole={AuthRole.administrator}>
                      <TableCell width={10} padding={'checkbox'}>
                        <Checkbox
                          checked={tableSelect.isSelected(x.id)}
                          onClick={() => tableSelect.setItem(x.id)}
                          disabled={userInfo?.id === x.id}
                        />
                      </TableCell>
                    </DisplayForMinRole>
                    <TableCell>{x.firstName || '-'}</TableCell>
                    <TableCell>{x.lastName || '-'}</TableCell>
                    <TableCell>
                      <Stack direction={'row'} gap={2}>
                        {x.emailAddress}
                        <CurrentAdministratorChip userId={x.id} size={'small'} />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <AdministratorRoleChip role={x.role || Role.Viewer} />
                    </TableCell>
                    <TableCell>
                      <Tooltip title={'Block/Unblock'} placement="top">
                        <Switch
                          checked={!x.enabled}
                          value={!x.enabled}
                          disabled={
                            userInfo?.id === x.id ||
                            blockUnblockAdministratorMutation.isLoading ||
                            userInfo?.role !== AuthRole.administrator
                          }
                          onChange={(evt) => handleChange(x.id, evt)}
                        />
                      </Tooltip>
                    </TableCell>
                    <DisplayForMinRole requiredMinRole={AuthRole.administrator}>
                      <TableCell>
                        <Tooltip title={'Preview'} placement="top">
                          <IconButton
                            aria-label="preview"
                            href={routes.admins.preview.link({ adminId: x.id })}
                          >
                            <PreviewIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </DisplayForMinRole>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Stack>
      </CardContent>
    </Card>
  );
};
