import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/block-unblock-administrator.mutation.graphql');

export const useBlockUnblockAdministratorMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, GraphqlErrorsType, { id: string; value: boolean }>(
    ['block-unblock-administrator'],
    async (body) => {
      await graphqlRunner<void, { id: string; blocked: boolean }>(queryDocument, {
        id: body.id,
        blocked: body.value,
      });
    },
    {
      onSuccess: () => {
        enqueueSnackbar(`Operation successful`, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error?.errors?.map((x) => x.message).join('\n, ') || 'Error', {
          variant: 'error',
        });
      },
    },
  );
};
