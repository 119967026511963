import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Card, Link } from '@mui/material';
import { routes } from '../../../routes';
import { useUserQuery } from '../hooks/use-user.query';

export const UserBredcrumbs: React.FC = () => {
  const { userId } = useParams();
  const { data } = useUserQuery(userId as string);

  return (
    <Card sx={{ mb: 3, p: 2 }}>
      <Breadcrumbs aria-label="breadcrumb" color="secondary">
        <Link underline="hover" color="inherit" href={routes.users.list.link(undefined)}>
          Users
        </Link>
        <Link
          underline="none"
          color="inherit"
          href={routes.users.preview.link({ userId: userId || '' })}
        >
          {`${data?.user?.givenName || 'User'} ${data?.user?.familyName || ''}`}
        </Link>
      </Breadcrumbs>
    </Card>
  );
};
