export class RoutePathLink<TParams extends { [key: string]: string }> {
  constructor(public path: string) {}

  public link(params?: TParams): string {
    if (this.path.search(/:/g) < 0) {
      return this.path;
    }

    return this.path
      .split(/\/:*?/)
      .map((x) => {
        const a = x.split(':');
        if (a[1]) {
          return (params && params[a[1]]) || 'undefined';
        }
        return x;
      })
      .join('/');
  }
}
