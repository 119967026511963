import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '../components/not-found-page';
import { AdministratorDetailsPage, AdministratorsPage } from '../modules/administrators';
import { AdministratorCreatePage } from '../modules/administrators/administrator-create.page';
import { ConfirmationPage, ForgotPasswordPage, LoginPage } from '../modules/auth';
import { JobDetailsPage, JobsListPage } from '../modules/jobs';
import { BaseLayout, DashboardLayout } from '../modules/layout';
import { RedirectConditional } from '../modules/shared';
import { UserPage, UsersPage } from '../modules/users';
import { routes } from './routes';

export const Router: React.FC = () => (
  <Routes>
    <Route path={routes.root} element={<BaseLayout />}>
      <Route path={routes.root} element={<RedirectConditional />} />
      <Route path={routes.login} element={<LoginPage />} />
      <Route path={routes.confirm} element={<ConfirmationPage />} />
      <Route path={routes.forgotPassword} element={<ForgotPasswordPage />} />
      <Route element={<DashboardLayout />}>
        <Route path={routes.users.root}>
          <Route path={routes.users.list.path} element={<UsersPage />} />
          <Route path={routes.users.preview.path} element={<UserPage />} />
        </Route>
        <Route path={routes.admins.root}>
          <Route path={routes.admins.list.path} element={<AdministratorsPage />} />
          <Route path={routes.admins.preview.path} element={<AdministratorDetailsPage />} />
          <Route path={routes.admins.create.path} element={<AdministratorCreatePage />} />
        </Route>
        <Route path={routes.jobs.root}>
          <Route path={routes.jobs.list.path} element={<JobsListPage />} />
          <Route path={routes.jobs.details.path} element={<JobDetailsPage />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </Routes>
);
