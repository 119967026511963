import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Stack, Typography } from '@mui/material';
import { Role } from '../../../../common/types';
import { useAdministratorQuery } from '../../hooks/use-administrator.query';
import { AdministratorRoleChip } from '../administrator-role-chip';
import { CurrentAdministratorChip } from '../current-administrator-chip';
import { EnabledDisabledChip } from './enabled-disabled-chip';

export const AdminDetails = () => {
  const { adminId } = useParams();
  const { data } = useAdministratorQuery(adminId || '');

  const hasNameOrLastname = !!(data?.administrator?.firstName || data?.administrator?.lastName);

  const displayedName = hasNameOrLastname
    ? `${data?.administrator?.firstName || ''} ${data?.administrator?.lastName || ''}`
    : `${data?.administrator?.emailAddress || ''}`;

  return (
    <Stack
      sx={{
        p: 2,
        borderRadius: '4px',
        mb: 3,
      }}
      component={Card}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5">{displayedName}</Typography>
      <Stack direction={'row'} gap={2}>
        <CurrentAdministratorChip userId={data?.administrator?.id || ''} />
        <EnabledDisabledChip enabled={data?.administrator?.enabled || false} />
        <AdministratorRoleChip role={data?.administrator?.role || Role.Viewer} size={'medium'} />
      </Stack>
    </Stack>
  );
};
