import { useCallback, useMemo, useState } from 'react';

type UseTableSelectConfigType<TItemValue> = {
  items: TItemValue[];
};

/* eslint-disable */
export type UseTableSelectType<TItemValue = any> = {
  selected: TItemValue[];
  setItem: (value: TItemValue) => void;
  selectAll: () => void;
  clearAll: () => void;
  count: number;
  hasAllSelected: boolean;
  isSelected: (key: TItemValue) => boolean;
};

export const useTableSelect = <T extends string | number>(
  config: UseTableSelectConfigType<T>,
): UseTableSelectType<T> => {
  const [selected, setSelected] = useState<T[]>([]);

  const setItem = useCallback(
    (item: T) => {
      if (selected.some((x) => x === item)) {
        setSelected(selected.filter((x) => x !== item));
        return;
      }
      setSelected([...selected, item]);
    },
    [setSelected, selected],
  );

  const selectAll = useCallback(() => {
    const shouldAddNewItems = config.items.reduce((acc, v) => {
      if (acc) return acc;
      acc = !selected.some((x) => x === v);
      return acc;
    }, false);

    if (shouldAddNewItems) {
      setSelected(Array.from(new Set<T>([...config.items, ...selected])));
      return;
    }

    setSelected(selected.filter((x) => !config.items.some((y) => x === y)));
  }, [config.items, selected, setSelected]);

  const clearAll = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  const isSelected = useCallback(
    (key: T) => {
      return selected.some((x) => x === key);
    },
    [selected],
  );

  const hasAllSelected = useMemo(
    () => (selected.length > 0 ? config.items.every((x) => selected.some((y) => y === x)) : false),
    [config.items, selected],
  );

  return {
    selected,
    setItem,
    selectAll,
    clearAll,
    isSelected,
    count: selected.length,
    hasAllSelected: hasAllSelected || false,
  };
};
