import React from 'react';
import { Chip } from '@mui/material';
import { JobApplicationStatus } from '../../../../../../common/types';

export const ApplicantStatus: React.FC<{ status: JobApplicationStatus }> = (props) => {
  const labelDictionary: { [key in JobApplicationStatus]: string } = {
    [JobApplicationStatus.None]: '',
    [JobApplicationStatus.AssignedToJob]: 'Assigned To Job',
    [JobApplicationStatus.Awaiting]: 'Awaiting',
    [JobApplicationStatus.CancelledByWorker]: 'Cancelled By Worker',
    [JobApplicationStatus.ClosedByManager]: 'Closed By Manager',
    [JobApplicationStatus.RejectedByManager]: 'Rejected By Manager',
  };

  const colorDictionary: {
    [key in JobApplicationStatus]:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  } = {
    [JobApplicationStatus.None]: 'default',
    [JobApplicationStatus.AssignedToJob]: 'success',
    [JobApplicationStatus.Awaiting]: 'warning',
    [JobApplicationStatus.CancelledByWorker]: 'warning',
    [JobApplicationStatus.ClosedByManager]: 'info',
    [JobApplicationStatus.RejectedByManager]: 'error',
  };

  return (
    <Chip
      size="small"
      color={colorDictionary[props.status]}
      label={labelDictionary[props.status]}
    />
  );
};
