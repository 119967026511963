import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth';

export const DisplayForUser: React.FC<{ userId: string }> = (props) => {
  const { userInfo } = useAuth();
  const [shouldBeDisplayed, setShouldBeDisplayed] = useState<boolean>(false);

  useEffect(() => {
    setShouldBeDisplayed(userInfo?.id === props.userId);
  }, [props.userId, userInfo]);

  return shouldBeDisplayed ? <>{props.children}</> : null;
};
