import { useQuery, UseQueryResult } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import {
  GetJobManagerQuery,
  GetJobManagerQueryVariables,
  GraphqlErrorsType,
} from '../../../common/types';

const queryDocument = loader('../queries/job-manager.graphql');

export const useJobManagerQuery = (
  userId?: string,
): UseQueryResult<GetJobManagerQuery, GraphqlErrorsType> => {
  return useQuery<GetJobManagerQuery, GraphqlErrorsType>(
    ['job-manager', userId],
    async () => {
      return await graphqlRunner<GetJobManagerQuery, GetJobManagerQueryVariables>(queryDocument, {
        id: userId || '',
      });
    },
    {
      staleTime: 5000,
      enabled: !!userId,
    },
  );
};
