import React from 'react';
import { useAuth } from './modules/auth';
import { SplashScreen } from './modules/shared';
import { Router } from './routes/router';

const App: React.FC = () => {
  const { initialized } = useAuth();

  if (!initialized) return <SplashScreen />;

  return <Router />;
};

export default App;
