import React from 'react';
import { Box } from '@mui/material';
import { UpdateJobInput } from '../../common/types';
import { NotFound } from '../../components/not-found';
import { routes } from '../../routes';
import { JobDetails } from './components/details/job-details';
import { useJobQuery } from './hooks/use-job.query';

export type JobDetailsFormProps = UpdateJobInput;

export const JobDetailsPage: React.FC = () => {
  const jobQuery = useJobQuery();

  return (
    <Box>
      {!jobQuery.isLoading && !jobQuery.data?.job && (
        <NotFound title={'Job does not exists'} href={routes.jobs.list.link()} />
      )}
      {jobQuery.data?.job && <JobDetails />}
    </Box>
  );
};
