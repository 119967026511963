import React from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { UpdateUserInput } from '../../common/types';
import { NotFound } from '../../components/not-found';
import { routes } from '../../routes';
import { UserBredcrumbs } from './components/user-breadcrumbs';
import { UserForm } from './components/user-form';
import { useUserQuery } from './hooks/use-user.query';

export type UserDetailsFormProps = UpdateUserInput;

export const UserPage: React.FC = () => {
  const { userId } = useParams();
  const userQuery = useUserQuery(userId as string);

  return (
    <Box>
      {!userQuery.data?.user && !userQuery.isLoading && (
        <NotFound title={'User does not exists'} href={routes.users.list.link()} />
      )}
      {userQuery.data?.user && (
        <>
          <UserBredcrumbs />
          <UserForm />
        </>
      )}
    </Box>
  );
};
