import { IconButtonClasses, IconButtonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { LinkBehavior } from '../../components/link-behavior';

export const iconButtonStyles: {
  defaultProps?: Partial<IconButtonProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof IconButtonClasses>> | undefined;
} = {
  defaultProps: {
    LinkComponent: LinkBehavior,
  },
};
