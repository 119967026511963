import { TextFieldProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const textFieldStyles: {
  defaultProps?: Partial<TextFieldProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<'root'>> | undefined;
} = {
  defaultProps: {
    sx: {
      borderRadius: 0,
    },
  },
};
