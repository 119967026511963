import { InputClasses, InputProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const inputStyles: {
  defaultProps?: Partial<InputProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof InputClasses>> | undefined;
} = {
  defaultProps: {
    sx: {
      borderRadius: 0,
    },
  },
  styleOverrides: {
    root: {
      '&.MuiFilledInput-root': {
        backgroundColor: '#FFF',
      },
    },
  },
};
