import React, { MouseEventHandler } from 'react';
import { Link, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  isSelected?: boolean;
  text?: string;
  icon?: React.ReactNode;
  href?: string;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler | undefined;
};

// href in ListItemButton not working - https://github.com/mui-org/material-ui/issues/29030
export const ListLinkItem: React.FC<Props> = (props) => {
  return (
    <Link href={props.href} underline={'none'} sx={{ width: '100%', color: '#000' }}>
      <ListItemButton selected={props.isSelected} sx={props.sx} onClick={props.onClick}>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <ListItemText>{props.text}</ListItemText>
      </ListItemButton>
    </Link>
  );
};
