import { useMutation } from 'react-query';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/job-delete.graphql');

export const useJobsDeleteMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, GraphqlErrorsType, { jobsId: string[] }>(
    ['job-delete'],
    async (body) => {
      const promisses: Promise<void>[] = [];

      body.jobsId.forEach((jobId) => {
        promisses.push(
          graphqlRunner<void, { id: string }>(queryDocument, {
            id: jobId,
          }),
        );
      });

      await Promise.all(promisses);
    },
    {
      onError: (error) => {
        enqueueSnackbar(error.errors.map((x) => x.message).join('\n,'), { variant: 'error' });
      },
    },
  );
};
