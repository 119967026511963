import { InputBaseClasses, InputBaseProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const inputBaseStyles: {
  defaultProps?: Partial<InputBaseProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof InputBaseClasses>> | undefined;
} = {
  defaultProps: {
    sx: {
      borderRadius: 0,
    },
  },
  styleOverrides: {
    root: {
      '&.MuiFilledInput-root': {
        backgroundColor: '#f8f8f8',
        '&.Mui-disabled': {
          '& input': {
            color: '#232930',
            WebkitTextFillColor: '#232930',
          },
          '&:before': {
            borderBottomStyle: 'solid',
            borderBottomColor: '#e1e1e1',
          },
          '&.MuiFilledInput-root': {
            backgroundColor: '#f8f8f8',
          },
        },
      },
    },
  },
};
