import { SelectClasses, SelectProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const selectStyles: {
  defaultProps?: Partial<SelectProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof SelectClasses>> | undefined;
} = {
  defaultProps: {
    MenuProps: {
      PaperProps: {
        sx: {
          maxHeight: '280px',
        },
      },
    },
  },
  styleOverrides: {
    select: {
      borderRadius: 0,
    },
  },
};
