import React, { useMemo } from 'react';
import { LocationType } from '../../../common/types';
import { SelectField, SelectFieldOption, SelectFieldProps } from '../../../components/select-field';

type Props = Omit<SelectFieldProps, 'options'>;

export const SelectLocationTypeField: React.FC<Props> = (props) => {
  const locationTypeOptions = useMemo<SelectFieldOption[]>(
    () => [
      { value: LocationType.None.toString(), label: '', disabled: true },
      { value: LocationType.OnSite.toString(), label: 'On site' },
      { value: LocationType.CanBeDoneRemotely.toString(), label: 'Can be done remotely' },
    ],
    [],
  );

  return (
    <SelectField
      label={props.label || 'Location Type'}
      {...props}
      disabled={props.disabled}
      options={locationTypeOptions}
    />
  );
};
