import React from 'react';
import { SvgIcon } from '@mui/material';
import { SxProps } from '@mui/system';

export const ExploreIcon: React.FC<{ sx?: SxProps }> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width={28}
      height={27}
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        width="28"
        height="27"
        viewBox="0 0 28 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22.5 22.001L26.5 26.001" stroke="#000000" strokeWidth="2" />
        <rect x="1.5" y="15.001" width="8" height="8" stroke="#000000" strokeWidth="2" />
        <rect x="1.5" y="1.00098" width="8" height="8" stroke="#000000" strokeWidth="2" />
        <rect x="15.5" y="1.00098" width="8" height="8" stroke="#000000" strokeWidth="2" />
        <rect x="14.5" y="14.001" width="10" height="10" rx="5" stroke="#000000" strokeWidth="2" />
      </svg>
    </SvgIcon>
  );
};
