import { TableClasses, TableProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const tableStyles: {
  defaultProps?: Partial<TableProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TableClasses>> | undefined;
} = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            whiteSpace: 'nowrap',
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
};
