import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Alert, Button, Grid, Stack, TextField } from '@mui/material';
import { FormGroupSection } from '../../../../components/form-group-section';
import { useAuth } from '../../../auth';
import { useResetAdminPasswordMutation } from '../../hooks/use-reset-administrator-password.mutation';

type PasswordFormProps = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

export const PasswordForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { adminId } = useParams();
  const auth = useAuth();
  const [error, setError] = useState<string | undefined>(undefined);
  const resetPasswordMutation = useResetAdminPasswordMutation();

  const {
    handleSubmit,
    control,
    reset,
    setError: setFormError,
    formState,
  } = useForm<PasswordFormProps>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
  });

  const submit = async (body: PasswordFormProps) => {
    setError(undefined);

    if (body.newPassword !== body.repeatNewPassword) {
      setFormError('repeatNewPassword', {
        type: 'VALIDATION_ERROR',
        message: 'Repeated password must be the same as password',
      });

      return;
    }

    await auth.changePassword(
      { oldPassword: body.oldPassword, password: body.newPassword },
      {
        onSuccess: () => {
          enqueueSnackbar(`Password successfully updated`, { variant: 'success' });
          reset();
        },
        onError: (err) => setError(err?.message),
      },
    );
  };

  const handleResetPassword = useCallback(() => {
    resetPasswordMutation.mutate({
      id: adminId || '',
    });
  }, []);

  if (adminId && auth.userInfo?.id === adminId) {
    return (
      <FormGroupSection defaultExpanded label={'Password'}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item md={6}>
              <Controller
                name={'oldPassword'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Old password is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={formState.isSubmitting}
                    label="Old password"
                    fullWidth
                    type={'password'}
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="none"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item md={3}>
              <Controller
                name={'newPassword'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'New password is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={formState.isSubmitting}
                    label="New password"
                    fullWidth
                    type={'password'}
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="none"
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <Controller
                name={'repeatNewPassword'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'New repeated password is required',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={formState.isSubmitting}
                    label="Repeat new password"
                    fullWidth
                    type={'password'}
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoComplete="none"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item md={12}>
              {error && <Alert severity="error">{error}</Alert>}
            </Grid>
            <Grid item md={3}>
              <Stack gap="20px" direction="row">
                <Button type={'submit'} disabled={formState.isSubmitting}>
                  Change password
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormGroupSection>
    );
  }
  return (
    <FormGroupSection defaultExpanded label={'Password'}>
      <Button onClick={handleResetPassword}>Reset password</Button>
    </FormGroupSection>
  );
};
