import React, { useMemo } from 'react';
import { JobStatus } from '../../../common/types';
import { SelectField, SelectFieldOption, SelectFieldProps } from '../../../components/select-field';

type Props = Omit<SelectFieldProps, 'options'>;

export const SelectStatusTypeField: React.FC<Props> = (props) => {
  const statusTypeOptions = useMemo<SelectFieldOption[]>(
    () => [
      { value: JobStatus.None.toString(), label: '', disabled: true },
      { value: JobStatus.ReadyToBePosted.toString(), label: 'Ready to be posted' },
      { value: JobStatus.Posted.toString(), label: 'Posted' },
      { value: JobStatus.InProgress.toString(), label: 'In progress' },
      { value: JobStatus.Completed.toString(), label: 'Completed' },
      { value: JobStatus.Closed.toString(), label: 'Closed' },
    ],
    [],
  );

  return (
    <SelectField
      label={props.label || 'Status Type'}
      {...props}
      disabled={props.disabled}
      options={statusTypeOptions}
    />
  );
};
