import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  FormControl,
  FormHelperText,
  InputBaseProps,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';

export type SelectMultipleFieldOption = {
  value: string | number;
  label: string;
  groupedOptions?: {
    value: string | number;
    label: string;
  }[];
};

export type SelectMultipleFieldProps = {
  value?: string[];
  onChange: (value?: string[]) => void;
  onBlur?: (value?: string[]) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  options: SelectMultipleFieldOption[];
  disabled?: boolean;
  sx?: SxProps<Theme>;
  size?: InputBaseProps['size'];
  fullWidth?: boolean;
  maxItems?: number;
};

export const SelectMultipleField: React.FC<SelectMultipleFieldProps> = (props) => {
  const [labelId] = useState(`label-id-${Math.random() * 9999}`);
  const { enqueueSnackbar } = useSnackbar();

  const handleOnChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      if (props.maxItems && event.target.value.length > props.maxItems) {
        enqueueSnackbar(`Limit is ${props.maxItems}`, { variant: 'error' });
        return;
      }

      const val =
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
      props.onChange(val);
    },
    [props.onChange],
  );

  const handleOnBlur = useCallback(() => {
    if (!props.onBlur) return;
    props.onBlur();
  }, [props.onBlur]);

  return (
    <FormControl sx={{ minWidth: 210 }} fullWidth={props.fullWidth} size={props.size}>
      <InputLabel error={props.error} id="demo-simple-select-label">
        {props.label}
      </InputLabel>
      <MuiSelect
        size={props.size}
        labelId={labelId}
        label={props.label}
        disabled={props.disabled || !props.options.length}
        value={props.options.length && props.value ? props.value : []}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        error={props.error}
        multiple
      >
        {props.options.map((option) => {
          if (option.groupedOptions?.length) {
            return option.groupedOptions.map((o, index) => [
              index === 0 ? (
                <ListSubheader
                  sx={{
                    color: 'primary.main',
                    textTransform: 'uppercase',
                    fontWeight: '700',
                  }}
                  key={option.value}
                >
                  {option.label}
                </ListSubheader>
              ) : null,
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>,
            ]);
          }
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {props.error && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};
