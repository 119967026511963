import { Card, Typography } from '@mui/material';

interface Props {
  condition: boolean;
  children: React.ReactElement;
}

export const NoResults: React.FC<Props> = ({ condition, children }) => {
  if (condition)
    return (
      <Card sx={{ mt: 3, p: 3, backgroundColor: 'background.default' }}>
        <Typography textAlign="center">No results</Typography>
      </Card>
    );

  return children;
};
