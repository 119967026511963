import Amplify, { Auth } from 'aws-amplify';
import { environment } from '../../environment';

Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: environment.USER_POOL_ID,
    userPoolWebClientId: environment.USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'API_URL',
        endpoint: environment.BASE_URL,
        custom_header: async () => {
          const userInfo = await Auth.currentAuthenticatedUser();
          return {
            Authorization: `Bearer ${
              userInfo?.signInUserSession?.accessToken?.jwtToken || 'no-token-provided'
            }`,
          };
        },
      },
      {
        name: 'ADMINS_API',
        endpoint: environment.COGNITO_URL,
        custom_header: async () => {
          const userInfo = await Auth.currentAuthenticatedUser();
          return {
            Authorization: `Bearer ${
              userInfo?.signInUserSession?.accessToken?.jwtToken || 'no-token-provided'
            }`,
          };
        },
      },
    ],
    headers: async () => {
      const userInfo = await Auth.currentAuthenticatedUser();
      return {
        Authorization: `Bearer ${
          userInfo?.signInUserSession?.accessToken?.jwtToken || 'no-token-provided'
        }`,
      };
    },
    graphql_endpoint: environment.GRAPHQL_URL,
    graphql_headers: async () => {
      const userInfo = await Auth.currentAuthenticatedUser();
      return {
        Authorization: `Bearer ${
          userInfo?.signInUserSession?.accessToken?.jwtToken || 'no-token-provided'
        }`,
      };
    },
  },
});
