import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useSnackbar } from 'notistack';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GraphqlErrorsType } from '../../../common/types';
import { routes } from '../../../routes';

const queryDocument = loader('../queries/delete-administrator.mutation.graphql');

export const useDeleteAdministratorMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useMutation<void, GraphqlErrorsType, { ids: string[] }>(
    ['delete-administrator'],
    async (body) => {
      const promisses: Promise<void>[] = [];
      body.ids.forEach((id) => {
        promisses.push(
          graphqlRunner<void, { id: string }>(queryDocument, {
            id: id,
          }),
        );
      });

      await Promise.all(promisses);
    },
    {
      onSuccess: () => {
        enqueueSnackbar(`User successfully deleted`, { variant: 'success' });
        navigate(routes.admins.list.link());
      },
      onError: (error) => {
        enqueueSnackbar(error?.errors?.map((x) => x.message).join('\n, ') || 'Error', {
          variant: 'error',
        });
      },
    },
  );
};
