type Environment = {
  BASE_URL: string;
  GRAPHQL_URL: string;
  COGNITO_URL: string;
  USER_POOL_WEB_CLIENT_ID: string;
  USER_POOL_ID: string;
};

export const environment: Environment = {
  BASE_URL: process.env.REACT_APP_BASE_URL || '',
  GRAPHQL_URL: new URL(`${process.env.REACT_APP_BASE_URL}/graphql`).href,
  COGNITO_URL: process.env.REACT_APP_COGNITO_URL || '',
  USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID || '',
};
