import { CollapseClasses, CollapseProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const collapseStyles: {
  defaultProps?: Partial<CollapseProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof CollapseClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    root: {
      '& .MuiCollapse-wrapper ': {
        '&.MuiCollapse-vertical': {
          padding: 0,
        },
      },
    },
  },
};
