import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { NumberField } from '../../../../components/number-field';
import { SelectRatePricingTypeField } from '../../../shared';
import { SelectCurrency } from '../../../shared/components/select-currency';
import { useJobQuery } from '../../hooks/use-job.query';
import { JobDetailsFormProps } from '../../job-details.page';

type Props = {
  control: Control<JobDetailsFormProps>;
  disabled?: boolean;
};

export const PricingForm: React.FC<Props> = (props) => {
  const jobQuery = useJobQuery();

  return (
    <Box>
      <Typography variant={'h6'} sx={{ mb: 2 }}>
        Pricing
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item md={4}>
          <Controller
            name="maxRate.type"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'Type is required',
              },
            }}
            render={({ field, fieldState }) => (
              <SelectRatePricingTypeField
                disabled={jobQuery.isLoading || props.disabled}
                fullWidth
                label="Type"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            name="maxRate.wage"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'Wage is required',
              },
              max: {
                value: 999.99,
                message: 'Max price is 999.99',
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <NumberField
                  disabled={jobQuery.isLoading || props.disabled}
                  fullWidth
                  label="Wage"
                  precision={2}
                  name={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Controller
            name="maxRate.currency"
            control={props.control}
            rules={{
              required: {
                value: true,
                message: 'Currency is required',
              },
            }}
            render={({ field, fieldState }) => (
              <SelectCurrency
                fullWidth
                disabled={jobQuery.isLoading || props.disabled}
                label="Currency"
                value={field.value || ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
