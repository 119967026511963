import React, { useMemo } from 'react';
import { SelectField, SelectFieldOption, SelectFieldProps } from '../../../components/select-field';
import { useStatesQuery } from '../hooks/use-states.query';

type Props = Omit<SelectFieldProps, 'options'>;

export const SelectStateField: React.FC<Props> = (props) => {
  const { data, isLoading } = useStatesQuery();

  const citiesSelectItems = useMemo<SelectFieldOption[]>(
    () =>
      data?.states.map((state) => ({
        value: state,
        label: state,
      })) || [],
    [data?.states],
  );

  return (
    <SelectField
      label={'State'}
      {...props}
      disabled={props.disabled || isLoading}
      options={citiesSelectItems}
    />
  );
};
