import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Card, Link } from '@mui/material';
import { routes } from '../../../../routes';
import { useAdministratorQuery } from '../../hooks/use-administrator.query';

export const AdminBredcrumbs: React.FC<{ defaultName?: string }> = (props) => {
  const { adminId } = useParams();
  const { data } = useAdministratorQuery(adminId || '');

  const hasNameOrLastname = !!(data?.administrator?.firstName || data?.administrator?.lastName);

  const displayedName = hasNameOrLastname
    ? `${data?.administrator?.firstName || ''} ${data?.administrator?.lastName || ''}`
    : `${data?.administrator?.emailAddress || ''}`;

  return (
    <Card sx={{ mb: 3, p: 2 }}>
      <Breadcrumbs aria-label="breadcrumb" color="secondary">
        <Link underline="hover" color="inherit" href={routes.admins.list.link(undefined)}>
          Administrators
        </Link>
        <Link
          underline="none"
          color="inherit"
          href={routes.admins.preview.link({ adminId: adminId || '' })}
        >
          {displayedName || props.defaultName || `User Name`}
        </Link>
      </Breadcrumbs>
    </Card>
  );
};
