import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  open?: boolean;
  onClose: () => void;
  onAccept: () => void;
  title: string;
  description: string;
};

export const AlertDialog: React.FC<Props> = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" color="secondary" onClick={props.onClose}>
          Disagree
        </Button>
        <Button size="small" onClick={props.onAccept} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
