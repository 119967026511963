import React from 'react';
import { Card, Chip, Stack, Typography } from '@mui/material';
import { JobStatus } from '../../../../common/types';
import { toDate } from '../../../../common/utils/dates';
import { useJobQuery } from '../../hooks/use-job.query';
import { JobColorChip } from '../list/job-status-chip';
import { BreadcrumbsDetails } from './breadcrumbs-details';
import { JobForm } from './job-form';

export const JobDetails = () => {
  const jobQuery = useJobQuery();

  return (
    <>
      <BreadcrumbsDetails />
      <Stack
        sx={{
          p: 2,
          borderRadius: '4px',
          mb: 3,
        }}
        component={Card}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" sx={{ maxWidth: '50%' }}>
          {jobQuery.data?.job?.title}
        </Typography>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography>
            To Be Done:{' '}
            <strong>
              {jobQuery.data?.job?.toBeDoneDate && toDate(jobQuery.data?.job?.toBeDoneDate || '')}
            </strong>
          </Typography>
          <Typography>
            Created At:{' '}
            <strong>
              {jobQuery.data?.job?.createdAt && toDate(jobQuery.data?.job?.createdAt || '')}
            </strong>
          </Typography>
          <JobColorChip
            size="medium"
            status={jobQuery.data?.job?.status as JobStatus}
            sx={{ fontSize: '16px', px: 3 }}
          />
          {jobQuery.data?.job?.status === JobStatus.Completed && (
            <Chip
              size="medium"
              label="PREVIEW ONLY"
              color={'error'}
              sx={{ fontSize: '16px', px: 3 }}
            />
          )}
        </Stack>
      </Stack>
      <JobForm />
    </>
  );
};
