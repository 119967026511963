import { LinkClasses, LinkProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { LinkBehavior } from '../../components/link-behavior';

export const linkStyles: {
  defaultProps?: Partial<LinkProps<'a', void>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof LinkClasses>> | undefined;
} = {
  defaultProps: {
    component: LinkBehavior,
  } as LinkProps,
};
