import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { FormGroupSection } from '../../../components/form-group-section';
import { useUserQuery } from '../hooks/use-user.query';

export const UserVerification: React.FC = () => {
  const { userId } = useParams();
  const { data } = useUserQuery(userId as string);

  return (
    <FormGroupSection label="User verification">
      <Stack direction="row">
        <Stack spacing="20px" flex={1}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Verified</TableCell>
                <TableCell>{data?.user?.verification.isVerified ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Verified at</TableCell>
                <TableCell>{data?.user?.verification.beingVerifiedAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Verification failure reason</TableCell>
                <TableCell>{data?.user?.verification.failureReason}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Verification failure detailed reason</TableCell>
                <TableCell>{data?.user?.verification.failureDetailedReason}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </FormGroupSection>
  );
};
