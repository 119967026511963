import React from 'react';
import { SelectField, SelectFieldProps } from '../../../components/select-field';

type Props = Omit<SelectFieldProps, 'options'>;

export const SelectCurrency: React.FC<Props> = (props) => {
  return (
    <SelectField
      {...props}
      options={[
        { value: '', label: '' },
        { value: 'USD', label: 'USD' },
      ]}
    />
  );
};
