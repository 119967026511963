import React from 'react';
import { FormState, UseFormReset } from 'react-hook-form';
import { Alert, Button, Card, Collapse, Stack } from '@mui/material';
import { UpdateUserInput } from '../../../common/types';
import { useUserMutation } from '../hooks/use-user.mutation';
import { UserDetailsFormProps } from '../user.page';

type Props = {
  formState: FormState<UserDetailsFormProps>;
  reset: UseFormReset<UpdateUserInput>;
};

export const UserSubmit: React.FC<Props> = ({ formState, reset }) => {
  const userMutation = useUserMutation();

  return (
    <>
      <Collapse in={formState.isDirty && formState.isDirty}>
        <Card sx={{ p: 2, mb: 1 }}>
          <Stack direction="row" spacing={2}>
            <Button type="submit" size="small" disabled={userMutation.isLoading}>
              Save Changes
            </Button>
            <Button
              color="secondary"
              size="small"
              disabled={userMutation.isLoading}
              onClick={() => reset()}
            >
              Reset
            </Button>
          </Stack>
        </Card>
      </Collapse>
      <Collapse in={!!userMutation.error}>
        <Card sx={{ mt: 1, mb: 3 }}>
          <Alert severity="error">Error</Alert>
        </Card>
      </Collapse>
      <Collapse in={userMutation.isSuccess}>
        <Card sx={{ mt: 1, mb: 3 }}>
          <Alert severity="success">Successfully updated</Alert>
        </Card>
      </Collapse>
    </>
  );
};
