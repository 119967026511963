import React from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from '../../components/not-found';
import { routes } from '../../routes';
import { DisplayForMinRole, Role } from '../auth';
import { AdminBredcrumbs } from './components/details/admin-bredcrumbs';
import { AdminDetails } from './components/details/admin-details';
import { DangerZone } from './components/details/danger-zone';
import { GeneralForm } from './components/details/general-form';
import { PasswordForm } from './components/details/password-form';
import { useAdministratorQuery } from './hooks/use-administrator.query';

export const AdministratorDetailsPage = () => {
  const { adminId } = useParams();
  const data = useAdministratorQuery(adminId || '');

  return (
    <>
      {!data.isLoading && !data.data?.administrator && (
        <NotFound title={'User does not exists'} href={routes.admins.list.link()} />
      )}
      {data.data?.administrator && adminId && (
        <>
          <AdminBredcrumbs />
          <AdminDetails />
          <GeneralForm />
          <PasswordForm />
          <DisplayForMinRole requiredMinRole={Role.administrator}>
            <DangerZone />
          </DisplayForMinRole>
        </>
      )}
    </>
  );
};
