import { useQuery, UseQueryResult } from 'react-query';
import { loader } from 'graphql.macro';
import { graphqlRunner } from '../../../common/graphql/graphql-runner';
import { GetStatesQuery, GetStatesQueryVariables, GraphqlErrorsType } from '../../../common/types';

const queryDocument = loader('../queries/states.graphql');

export const useStatesQuery = (): UseQueryResult<GetStatesQuery, GraphqlErrorsType> => {
  return useQuery<GetStatesQuery, GraphqlErrorsType>(
    ['states'],
    async () => {
      return await graphqlRunner<GetStatesQuery, GetStatesQueryVariables>(queryDocument);
    },
    {
      keepPreviousData: true,
      staleTime: 20000,
    },
  );
};
